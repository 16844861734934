import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { SidebarProps } from '../Sidebar/types';
import { DHLLogoIcon } from '../../../assets/icons';

const appName = 'Capacity for DHL';

export const AppInfo: FC<SidebarProps> = ({ open, setOpen, isSidebarVisible }) => {
	return (
		<Box display="flex" alignItems="center">
			{isSidebarVisible && (
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={(): void => setOpen(true)}
					edge="start"
					sx={{ mr: 2, ...(open && { display: 'none' }) }}
				>
					<MenuIcon />
				</IconButton>
			)}

			<DHLLogoIcon height={16} />
			<Box
				sx={{
					fontWeight: 600,
					marginLeft: 8,
					fontSize: 15,
					color: 'error.main',
				}}>
				{appName}
			</Box>
		</Box>

	);
};
