import { IconButton, Tooltip } from "@mui/material";
import { UnsubscribedIcon } from "../../../assets/icons/unsubscribed.icon";

const CustomToolbar = ({handleButtonClick}: any) => {
  
    return (
      <>
        <Tooltip title={"Send open capacity to brokers"}>
          <IconButton onClick={handleButtonClick}>
            <UnsubscribedIcon />
          </IconButton>
        </Tooltip>
      </>
    );
}

export default CustomToolbar;