import React, { ReactElement } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables";
import { ROUTES } from "../../../routes";
import { CircleStatus } from "../../LanesScreen/LanesScreenTable/types";
import { ColorCircle } from "../../LanesScreen/LanesScreenTable/ColorCircle";
import { LaneScreenAction } from "../../LanesScreen/LanesScreenTable/LanesScreenAction";

export const innerTableColumns = (navigate: any) =>
  [{
      name: "id",
      label: "id",
      options: {
          filter: false,
          sort: false,
          display: "excluded",
      },
  },
    {
      name: "loadId",
      label: "Load ID",
      options: {
        filter: true,
        sort: false,
        filterType: "textField",
      },
    },

    {
      name: "broker",
      label: "Broker",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (
          value: Record<string, string | number>
        ): ReactElement => <div>{value.name ?? ""}</div>,
      },
    },
    {
      name: "bhPickup",
      label: "BH pickup",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "bhPickupDate",
      label: "BH pickup date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bhMiles",
      label: "BH miles",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "gm",
      label: "GM",
      options: {
        filter: false,
        customBodyRender: (value: CircleStatus) => (
          <ColorCircle value={value} />
        ),
      },
    },
    {
      name: "hos",
      label: "Hos",
      options: {
        filter: false,
        display: 'excluded',
        customBodyRender: (value: CircleStatus) => (
          <ColorCircle value={value} />
        ),
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (id: number, actionStatus: any) => {
          return <LaneScreenAction id={id} laneId={actionStatus?.rowData[10]} actionStatus={actionStatus?.rowData[9]} biddingEnabled={actionStatus?.rowData[6]?.biddingEnabled}/>
        }
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "laneId",
      label: "Detail",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (laneId: number, data) => {
          return (
            <IconButton
              onClick={() => navigate(`/${ROUTES.lanesScreen}/${laneId}#${data?.rowData[0]}`)}
              color="default"
            >
              <MoreVertIcon />
            </IconButton>
          );
        },
      },
    },
  ] as MUIDataTableColumn[];
