import React, { FC } from 'react'
import { MenuItem, Select } from '@mui/material'
import { useFormContext } from "react-hook-form";

import { FormItemWrapper } from './FormItemWrapper';
import { InputParametersName } from '../PursuitSupport/InputParameters/types';
import { InputNameApproave, InputNameDecline } from '../Modal/types';
import { saveRoute } from '../CapacityAssignment/ReasonSelect';

interface CustomInputProps {
	label: string;
	inputName: InputNameApproave | InputNameDecline | InputParametersName;
	isRequired?: boolean;
	reasons?: any[]
}

export const FormItemSelect: FC<CustomInputProps> = ({ label, inputName, isRequired, reasons }) => {
	const { register } = useFormContext();

	// const handleChange = async (e: any) => {
	// 	//setReasonText(e.target.value)
	// };
	return (
		<FormItemWrapper label={label}>
			<Select
				id={inputName}
				{...register(inputName, { required: isRequired })}
			//onChange={(e) => handleChange(e)}
			>
				{reasons?.map(reason => <MenuItem key={reason.id} value={reason.id}>{reason.name}</MenuItem>)}
			</Select>
		</FormItemWrapper>
	)
}