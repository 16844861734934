import React, { FC } from 'react'
import { Paper, Box, Typography } from '@mui/material'


export const ContentHeader: FC<{ header: string, importInfo?: string }> = ({ header, importInfo }) => (
	<Paper elevation={2} sx={{
		p: 4,
		mb: 4,
		mt: '64px'
	}}>
		<Box sx={{ mb: 8, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
			<Typography sx={{ fontSize: "19px", fontWeight: 600}}>{header}</Typography>
			{importInfo && 
				<Typography sx={{ fontSize: 11, fontWeight: '400' }}>
					{importInfo}
				</Typography>
			}
		</Box>
	</Paper>


)