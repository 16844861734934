export interface TableFiltersProps {
	site: string;
	onFilter: any;
	mockData: any[];
}

export enum Action {
	APPROVED = "approved",
	DECLINE = "decline",
}

export interface LaneScreenActionProps {
	status: Action;
	isDisabled: boolean
}

export enum Status {
	NOT_A_MATCH = "NOT_A_MATCH",
	SUBMITTED = "SUBMITTED",
	DECLINED = "DECLINED",
	MATCH_FOUND = "MATCH_FOUND",
	CANCELLED = "CANCELLED",
	BOOKED = "BOOKED",
	EXPIRED = "EXPIRED",
}

export interface LaneScreenStatusProps {
	status: Status;
}

export enum CircleStatus {
	GREEN = "GREEN",
	YELLOW = "YELLOW",
	RED = "RED",
}

export interface ColorCircleProps {
	value: CircleStatus;
}

