import React, { FC } from 'react'
import { Box } from '@mui/system'
import { blue, green, grey, red, yellow } from '@mui/material/colors';
import { LaneScreenStatusProps, Status } from './types';

const getStatusColor = (status: Status): string => {
	return ({
		[Status.MATCH_FOUND]: blue[500],
		[Status.DECLINED]: red[400],
		[Status.NOT_A_MATCH]: yellow[600],
		[Status.SUBMITTED]: grey[500],
		[Status.BOOKED]: green[500],
		[Status.CANCELLED]: red[700],
		[Status.EXPIRED]: grey[700],
	})[status]
}

export const getStatusText = (status: Status): string => {
	return ({
		[Status.MATCH_FOUND]: "Match found",
		[Status.DECLINED]: "Declined",
		[Status.NOT_A_MATCH]: "Not a match",
		[Status.SUBMITTED]: "Submitted",
		[Status.BOOKED]: "Booked",
		[Status.CANCELLED]: "Cancelled",
		[Status.EXPIRED]: "Expired",
	})[status]
}

export const LaneScreenStatus: FC<LaneScreenStatusProps> = ({ status }) => {
	if (status == null) {
		return <Box>-</Box>
	}
	return (
		<Box
			sx={{
				border: '2px solid',
				borderColor: getStatusColor(status),
				color: getStatusColor(status),
				display: 'flex',
				justifyContent: 'center',
				fontWeight: '600',
				padding: 1,
				width: "max-content"
			}}>
			{getStatusText(status)}
		</Box>
	)
}

