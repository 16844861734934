import { Box } from "@mui/material"
import React, { FC } from 'react';

export const FilterButton: FC<{ onClick: () => void }> = ({ onClick }) => {
	return (
		<Box sx={{
			fontFamily: 'fontFamily',
			fontWeight: 600,
			display: 'flex', fontSize: '12px', marginTop: '30px', background: '#FFCC00', border: 'none', padding: '8px', borderRadius: '6px', width: 'fit-content', ":hover": {
				cursor: "pointer"
			}
		}} onClick={onClick}>
			Apply Filters
		</Box >
	)
}
