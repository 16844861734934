export const laneRouteBidToRow = (route: any): any => {

	const { bidMessage, status, id, rate, bidNumber, bidDate, createdBy, matchStatus, bidType, rejectReason } = route;

	return {
		id: id,
		bidNumber: bidNumber,
		rate: rate,
		status: status,
		bidMessage: bidMessage,
		action: id,
		bidDate: bidDate,
		user: createdBy,
		matchStatus: matchStatus,
		bidType: bidType,
		rejectReason: rejectReason
	};
};

export const laneRouteBidResponseToRows = (response: any): any[] => {
	return response.content.map((row: any) => laneRouteBidToRow(row));
};

