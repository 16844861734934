import React from 'react';
import { Notyf } from 'notyf';

export default React.createContext(
	new Notyf({
		duration: 5000,
		ripple: false,
		dismissible: true,
		types: [
			{
				type: 'info',
				background: '#2196f3',
			},
			{
				type: 'success',
				background: '#4caf50',
			},
			{
				type: 'warning',
				background: '#FFCC00',
			},
			{
				type: 'error',
				background: "#CC0000",
			},
		],
	}),
);
