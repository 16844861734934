import React, { useState, useEffect, useContext } from 'react'

import { rfPtableColumns } from './rfPtableColumns';
import { rftRouteResponseToRows } from './helpers';
import { FiltersState, PaginationState, SortingState } from '../../DHLTable/action.utils';
import { FilterOptionsForTable } from '../../DHLTable/types';
import { Center } from '../../layout/Center';
import CircularProgress from '@mui/material/CircularProgress';
import { DhlTable } from '../../DHLTable';
import { generateDownloadFilename, showErrorNotyf } from '../../DHLTable/general.utils';
import { createDownloadHandler } from '../../DHLTable/DhlTable.utils';
import { downloadColumnMapper } from '../../DHLTable/helpers';
import { postRFPTable } from '../../../service/postRFPTable';
import NotyfContext from '../../DHLTable/NotyfContext';
import { useRfpRealod } from './RfpContext';

export const RfpTable: React.FC<any> = ({ rowActions, tableActions }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [isFirstLoading, setFirstIsLoading] = useState(true);
	const [totalRowCount, setTotalRowCount] = useState<number>(0);
	const [filterOptions, setFilterOptions] = useState<FilterOptionsForTable<any>>({});
	const notyf = useContext(NotyfContext);
	const { realoadNumber } = useRfpRealod();

	const loadDataAndFiltersOptions = async (
		filtersParam: FiltersState<any>,
		sortingParam: SortingState<any> | null,
		paginationParam: PaginationState,
	): Promise<void> => {
		setIsLoading(true);
		let response;
		try {
			response = await postRFPTable({ filtersParam, sortingParam, paginationParam });
		} catch (e) {
			showErrorNotyf(notyf, e, 'No data available');
			setIsLoading(false);
			return;
		}

		setData(rftRouteResponseToRows(response));
		setTotalRowCount(response.totalElements);
		setIsLoading(false);
		setFilterOptions({});
	};

	useEffect(() => {
		setFirstIsLoading(true)
		const fetchData = async () => {
			const datas = await postRFPTable({});
			setData(rftRouteResponseToRows(datas));
			setFirstIsLoading(false)
		}

		fetchData()
			.catch(console.error);
			
	}, [realoadNumber])

	if (isFirstLoading) {
		return (
			<Center>
				<CircularProgress size={25} />
			</Center>
		)
	}

	return (
		<>
			<DhlTable
				bottomPosition='0px'
				title=""
				data={data}
				totalRowCount={totalRowCount}
				columns={rfPtableColumns()}
				rowActions={rowActions}
				tableActions={tableActions}
				filterOptions={filterOptions}
				isLoading={isLoading}
				options={{
					search: false,
					filter: false,
					downloadOptions: {
						filename: generateDownloadFilename('Pursuit support'),
						separator: ';',
						filterOptions: {
							useDisplayedColumnsOnly: true,
							useDisplayedRowsOnly: false,
						},
					},
					onDownload: createDownloadHandler(downloadColumnMapper),
					textLabels: {
						body: {
							noMatch: isLoading ? 'Loading...' : 'No tasks',
						},
						pagination: {
							rowsPerPage: 'Routes per page',
						},
						toolbar: {
							downloadCsv: 'Download CSV (Current Page Only)',
						},
					},
				}}
				onDataFetchRequested={loadDataAndFiltersOptions}
			/>
		</>
	)
}