import React, { FC } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

interface LoginLogoutButtonsProps {
	handleLogout: (loginType: string) => void;
	handleLogin: (loginType: string) => void
}

export const LoginLogoutButtons: FC<LoginLogoutButtonsProps> = ({ handleLogin, handleLogout }) => {
	const isAuthenticated = useIsAuthenticated();

	return (
		<>
			{isAuthenticated ?
				<>
					<MenuItem onClick={(): void => handleLogout('popup')}>Logout using Popup</MenuItem>
					<MenuItem onClick={(): void => handleLogout('redirect')}>Logout using Redirect</MenuItem>
				</> : <>
					<Button onClick={(): void => handleLogin('popup')}>
						Sign in using Popup
					</Button>
					<Button onClick={(): void => handleLogin('redirect')}>
						Sign in using Redirect
					</Button>
				</>}
		</>
	);
};