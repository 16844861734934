import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import NotyfContext from "../../DHLTable/NotyfContext";
import { showErrorNotyf } from "../../DHLTable/general.utils";
import { Center } from "../../layout/Center";
import { postDistribute } from "../../../service/postCapacityDistribution";

export const DistributionModal: FC<{ closeModal: any;}> = ({closeModal}) => {
  const methods = useForm();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      let response = null;

      response = await postDistribute();

      await notyf.success(`Capacity distributed correctly`);      
      closeModal();
      setIsLoading(false);
      return response || undefined;
    } catch (e) {
      setIsLoading(false);
      showErrorNotyf(notyf, e, `Failed to distribute capacity`);
      closeModal();
      throw e;
    }
  };

  useEffect(() => {
    return () => closeModal();
  }, [methods.formState.isSubmitSuccessful]);

  const isbuttonDisabled = methods.formState.isSubmitting;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mt={"40px"}>
            <Box textAlign={'center'}>
              {`Are you sure you want to send the open capacity to the configured broker distribution list?`}
            </Box>
            <Box>

            </Box>
            <Center mt="30px">
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ width: "fit-content", mb: "10px" }}
                disabled={isbuttonDisabled}
              >
                {isLoading ? <CircularProgress size={15} /> : 'Confirm'}
              </Button>
            </Center>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};
