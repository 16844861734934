import * as React from "react";
import {Appointments, AppointmentTooltip} from "@devexpress/dx-react-scheduler-material-ui";
import { blue, grey, yellow } from "@mui/material/colors";
import {Grid} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import format from "date-fns/format";

export const Appointment = ({ children, style, ...restProps }: any) => {
  const { routeType, title } = restProps.data;
  const routesGroup = routeType === "D" || routeType === "S";
  const bgColor = routesGroup ? yellow[800] : blue[800];
  const colorText = routesGroup ? grey[800] : "white";
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: bgColor,
        borderRadius: "4px",
        fontSize: "12px",
        color: colorText,
        padding: "4px",
      }}
    >
      {title}
    </Appointments.Appointment>
  );
};

export const TooltipHeader:React.FC<AppointmentTooltip.HeaderProps> = (props:any) => {
    return <div style={{height: 20, width: '100%', backgroundColor: props.appointmentData.routeType == 'I' ? blue[800] : yellow[800]}}></div>
}
/*export const TooltipLayout:React.FC<AppointmentTooltip.LayoutProps> = (props) => {
    return <AppointmentTooltip.Layout>
    </AppointmentTooltip.Layout>
}*/

const getDateInterval = (start:Date, end:Date) => {
    return `${format(start, "MM/dd/yyy, h:mm a")} - ${format(end, "MM/dd/yyy, h:mm a")}`;
}

export const TooltipContent:React.FC<AppointmentTooltip.ContentProps> = (props) => {
    //@ts-ignore
    return <AppointmentTooltip.Content recurringIconComponent={<></>} appointmentData={props.appointmentData} formatDate={props.formatDate} className={`schedule-tooltip-content${props.appointmentData?.originalStart ? ' more-days' : ''}`}>
        {props.appointmentData?.originalStart && <Grid container alignItems="center">
            <Grid item xs={2} style={{textAlign: "center"}}>
                <AccessTimeIcon />
            </Grid>
            <Grid item xs={10}>
                <span></span>
                {/*//@ts-ignore*/}
                <span>{getDateInterval(props.appointmentData?.originalStart, props.appointmentData?.originalEnd)}</span>
            </Grid>
        </Grid> }
        {props.appointmentData?.employee && <Grid container alignItems="center">
            <Grid item xs={2} style={{textAlign: "center", marginTop: 10}}>
                <PersonIcon />
            </Grid>
            <Grid item xs={10}>
                <span></span>
                {/*//@ts-ignore*/}
                <span>{`${props.appointmentData?.employee.name} (${props.appointmentData?.employee.employeeId})`}</span>
            </Grid>
        </Grid> }
    </AppointmentTooltip.Content>
}

export const TooltipIcon:React.FC<any> = (props) => {
    return <>

    </>
}