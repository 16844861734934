import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContentHeader } from '../layout/ContentHeader';
import { LanesScreenDetailContent } from './LanesScreenDetailContent';
import { OpenLaneDetailContextContainer, useOpenLaneDetail } from './OpenLaneDetailContext';
import { fetchLaneDetail } from '../../service/fetchLaneDetail';
import { Center } from '../layout/Center';
import { CircularProgress } from '@mui/material';

export const LanesScreenDetail: FC = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [detail, setDetailData] = useState<any>();
	const { realoadNumber } = useOpenLaneDetail();

	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			if (id) {
				const response = await fetchLaneDetail(id);
				setDetailData(response);
				setIsLoading(false);
			}
		}

		fetchData()
			.catch(console.error);
	}, [id, realoadNumber])

	if (isLoading) {
		return (
			<Center>
				<CircularProgress size={25} />
			</Center>
		)
	}

	return (
		<OpenLaneDetailContextContainer>
			<ContentHeader header={`Lane Detail - LoadID ${detail.loadId}`} />
			<LanesScreenDetailContent detail={detail}/>
		</OpenLaneDetailContextContainer>
	)
}