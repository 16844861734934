export enum Type {
	EQUALS = "equals",
	NOT_EQUALS = 'notEqual',
	CONTAINS = "contains",
	NOT_CONTAINS = "notContains",
	RANGE = 'inRange',
	LESS_THAN = 'lessThan',
	GREATER_THAN = 'greaterThan',
	GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
	LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
}

export enum FilterType {
	SET = "set",
	DATE = 'date',
	DECIMAL = "decimal",
	TEXT = "text",
	NUMBER = 'number',
}


export interface SitesType {
	id: number,
	siteType: null | string,
	code: string,
	latitude: number,
	longitude: number,
}