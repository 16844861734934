import React, { useEffect, useState, FC } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import Typography from '@mui/material/Typography';

const WelcomeName: FC = () => {
	const { accounts } = useMsal();
	const account = useAccount(accounts[0] || {});
	const [name, setName] = useState<string | null>(null);

	useEffect(() => {
		if (account && account.name) {
			setName(account.name.split(' ')[0]);
		}
	}, [account]);


	return (
		<Typography variant="h6" style={{ alignSelf: 'center', fontSize: '14px' }}>
			Welcome, {name}
		</Typography>
	)
};

export default WelcomeName;