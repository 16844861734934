import React, { useEffect, useState } from 'react'
import { ContentHeader } from '../layout/ContentHeader'
import { LanesScreenMainTable } from './LaneScreenMainTable';
import { OpenLanesContextContainer } from './OpenLanesContext';
import { fetchLatestImportLog } from '../../service/fetchImportLog';
import { LatestImportLogResponseType } from '../LocalIdleDashboard/LocalIdleTable/types';
import { formatAsLocalDateTime } from '../../helpers';

export const LanesScreen: React.FC = () => {
	const [ lastImportInfo, setLastImportInfo ] = useState<LatestImportLogResponseType>();

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetchLatestImportLog('match');
			setLastImportInfo(response);
		}

		fetchData()
			.catch(console.error);
	}, [])

	const getLocalDateTime = () => {
		return lastImportInfo?.finished ? `Last updated on: ${formatAsLocalDateTime(lastImportInfo?.finished!)}` : 'Last udpated on: unknown';
	}

	return (
		<OpenLanesContextContainer>
			<ContentHeader header='Open Lanes' importInfo={getLocalDateTime()} />
			<LanesScreenMainTable />
		</OpenLanesContextContainer>
	)
}
