import React, { FC } from 'react'
import { Box } from '@mui/material';

import { DetailTableItem } from './DetailTableItem';
import { formatAsLocalDateTime } from '../../../helpers';

export const MainDetailTable: FC<{ detail: any, icon?: any }> = ({ detail, icon }) => {

	const { equipmentHeight, equipmentLength, equipmentWidth, id, broker, loadId, pickupDate, deliveryDate, commodityType } = detail;
	const { city, state, name, street, zip, country, latitude, longitude } = detail.pickupLocation;
	const { city: delCity, state: delState, name: delName, street: delStreet, zip: delZip, country: delCountry, latitude: delLatitude, longitude: delLongitude } = detail.deliveryLocation;

	const dimension = `${equipmentHeight} x ${equipmentLength} x ${equipmentWidth}`;
	const dateFormatPickup = new Date(pickupDate);
	const pickup = pickupDate ? formatAsLocalDateTime(dateFormatPickup) : "";

	const deliveryDateFormat = new Date(deliveryDate);
	const delivery = deliveryDate ? formatAsLocalDateTime(deliveryDateFormat) : "";

	const mainDetail = [
		{ id: 1, text: "Lane ID", value: id },
		{ id: 2, text: "Broker", value: broker?.name },
		{ id: 3, text: "Load ID", value: loadId, },
		{
			id: 4, text: "Pickup Location", value: `${city}, ${state}`, tooltip: true, address: {
				locationName: name,
				street: street, state: state, zip: zip, country: country, lat: latitude, lon: longitude
			}
		},
		{ id: 5, text: "Pickup date", value: pickup },
		{
			id: 6, text: "Delivery Location", value: `${delCity}, ${delState}`, tooltip: true, address: {
				locationName: delName,
				street: delStreet, state: delState, zip: delZip, country: delCountry, lat: delLatitude, lon: delLongitude
			}
		},
		{ id: 7, text: "Delivery date", value: delivery },
		{ id: 8, text: "Equipment Dimension (h x l x w)", value: dimension },
		{ id: 9, text: "Commodity Type", value: commodityType },
		{ id: 10, text: "Other Lane Attributes", value: "-" },
	]

	return (
		<Box display='flex'>
			{icon}
			<Box ml={icon ? "10px" : undefined}>
				{mainDetail.map((item: any) => {
					return (
						<DetailTableItem key={item.id} item={item} />
					)
				})}
			</Box>
		</Box>
	)
}


