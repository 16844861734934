import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { msalInstance } from '../index';
import qs from 'qs';
import {loginRequest, setActiveAccount} from '../authConfig';
import {PublicClientApplication} from "@azure/msal-browser";

const baseURL = process.env.REACT_APP_API;
//const baseURL = "https://dscusnoramapp-c4d-fe-api-pldnsazr.azurewebsites.net"

const fetchAccessToken = (msalInstance: PublicClientApplication): Promise<string> | undefined => {
	if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length === 0) {
		msalInstance.loginRedirect(loginRequest);
		Promise.reject("Redirecting to Azure");
	} else {
		return new Promise((resolve) => {
			msalInstance.acquireTokenSilent({ ...loginRequest, account: msalInstance.getAllAccounts()[0] })
				.then((response) => {
					resolve(response.accessToken);
					msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
					setActiveAccount(msalInstance.getAllAccounts()[0]);
			});
		});
	}
};

const timeoutSeconds = 60;

export const http = axios.create({
	baseURL: `${baseURL}/`,
	timeout: timeoutSeconds * 1000,
	withCredentials: false,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
});

// add a request interceptor to handle authentication
http.interceptors.request.use(
	async (config) => {
		const accessToken = await fetchAccessToken(msalInstance);
		if (config.headers) {
			if (accessToken) {
				//console.log(accessToken)
				config.headers['Authorization'] = 'Bearer ' + accessToken;
			}
			config.headers['Content-Type'] = 'application/json';
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	},
);

// for 4xx, return the actual response from API instead of JavaScript error
http.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.code === 'ECONNABORTED') {
			throw new Error('Connection timed out after ' + timeoutSeconds + ' sec');
		} else if (error.response?.status >= 400 && error.response?.status < 500 && error.response.data) {
			// for 4xx error that have an actual response, return the actual API response (REST API often responds with 4xx)
			return Promise.reject(error.response.data);
		} else {
			// otherwise, return the whole JavaScript error
			return Promise.reject(error);
		}
	},
);


export const get = <T = unknown, R = AxiosResponse<T>>(
	url: string,
	headers: AxiosRequestHeaders = {},
	params: Record<string, unknown> = {},
): Promise<R> =>
	http.get<T, R>(url, {
		params,
		headers,
		paramsSerializer: (parameters) => qs.stringify(parameters, { arrayFormat: 'repeat' }),
	});

export const post = <T = unknown, R = AxiosResponse<T>, D = unknown>(
	url: string,
	data: D,
	headers: AxiosRequestHeaders = {},
	params: Record<string, unknown> = {},
): Promise<R> =>
	http.post<T, R, D>(url, data, {
		params,
		headers,
		paramsSerializer: (parameters) => qs.stringify(parameters, { arrayFormat: 'repeat' }),
	});

export const put = <T = unknown, R = AxiosResponse<T>, D = unknown>(
	url: string,
	data: D,
	headers: AxiosRequestHeaders = {},
	params: Record<string, unknown> = {},
): Promise<R> =>
	http.put<T, R, D>(url, data, {
		params,
		headers,
		paramsSerializer: (parameters) => qs.stringify(parameters, { arrayFormat: 'repeat' }),
	});

export const remove = <T = unknown, R = AxiosResponse<T>>(
	url: string,
	headers: AxiosRequestHeaders = {},
	params: Record<string, unknown> = {},
): Promise<R> =>
	http.delete<T, R>(url, {
		headers,
		params,
		paramsSerializer: (parameters) => qs.stringify(parameters, { arrayFormat: 'repeat' }),
	});


export const getFile = <T>(url: string, headers: any = {}, params: any = {}) =>
	http.get<T>(url, {
		responseType: 'blob',
		params: { ...params },
		headers: { ...headers },
	});
