import React, { FC, useEffect } from 'react';
import { Button } from '@mui/material';
import { red } from '@mui/material/colors';
import { useFormContext } from "react-hook-form";
import { DeclineIcon } from '../../assets/icons';

export const DeclineButton: FC<{ onClick?: () => void, disabled?: boolean, closeModalDecline?: any }> = ({ onClick, disabled, closeModalDecline }) => {
	const { formState: { isSubmitSuccessful, isValid, isDirty } } = useFormContext()

	useEffect(() => {
		if (isSubmitSuccessful) {
			closeModalDecline()
		}
	}, [isSubmitSuccessful])

	return (
		<Button type="submit" disabled={disabled} color='inherit' variant="outlined" endIcon={<DeclineIcon color={red[600]} />} onClick={onClick} sx={{ width: 'fit-content', color: red[700], borderColor: red[600] }}>
			Decline Match
		</Button>
	)
}
