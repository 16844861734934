import React, { FC, useContext, useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { localIdletableColumns } from "./localIdletableColumns";
import { FilterOptionsForTable } from "../../DHLTable/types";
import { FiltersState, PaginationState, SortingState } from "../../DHLTable/action.utils";
import { CustomMultiSelectWithPlaceholder, CustomSelectWithPlaceholder } from "../../Forms/CustomSelectWithPlaceholder";
import { Box, Checkbox, ListItemText, MenuItem, TextField, useMediaQuery } from "@mui/material";
import { DhlTable } from "../../DHLTable";
import {generateDownloadFilename, showErrorNotyf, validateDate} from "../../DHLTable/general.utils";
import { createDownloadHandler } from "../../DHLTable/DhlTable.utils";
import { postIdleLocation } from "../../../service/postIdleLocation";
import { downloadColumnMapper } from "../../DHLTable/helpers";
import {ExternalFilterWrapper, localIdleCsvMapper, localIdleResponseToRows} from "./helpers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { BrokersType, CoordinatesType, LocalIdleTableType, StateType, localIdleRouteType } from "./types";
import { fetchStates } from "../../../service/fetchStates";
import NotyfContext from "../../DHLTable/NotyfContext";
import { fetchBrokers } from "../../../service/getBrokers";
import { LocalIdleMap } from "../Map/LocalIdleMap";
import { getEndOfDay, getLocalDateFormat, getStartOfDate } from "../../../helpers";
import { fetchEquipments } from "../../../service/getEquipments";

export const LocalIdleTable: FC<LocalIdleTableType> = ({ site, selectedSiteData }) => {
	const todayStart = getStartOfDate(new Date());
	const isFiltersCompose = useMediaQuery('(max-width:1300px)');
	const [data, setData] = useState<localIdleRouteType[]>([]);
	const [states, setStates] = useState<StateType[]>([]);
	const [brokers, setBrokers] = useState<any[] | null>([]);
	const [equipments, setEquipments] = useState<any[] | null>([]);
	const [selectedBrokers, setSelectedBrokers] = useState<BrokersType[]>([]);
	const [pickupState, setPickupState] = useState<any | null>(null);
	const [deliveryState, setDeliveryState] = useState<any | null>(null);
	const [totalRowCount, setTotalRowCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [filterOptions, setFilterOptions] = useState<FilterOptionsForTable<any>>({});
	const [pickupDateValueTo, setPickupDateValueTo] = useState<Date | null>(null)
	const [deliveryDateValueTo, setDeliveryDateValueTo] = useState<Date | null>(null)
	const [pickupDateValueFrom, setPickupDateValueFrom] = useState<Date | null>(todayStart)
	const [deliveryDateValueFrom, setDeliveryateValueFrom] = useState<Date | null>(null)
	const notyf = useContext(NotyfContext);
	const [mapData, setMapData] = useState<any | null>(null);
	const [showMap, setShowMap] = useState<boolean>(true);
	const [coordinates, setCoordinates] = useState<CoordinatesType | null>(null);

	// state pickup
	const handleFilterPickupState = (e: any) => {
		setPickupState(e.target.value)
	}
	const handleClearClickPickupState = () => {
		setPickupState(null);
	};

	// state delivery
	const handleFilterDeliveryState = (e: any) => {
		setDeliveryState(e.target.value)
	}
	const handleClearClickDeliveryState = () => {
		setDeliveryState(null);
	};

	// delivery
	const handleChangeDeliveryTo = (value: any) => {
		if (value) {
			setDeliveryDateValueTo(getEndOfDay(value));
		} else {
			setDeliveryDateValueTo(value)
		}
	};
	const handleChangeDeliveryFrom = (value: any) => {
		if (value) {
			setDeliveryateValueFrom(getStartOfDate(value));
		} else {
			setDeliveryateValueFrom(value)
		}
	};

	// pickup
	const handleChangePickupTo = (value: any) => {
		if (value) {
			setPickupDateValueTo(getEndOfDay(value));
		} else {
			setPickupDateValueTo(value)
		}
	};
	const handleChangePickupFrom = (value: any) => {
		if (value) {
			setPickupDateValueFrom(getStartOfDate(value));
		} else {
			setPickupDateValueFrom(value)
		}
	};

	const postedBrokers = brokers?.filter(broker => selectedBrokers.some(selectedBroker => broker.name === selectedBroker)).map(brokerId => brokerId.id);

	const loadDataAndFiltersOptions = async (
		filtersParam: FiltersState<any>,
		sortingParam: SortingState<any> | null,
		paginationParam: PaginationState,
	): Promise<void> => {
		if (!validateDate(pickupDateValueFrom) || !validateDate(pickupDateValueTo) || !validateDate(deliveryDateValueFrom) || !validateDate(deliveryDateValueTo)) {
			return;
		}
		setIsLoading(true);
		let response;
		try {
			response = await postIdleLocation({ site, postedBrokers, filtersParam, sortingParam, paginationParam, pickupState, deliveryState, deliveryDateValueTo, pickupDateValueTo, deliveryDateValueFrom, pickupDateValueFrom, coordinates, equipments });
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to load data')
			setIsLoading(false);
			return;
		}

		setIsLoading(false);
		setData(localIdleResponseToRows(response));
		setTotalRowCount(response.totalElements);
		setFilterOptions({});
	};

	useEffect(() => {
		if (!validateDate(pickupDateValueFrom) || !validateDate(pickupDateValueTo) || !validateDate(deliveryDateValueFrom) || !validateDate(deliveryDateValueTo)) {
			return;
		}
		setIsLoading(true);
		setShowMap(false);
		const fetchData = async () => {
			const data = await postIdleLocation({ site, postedBrokers, pickupState, deliveryState, deliveryDateValueTo, pickupDateValueTo, deliveryDateValueFrom, pickupDateValueFrom, coordinates, equipments });
			setData(localIdleResponseToRows(data));
			setTotalRowCount(data.totalElements);
			setIsLoading(false)
		}

		fetchData()
			.catch(console.error);

		setShowMap(true);
	}, [site, selectedBrokers, deliveryDateValueTo, pickupDateValueTo, pickupState, deliveryDateValueFrom, pickupDateValueFrom, deliveryState, coordinates])

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			const responseState = await fetchStates();
			setStates(responseState);
			const responseBrokers = await fetchBrokers();
			setBrokers(responseBrokers);
			const responseEquipments = await fetchEquipments();
			setEquipments(responseEquipments);
			if(data.length !== 0) {
				setIsLoading(false);
			}
		}

		fetchData()
			.catch(console.error);

	}, [])


	useEffect(() => {
		setShowMap(false);
		const mapData = data.map(map => {
			const { id, deliveryLat, deliveryLng, pickupLat, pickupLng, loadId, pickupDateFrom, pickupLocation, deliveryLocation, rate } = map;
			return ({
				id: id,
				deliveryLat: deliveryLat,
				deliveryLng: deliveryLng,
				pickupLat: pickupLat,
				pickupLng: pickupLng,
				loadId: loadId,
				selected: false,
				pickupDate: pickupDateFrom,
				pickupLocation,
				deliveryLocation,
				revenue: rate,
			})
		});

		setMapData(mapData);
		setShowMap(true);
	}, [data]);

	const onRowSelect = async (loadId: any) => {
		setShowMap(false);
		const updatedMapData = mapData.map((e: any) => {
			if(e.loadId === loadId) {
				return {...e, selected: true};
			}
			return {...e, selected: false};
		});

		await setMapData(updatedMapData);
		setShowMap(true);
	};

	const onManualChange = (location: React.SetStateAction<CoordinatesType | null>) => {
		setCoordinates(location);
	}

	return (
		<>
			<LocalIdleMap mapData={mapData} selectedSiteData={selectedSiteData} isDataVisible={showMap} coordinates={coordinates} onManualChange={onManualChange}/>
			<Box sx={{ pl: '10px', zIndex: 3, width: 'fit-content', display: "flex", alignItems: "baseline", position: "relative", top: "38px" }}>
				<Box sx={{ display: isFiltersCompose ? "flex" : "flex", gap: "10px", position: 'relative', bottom: '27px' }}>
					{brokers && (
						<CustomMultiSelectWithPlaceholder placeholder="Select broker" setSelectedSites={setSelectedBrokers} selectedSites={selectedBrokers}>
							{brokers.map((name: any) => (
								<MenuItem key={name.id} value={name.name}>
									<Checkbox checked={selectedBrokers.indexOf(name.name) > -1} />
									<ListItemText primary={name.name} />
								</MenuItem>
							))}
						</CustomMultiSelectWithPlaceholder>
					)}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Box sx={{ display: isFiltersCompose ? "grid" : "flex" }}>
							<ExternalFilterWrapper>
								<Box sx={{ fontSize: '11px' }}>Pickup from</Box>
								<DesktopDatePicker
									minDate={new Date()}
									inputFormat={getLocalDateFormat()}
									value={pickupDateValueFrom ?? null}
									onChange={(e) => handleChangePickupFrom(e)}
									renderInput={(params: any) => <TextField {...params} />}
								/>
							</ExternalFilterWrapper>
							<ExternalFilterWrapper>
								<Box sx={{ fontSize: '11px' }}>Pickup to</Box>
								<DesktopDatePicker
									minDate={new Date()}
									inputFormat={getLocalDateFormat()}
									value={pickupDateValueTo ?? null}
									onChange={(e) => handleChangePickupTo(e)}
									renderInput={(params: any) => <TextField {...params} />}
								/>
							</ExternalFilterWrapper>
							<ExternalFilterWrapper>
								<CustomSelectWithPlaceholder
									selectedValue={pickupState ?? ""}
									onChange={handleFilterPickupState}
									handleClearClick={handleClearClickPickupState}
									placeholder="Pickup state">
									{states?.map((item) => (
										<MenuItem value={item.code} key={item.id}>
											{item.name} ({item.code})
										</MenuItem>
									))}
								</CustomSelectWithPlaceholder>
							</ExternalFilterWrapper>
						</Box>
						<Box sx={{ display: isFiltersCompose ? "grid" : "flex", pr: '10px' }}>
							<ExternalFilterWrapper>
								<Box sx={{ fontSize: '11px' }}>Delivery from</Box>
								<DesktopDatePicker
									minDate={new Date()}
									inputFormat={getLocalDateFormat()}
									value={deliveryDateValueFrom ?? null}
									onChange={(e) => handleChangeDeliveryFrom(e)}
									renderInput={(params: any) => <TextField {...params} />}
								/>
							</ExternalFilterWrapper>
							<ExternalFilterWrapper>
								<Box sx={{ fontSize: '11px' }}>Delivery to</Box>
								<DesktopDatePicker
									minDate={new Date()}
									inputFormat={getLocalDateFormat()}
									value={deliveryDateValueTo ?? null}
									onChange={(e) => handleChangeDeliveryTo(e)}
									renderInput={(params: any) => <TextField {...params} />}
								/>
							</ExternalFilterWrapper>
							<ExternalFilterWrapper>
								<CustomSelectWithPlaceholder
									selectedValue={deliveryState ?? ""}
									onChange={handleFilterDeliveryState}
									handleClearClick={handleClearClickDeliveryState}
									placeholder="Delivery state">
									{states?.map((item) => (
										<MenuItem value={item.code} key={item.id}>
											{item.name} ({item.code})
										</MenuItem>
									))}
								</CustomSelectWithPlaceholder>
							</ExternalFilterWrapper>
						</Box>
					</LocalizationProvider>

				</Box>
			</Box >
			<DhlTable
				site={site}
				deliveryState={deliveryState}
				pickupState={pickupState}
				pickupDateValueTo={pickupDateValueTo}
				deliveryDateValueTo={deliveryDateValueTo}
				pickupDateValueFrom={pickupDateValueFrom}
				deliveryDateValueFrom={deliveryDateValueFrom}
				title=""
				data={data}
				totalRowCount={totalRowCount}
				columns={localIdletableColumns(equipments)}
				rowActions={undefined}
				tableActions={undefined}
				filterOptions={filterOptions}
				isLoading={isLoading}
				options={{
					search: false,
					downloadOptions: {
						filename: generateDownloadFilename('Local idle'),
						separator: ';',
						filterOptions: {
							useDisplayedColumnsOnly: true,
							useDisplayedRowsOnly: false,
						},
					},
					onDownload: createDownloadHandler(downloadColumnMapper, localIdleCsvMapper),
					textLabels: {
						body: {
							noMatch: isLoading ? 'Loading...' : 'No tasks',
						},
						pagination: {
							rowsPerPage: 'Routes per page',
						},
						toolbar: {
							downloadCsv: 'Download CSV (Current Page Only)',
						},
					},
					onRowClick: (rowData: any,) => {
						onRowSelect(rowData[1]);
					},
					setRowProps: (row) => {
						return {
							style: { cursor: 'pointer' },
						};
					},
				}}
				onDataFetchRequested={loadDataAndFiltersOptions} />

		</>
	)
}

