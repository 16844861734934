import React, { FC } from 'react';
import { drawerWidth } from './constants';
import MuiDrawer from '@mui/material/Drawer';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import SidebarList from './SidebarList';
import { SidebarProps } from './types';
import { menuItems, bottomItems } from './listItems';
import { LoginLogoutPart } from '../PageHeader/Authorization/LoginLogoutPart';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Sidebar: FC<SidebarProps> = ({ setOpen, open }) => {
  const isUserInfoVisible = useMediaQuery('(max-width:900px)');
  const theme = useTheme();

  return (
    <Drawer variant="permanent" open={open} className={'sidebar'}>
      <DrawerHeader>
        <IconButton onClick={() => setOpen(!open)}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Box sx={{ display: 'grid', alignContent: 'space-between', height: '100%' }}>
        <SidebarList items={menuItems} open={open} />
        {isUserInfoVisible && (
          <Box sx={{ mb: '10px' }}>
            <SidebarList items={bottomItems} open={open} />
            <LoginLogoutPart isSidebar />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
