import React, { FC } from 'react'
import { Checkbox } from '@mui/material'
import { useFormContext } from "react-hook-form";
import { InputNameApproave, InputNameDecline } from '../Modal/types';
import { FormItemWrapper } from './FormItemWrapper';
import { InputParametersName } from '../PursuitSupport/InputParameters/types';

interface CustomInputProps {
	label: string;
	inputName: InputNameApproave | InputNameDecline | InputParametersName;
	isRequired?: boolean
}

export const FormItemCheckbox: FC<CustomInputProps> = ({ label, inputName, isRequired = false }) => {
	const { register } = useFormContext();
	return (
		<FormItemWrapper label={label}>
			<Checkbox {...register(inputName, { required: isRequired })} name={inputName} />
		</FormItemWrapper>
	)
}
