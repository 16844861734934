import React, { ReactElement } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ColorCircle } from "./ColorCircle";
import { LaneScreenStatus } from "./LaneScreenStatus";
import { LaneScreenAction } from "./LanesScreenAction";
import { CircleStatus, Status } from "./types";
import {FormGroup, FormLabel, IconButton, TextField, Tooltip} from '@mui/material';
import { Link } from 'react-router-dom';
import { MUIDataTableColumn } from 'mui-datatables';
import { ROUTES } from '../../../routes';

const findLastCharWithinRange = (text:string, char: string, range:number):number => {
	let index = 0;
	for (let i = 0; i < range;) {
		const space = text.indexOf(char, i);
		if (space < 0) {
			if (index === 0) {
				index = range;
			}
			break;
		}
		i = space + 1;
		index = space;
	}
	return index;
}

const EllipsisWithTooltip: React.FC<{ text:any, length:number }> = ({text, length}) => {
	if (!text) {
		return <></>;
	}
	const showTooltip = text.length > length;
	const index = findLastCharWithinRange(text, " ", length);
	const value = text.substring(0, Math.min(index, text.length));
	return showTooltip ? <Tooltip title={text}><p>{`${value}`}<span>&hellip;</span></p></Tooltip> : <p>{text}</p>
}

export const tableColumns = () =>
  [
    {
      name: "id",
      label: "Lane route ID",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customFilterListOptions: { render: (v: any): string => `Route: ${v}` },
        display: false,
      },
    },
    {
      name: "created",
      label: "Created date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "site",
      label: "Site",
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customFilterListOptions: { render: (v): string => `Site: ${v?.code}` },
        customBodyRender: (
          value: Record<string, string | number>
        ): ReactElement => <div>{value}</div>,
      },
    },
    {
      name: "routeName",
      label: "Route name",
      options: {
        filter: false,
        sort: true,
        customFilterListOptions: {
          render: (routeName: string): string =>
            `Route name include: ${routeName}`,
        },
        customBodyRender: (value: Record<string, string>): ReactElement => (
          <EllipsisWithTooltip text={value} length={30} />
        ),
        filterType: "textField",
      },
    },
    {
      name: "bhPickup",
      label: "BH pickup",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bhPickupDate",
      label: "BH pickup date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bhDelivery",
      label: "BH delivery",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "brokerName",
      label: "Broker",
      options: {
        filter: false,
        sort: true,
        // filterType: 'textField',
        // customFilterListOptions: { render: (v): string => `Broker: ${v.name}` },
        customBodyRender: (
          value: Record<string, string | number>
        ): ReactElement => <div>{value ?? ""}</div>,
      },
    },
    {
      name: "loadId",
      label: "Load ID",
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (loadId: string): string => `Load ID: ${loadId}`,
        },
        filterType: "textField",
      },
    },
    {
      name: "oor",
      label: "OOR",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "distance",
      label: "Revenue Miles",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "oorFrom",
      options: {
        filter: true,
        display: "excluded",
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0]) {
              return `OOR from: ${v[0]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index = 6) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(fromMiles, filters) {
            if (filters[0] && filters[1]) {
              return fromMiles < filters[0] || fromMiles > filters[1];
            } else if (filters[0]) {
              return fromMiles < filters[0];
            } else if (filters[1]) {
              return fromMiles > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>OOR from</FormLabel>
              <FormGroup row>
                <TextField
                  type="number"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "oorTo",
      options: {
        filter: true,
        filterType: "custom",
        display: "excluded",
        customFilterListOptions: {
          render: (v) => {
            if (v[1]) {
              return `OOR to: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(toMiles, filters) {
            if (filters[1]) {
              return toMiles > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>OOR to</FormLabel>
              <FormGroup row>
                <TextField
                  type="number"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "bhMiles",
      label: "BH miles",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "suggestRate",
      label: "Suggested rate",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "gm",
      label: "SR GM",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "hos",
      label: "Hos",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        customBodyRender: (value: CircleStatus) => (
          <ColorCircle value={value} />
        ),
      },
    },
    {
      name: "rank",
      label: "Rank",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        customBodyRender: (rank: number) => <>{rank}.</>,
      },
    },
    {
      name: "updated",
      label: "Last updated date",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (action: number, actionStatus: any) => {
          return (
            <LaneScreenAction
              id={actionStatus?.rowData[0]}
              laneId={actionStatus?.rowData[21]}
              actionStatus={actionStatus?.rowData[20]}
              biddingEnabled={actionStatus?.rowData[26]}
            />
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (status: Status) => (
          <LaneScreenStatus status={status} />
        ),
      },
    },
    {
      name: "laneId",
      label: "Detail",
      options: {
        sort: false,
        filter: false,
        download: false,
        customBodyRender: (laneId: number, data) => {
          return (
            <Link to={`${ROUTES.lanesScreen}/${laneId}#${data?.rowData[0]}`}>
              <IconButton color="default">
                <MoreVertIcon />
              </IconButton>
            </Link>
          );
        },
      },
    },
    {
      name: "suggestRateFrom",
      options: {
        filter: true,
        display: "excluded",
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0]) {
              return `Suggest rate from: ${v[0]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index = 6) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(fromMiles, filters) {
            if (filters[0] && filters[1]) {
              return fromMiles < filters[0] || fromMiles > filters[1];
            } else if (filters[0]) {
              return fromMiles < filters[0];
            } else if (filters[1]) {
              return fromMiles > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Suggested rate from</FormLabel>
              <FormGroup row>
                <TextField
                  type="number"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "suggestRateTo",
      options: {
        filter: true,
        filterType: "custom",
        display: "excluded",
        customFilterListOptions: {
          render: (v) => {
            if (v[1]) {
              return `Suggest rate to: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(toMiles, filters) {
            if (filters[1]) {
              return toMiles > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Suggested rate to</FormLabel>
              <FormGroup row>
                <TextField
                  type="number"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "fromMiles",
      options: {
        filter: true,
        display: "excluded",
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v[0]) {
              return `Min miles: ${v[0]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index = 6) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(fromMiles, filters) {
            if (filters[0] && filters[1]) {
              return fromMiles < filters[0] || fromMiles > filters[1];
            } else if (filters[0]) {
              return fromMiles < filters[0];
            } else if (filters[1]) {
              return fromMiles > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>From miles</FormLabel>
              <FormGroup row>
                <TextField
                  type="number"
                  value={filterList[index][0] || ""}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "toMiles",
      options: {
        filter: true,
        filterType: "custom",
        display: "excluded",
        customFilterListOptions: {
          render: (v) => {
            if (v[1]) {
              return `Max miles: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(toMiles, filters) {
            if (filters[1]) {
              return toMiles > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>To miles</FormLabel>
              <FormGroup row>
                <TextField
                  type="number"
                  value={filterList[index][1] || ""}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "biddingEnabled",
      label: "Bidding Enabled",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
  ] as MUIDataTableColumn[];
