import { createContext, useContext, useState } from "react";

export interface LocalEquipmentContextProps {
	realoadNumber: number;
	setRealoadNumber: (realoadNumber: number) => void;
}

const noop = () => {
	console.warn('setRealodNumber hook used outside of LocalEquipmentContextContainer');
};

const noContextObj = {
	realoadNumber: 0,
	setRealoadNumber: noop
};

export const useLocalEquipmentContext = (): LocalEquipmentContextProps => {
	return useContext(LocalEquipmentContext) || noContextObj;
};

const LocalEquipmentContext = createContext<LocalEquipmentContextProps>(noContextObj);

export const LocalEquipmentContextContainer: React.FC = ({ children }) => {
	const [realoadNumber, setRealoadNumber] = useState<number>(0);

	return (
		<LocalEquipmentContext.Provider value={{ realoadNumber, setRealoadNumber }}>
			{children}
		</LocalEquipmentContext.Provider>
	);
};