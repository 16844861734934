import { isSomeContainInObject } from '../components/DHLTable/action.utils';
import { BidType } from '../components/LaneScreenDetail/MatchesRoutes/BidHistory/types';
import { post } from './service';
import { FilterType, Type } from './types';


export const postBidHistory = async ({ filtersParam, sortingParam, paginationParam, id, bidType }: any): Promise<any | undefined> => {
	const filters = {};
	const sortingData = {};

	if (isSomeContainInObject(sortingParam)) {
		Object.assign(sortingData, { "colId": sortingParam.columnName, "sort": sortingParam.direction });
	}

	if (filtersParam?.bidMessage?.values.length > 0) {
		Object.assign(filters, { bidMessage: { "filterType": FilterType.TEXT, "filter": filtersParam.bidMessage.values[0], type: Type.CONTAINS } });
	}

	if (filtersParam?.id?.values.length > 0) {
		Object.assign(filters, { id: { "filterType": FilterType.NUMBER, "filter": filtersParam.id.values[0], type: Type.EQUALS } });
	}

	if (filtersParam?.rate?.values.length > 0) {
		Object.assign(filters, { rate: { "filterType": FilterType.NUMBER, "filter": filtersParam.rate.values[0], type: Type.EQUALS } });
	}
	if (filtersParam?.status?.values.length > 0) {
		Object.assign(filters, { status: { "filterType": FilterType.TEXT, "filter": filtersParam.status.values[0], type: Type.CONTAINS } });
	}

	try {
		const url = BidType.MATCH === bidType ? "laneRoute" : "lane";
		const idFilter = BidType.MATCH === bidType;

		const response = await post<any>(`${url}/bid`,
			{
				"page": paginationParam?.currentPageIndex ?? 0,
				"size": paginationParam?.rowsPerPage ?? 20,
				"filter": {
					...(idFilter ? 
						{ laneToRoute : { "filterType": "number", "type": "equals", "filter": id }} : 
						{ lane : { "filterType": "number", "type": "equals", "filter": id }}
					),
					...filters,
				},
				...(isSomeContainInObject(sortingParam) && { 'sort': [sortingData] })
			}
		);
		return response.data || undefined;
	} catch (e) {
		throw e;
	}
};