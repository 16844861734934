import React, { FC } from 'react'
import { Box } from '@mui/system'
import { blue, green, red, yellow } from '@mui/material/colors';
import { LaneScreenStatusProps, Result } from './types';
import { Button, Popover } from '@mui/material';

const getResultColor = (result: Result): string => {
	return ({
		[Result.IN_PROCESS]: yellow[700],
		[Result.INVALID]: red[300],
		[Result.PROCESSED]: blue[600],
		[Result.NEW]: green[600],
		[Result.FAILED]: red[300],
	})[result]
}

const getResultText = (result: Result): string => {
	return ({
		[Result.IN_PROCESS]: "In process",
		[Result.FAILED]: "Failed",
		[Result.INVALID]: "Invalid",
		[Result.PROCESSED]: "Processed",
		[Result.NEW]: "New"
	})[result]
}

export const RfpResult: FC<LaneScreenStatusProps> = ({ result, log }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const logText = log?.rowData[0];

	if (logText) {
		return (
			<>
				<Button aria-describedby={id} variant="outlined" onClick={handleClick} sx={{
					border: '2px solid', ":hover": { border: '2px solid', borderColor: red[800], color: red[800] },
					borderColor: getResultColor(result),
					color: getResultColor(result),
					display: 'flex',
					justifyContent: 'center',
					fontWeight: '600',
					padding: 1,
					borderRadius: 0,
					textTransform: 'capitalize',
					width: '100%'

				}}>

					{getResultText(result)}
				</Button>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<Box sx={{ p: 4, maxWidth: '400px', background: red[900], color: 'white' }}>{logText}</Box>
				</Popover>
			</>
		)
	}

	return (
		<>
			<Box
				sx={{
					border: '2px solid',
					borderColor: getResultColor(result),
					color: getResultColor(result),
					display: 'flex',
					justifyContent: 'center',
					fontWeight: '600',
					padding: 1
				}}>
				{getResultText(result)}
			</Box>

		</>

	)
}

