import { formatAsLocalDateTime, getLocation } from "../../helpers";
import { RouteResponseContentType, RouteResponseType, RouteType } from "./types";

export const AVAILABLE = 'Available';

export const routeToRow = (route: RouteResponseContentType): RouteType => {
	const { routeStart, id, reason, code, site, lastStopLocation, returnLocation, returnMiles, lastStopDate, externalId, t5Shift, name } = route;
	const routeStartDate = routeStart && new Date(routeStart);
	const date = routeStartDate ? formatAsLocalDateTime(routeStartDate) : "";

	const lastStopDateValue = lastStopDate && new Date(lastStopDate);
	const lastStopDateFormat = lastStopDateValue ? formatAsLocalDateTime(lastStopDateValue) : "";

	const routeTorRowData = {
		id: id,
		externalId: externalId,
		routeId: id,
		reason: reason,
		routeName: code,
		code: code,
		site: site.code,
		lastStop: lastStopLocation ? getLocation({ city: lastStopLocation?.city, state: lastStopLocation?.state }) : null,
		lastStopDate: lastStopDateFormat,
		returnLocation: returnLocation ? getLocation({ city: returnLocation.city, state: returnLocation.state }) : null,
		returnMiles: returnMiles?.toFixed(2),
		fromMiles: returnMiles?.toFixed(2),
		toMiles: returnMiles?.toFixed(2),
		routeStart: date,
		siteId: site.id,
		t5Shift: t5Shift,
		name: name,
	}

	return routeTorRowData
};

export const routeResponseToRows = (response: RouteResponseType): RouteType[] => {
	return response.content.map((row) => routeToRow(row));
};

export const routeCsvMapper = {
	reason: (value:any) => !value ? AVAILABLE : value.name,
}