import React, {FC, useContext, useState} from 'react';
import { Box, Button, IconButton } from '@mui/material';

import { useModalState } from '../../../../hooks/useModalState';
import { ApproaveModal, DeclineModal } from '../../../Modal';
import { ModalWrapper } from '../../../Modal/ModalWrapper';
import { green, grey, red } from '@mui/material/colors';
import { ApproveIcon, DeclineIcon } from '../../../../assets/icons';
import { Status } from '../../../LanesScreen/LanesScreenTable/types';
import { disabledButtonStyle } from '../../../LanesScreen/LanesScreenTable/LanesScreenAction';
import {fetchLaneAvailability, fetchLaneDetail} from "../../../../service/fetchLaneDetail";
import {showErrorNotyf} from "../../../DHLTable/general.utils";
import NotyfContext from "../../../DHLTable/NotyfContext";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { BidType } from '../BidHistory/types';

const disabledButton = {
	width: 'fit-content',
	color: grey[600],
	borderColor: grey[600]
}

export const Actions: FC<{ id: number, status: Status, laneId: number, biddingEnabled: boolean }> = ({ id, status, laneId, biddingEnabled }) => {
	const { isModalOpen: isModalOpenApproave, closeModal: closeModalApproave, openModal: openModalApproave } = useModalState();
	const { isModalOpen: isModalOpenDecline, closeModal: closeModalDecline, openModal: openModalDecline } = useModalState();
	const notyf = useContext(NotyfContext);
	const activeIcons = status === Status.MATCH_FOUND;
	const [loading, setLoading] = useState(false);
	const [laneStatus, setLaneStatus] = useState(true);
	return (
		<>
			<Box display='flex' justifyContent='flex-end' gap='20px'>
				{activeIcons ? (
					<>
						<Button type="submit" color='inherit' variant="outlined" endIcon={<DeclineIcon color={red[600]} />} onClick={openModalDecline} sx={{ width: 'fit-content', color: red[700], borderColor: red[600] }}>
							Decline Match
						</Button>
						{biddingEnabled && <Button type='submit' variant="outlined" color='inherit' disabled={loading || !laneStatus} endIcon={loading ? <AutorenewIcon /> : <ApproveIcon color={laneStatus ? green[500] : grey[500]} />} onClick={async () => {
							setLoading(true);
							const response = await fetchLaneAvailability(laneId);
							setLoading(false);
							if (response) {
								openModalApproave();
							} else {
								setLaneStatus(false);
								showErrorNotyf(notyf, "Lane is not available", "Status");
							}
						}} sx={{ width: 'fit-content', color: green[800], borderColor: green[500] }}>
							Submit Bid
						</Button>}
					</>
				) : (
					<>
						{/*<IconButton aria-label="approave" sx={disabledButtonStyle}>*/}
							<Button type="submit" color='inherit' variant="outlined" endIcon={<DeclineIcon color={grey[600]} />} onClick={()=>{}} sx={disabledButton}>
								Decline Match
							</Button>
						{/*</IconButton>
						<IconButton aria-label="decline" sx={disabledButtonStyle}>*/}
							{biddingEnabled && <Button type='submit' variant="outlined" color='inherit' endIcon={<ApproveIcon color={grey[500]} />} onClick={()=>{}} sx={disabledButton}>
								Submit Bid
							</Button>}
						{/*</IconButton>*/}
					</>
				)}


			</Box>
			<ModalWrapper isOpen={isModalOpenApproave} closeModal={closeModalApproave}>
				<ApproaveModal id={id} closeModalApproave={closeModalApproave} bidType={BidType.LANE} />
			</ModalWrapper>
			<ModalWrapper isOpen={isModalOpenDecline} closeModal={closeModalDecline}>
				<DeclineModal id={id} closeModalDecline={closeModalDecline} />
			</ModalWrapper>
		</>
	)
}