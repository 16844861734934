import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { fetchLocalIdleLaneDetail } from '../../../service/fetchLaneDetail';
import { Center } from '../../layout/Center';
import { ContentHeader } from '../../layout/ContentHeader';
import { LocalIdleLaneScreenDetailContent } from './LocalIdleLaneScreenDetailContent';
import { BidContext, useBidRealod } from '../../LaneScreenDetail/MatchesRoutes/BidHistory/BidContext';

export const LocalIdleLanesScreenDetail: FC = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [detail, setDetailData] = useState<any>();
	const { realoadNumber } = useBidRealod();

	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			if (id) {
				const response = await fetchLocalIdleLaneDetail(id);
				setDetailData(response);
				setIsLoading(false);
			}
		}

		fetchData()
			.catch(console.error);
	}, [id, realoadNumber])

	if (isLoading) {
		return (
			<Center>
				<CircularProgress size={25} />
			</Center>
		)
	}

	return (
		<BidContext>
			<ContentHeader header={`Load ID - ${detail.loadId}`} />
			<LocalIdleLaneScreenDetailContent detail={detail}/>
		</BidContext>
	)
}