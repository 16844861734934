import React, { useEffect, useState } from 'react'
import { CapacityAssignment } from './CapacityAssignment'
import { ContentHeader } from '../layout/ContentHeader'
import { LatestImportLogResponseType } from '../LocalIdleDashboard/LocalIdleTable/types'
import { fetchLatestImportLog } from '../../service/fetchImportLog'
import { formatAsLocalDateTime } from '../../helpers'

export const CapacityAssigmentScreen: React.FC = () => {
	const [ lastImportInfo, setLastImportInfo ] = useState<LatestImportLogResponseType>();

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetchLatestImportLog('route');
			setLastImportInfo(response);
		}

		fetchData()
			.catch(console.error);
	}, [])

	const getLocalDateTime = () => {
		return lastImportInfo?.finished ? `Last updated on: ${formatAsLocalDateTime(lastImportInfo?.finished!)}` : 'Last updated on: unknown';
	}

	
	return (
		<>
			<ContentHeader header='Capacity Assignment Screen' importInfo={getLocalDateTime()} />
			<CapacityAssignment />
		</>
	)
}