import React, {FC, useContext, useState} from 'react';
import { Box, Button } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { useModalState } from '../../../hooks/useModalState';
import NotyfContext from '../../DHLTable/NotyfContext';
import { ApproveIcon } from '../../../assets/icons';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { fetchLaneAvailability } from '../../../service/fetchLaneDetail';
import { showErrorNotyf } from '../../DHLTable/general.utils';
import { ModalWrapper } from '../../Modal/ModalWrapper';
import { ApproaveModal } from '../../Modal';
import { BidType } from '../../LaneScreenDetail/MatchesRoutes/BidHistory/types';

const disabledButton = {
	width: 'fit-content',
	color: grey[600],
	borderColor: grey[600]
}

export const Actions: FC<{ laneId: number, biddingEnabled: boolean }> = ({ laneId, biddingEnabled }) => {
	const { isModalOpen: isModalOpenApproave, closeModal: closeModalApproave, openModal: openModalApproave } = useModalState();
	const notyf = useContext(NotyfContext);
	const [loading, setLoading] = useState(false);
	const [laneStatus, setLaneStatus] = useState(true);
	return (
		<>
			<Box display='flex' justifyContent='flex-end' gap='20px'>
				{true ? (
					<>
						{biddingEnabled && <Button type='submit' variant="outlined" color='inherit' disabled={loading || !laneStatus} endIcon={loading ? <AutorenewIcon /> : <ApproveIcon color={laneStatus ? green[500] : grey[500]} />} onClick={async () => {
							setLoading(true);
							const response = await fetchLaneAvailability(laneId);
							setLoading(false);
							if (response) {
								openModalApproave();
							} else {
								setLaneStatus(false);
								showErrorNotyf(notyf, "Lane is not available", "Status");
							}
						}} sx={{ width: 'fit-content', color: green[800], borderColor: green[500] }}>
							Submit Bid
						</Button>}
					</>
				) : (
					<>
						{biddingEnabled && <Button type='submit' variant="outlined" color='inherit' endIcon={<ApproveIcon color={grey[500]} />} onClick={()=>{}} sx={disabledButton}>
							Submit Bid
						</Button>}
					</>
				)}


			</Box>
			<ModalWrapper isOpen={isModalOpenApproave} closeModal={closeModalApproave}>
				<ApproaveModal id={laneId} closeModalApproave={closeModalApproave} bidType={BidType.LANE} />
			</ModalWrapper>
		</>
	)
}