import React, { FC, useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import NotyfContext from "../../DHLTable/NotyfContext";
import { showErrorNotyf } from "../../DHLTable/general.utils";
import { Center } from "../../layout/Center";
import { postSubscribe, postUnsubscribe } from "../../../service/postSubscription";

export const SubscriptionModal: FC<{ isSubscribed: boolean | null; userEmail: string; sites: any; closeModal: any; handleSubscribe: () => void }> = ({
  isSubscribed,
  userEmail,
  sites,
  closeModal,
  handleSubscribe
}) => {
  const methods = useForm();
  const notyf = useContext(NotyfContext);
  const action = isSubscribed ? 'unsubscribe' : 'subscribe';
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const siteIdList = sites.map((site: { id: any; }) => site.id);
      let response = null;

      if(isSubscribed) {
        response = await postUnsubscribe(userEmail, siteIdList);
      } else {
        response = await postSubscribe(userEmail, siteIdList);
      }

      await notyf.success(`You have been successfully ${action}d`);      
      closeModal();
      setIsLoading(false);
      handleSubscribe();
      return response || undefined;
    } catch (e) {
      setIsLoading(false);
      showErrorNotyf(notyf, '', `Failed to ${action}`);
      closeModal();
      throw e;
    }
  };

  useEffect(() => {
    return () => closeModal();
  }, [methods.formState.isSubmitSuccessful]);

  const isbuttonDisabled = methods.formState.isSubmitting;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mt={"40px"}>
            <Box>
              {`Are you sure you want to ${action} to the following sites:`}
            </Box>
            <Box>

            <List dense={true}>
              {sites.map((site: { id: any, code: any; }) => 
                <ListItem  sx={{
                    padding: 0,
                    textAlign: "left",
                    listStyleType: "disc",
                    display: "list-item",
                 }} key={`list-item-${site.id}`}>
                  <ListItemText
                    key={`list-item-text-${site.id}`}
                    primary={site.code}
                  />
                </ListItem>)}
            </List>
            </Box>
            <Center mt="30px">
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ width: "fit-content", mb: "10px" }}
                disabled={isbuttonDisabled}
              >
                {isLoading ? <CircularProgress size={15} /> : isSubscribed ? 'Unsubscribe' : 'Subscribe'}
              </Button>
            </Center>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};
