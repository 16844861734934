import React from 'react'
import { MUIDataTableColumn } from 'mui-datatables';
import { RfpResult } from './RfpResult';
import { Result } from './types';
import { DownloadFileTable } from './DownloadFileTable';


export const rfPtableColumns = () => [
	{
		name: "log",
		label: "Log",
		options: {
			display: 'excluded'
		}
	},
	{
		name: "id",
		label: "RFP ID",
		options: {
			sort: true,
			filter: true,
		}
	},
	{
		name: "inputFile",
		label: "Input file",
		options: {
			sort: false,
			filter: true,
			customBodyRender: (inputFile: any) => inputFile ? <DownloadFileTable inputFile={inputFile} /> : <></>,
		}
	},
	{
		name: "uploadedBy",
		label: "Uploaded by",
		options: {
			sort: true,
			filter: true,
		}
	},
	{
		name: "uploadDate",
		label: "Uploaded date",
		options: {
			sort: true,
			filter: true,
		}
	},
	{
		name: "result",
		label: "Result",
		options: {
			filter: true,
			sort: false,
			customBodyRender: (result: Result, log: null | string) => <RfpResult result={result} log={log} />,
		}
	},
	{
		name: "routes",
		label: "Routes",
		options: {
			sort: true,
			filter: true,
		}
	},
	{
		name: "lanesFound",
		label: "Lanes found",
		options: {
			sort: false,
			filter: true,
		}
	},
	{
		name: "outputfile",
		label: "Output file",
		options: {
			sort: false,
			filter: true,
			customBodyRender: (outputfile: any) => outputfile ? <DownloadFileTable inputFile={outputfile} /> : <></>,
		}
	},
	{
		name: "processDate",
		label: "Process date",
		options: {
			sort: true,
			filter: true,
		}
	}
] as MUIDataTableColumn[];

