import { isSomeContainInObject } from '../components/DHLTable/action.utils';
import { post } from './service';


export const postRFPTable = async ({ sortingParam, paginationParam }: any): Promise<any | undefined> => {
	const sortingData = { colId: "uploadDate", sort: "desc" };

	if (isSomeContainInObject(sortingParam)) {
		Object.assign(sortingData, { "colId": sortingParam.columnName, "sort": sortingParam.direction });
	}

	try {
		const response = await post<any>(`rfp`,
			{
				"page": paginationParam?.currentPageIndex ?? 0,
				"size": paginationParam?.rowsPerPage ?? 20,
				'sort': [sortingData],
			}
		);
		return response.data || undefined;
	} catch (e) {
		throw e;
	}
};
