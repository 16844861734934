import React, { FC } from 'react';
import { Link, ListItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CustomListItemProps } from './types';
import { CustomListItemContent } from './CustomListItemContent';

export const CustomListItem: FC<CustomListItemProps> = ({ menu, open }) => {
	const { isNested, path, isExternal, title } = menu;
	const location = useLocation();

	return (
		<ListItem
			title={title}
			component={Link}
			target={isExternal ? "_blank" : undefined}
			{...!isNested && { href: path }}
			rel="noreferrer"
			sx={{
				minHeight: 48,
				cursor: 'pointer',
				px: '6px',
				background: path === location.pathname ? "#FFCC00" : 'transparent'
			}
			}
		>
			<CustomListItemContent menu={menu} open={open} />
		</ListItem >
	)
}
