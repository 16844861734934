import { post } from './service';


export const postDistribute = async (): Promise<any | undefined> => {
	try {
		const response = await post<any>(`capacity/distribute`, {});
		return response || undefined;
	} catch (e) {
		throw e;
	}
};
