import { isSomeContainInObject } from '../components/DHLTable/action.utils';
import { formatDateForFilter } from './postCapacityAssignmentRoute';
import { post } from './service';
import { FilterType, Type } from './types';


// TODO dtomas refactor filter model creating
export const postLaneRoute = async ({ site, filtersParam, sortingParam, paginationParam, dateValueTo, dateValueFrom, postedBrokers }: any): Promise<any | undefined> => {
	const filters = {};
	const sortingData = {};
	const dateTo = formatDateForFilter(dateValueTo);
	const dateFrom = formatDateForFilter(dateValueFrom)

	if (isSomeContainInObject(sortingParam)) {
		Object.assign(sortingData, { "colId": sortingParam.columnName, "sort": sortingParam.direction });
	}
	if (site?.length > 0) {
		Object.assign(filters, { site: { "filterType": FilterType.SET, "values": site } });
	}
	if (filtersParam?.id?.values.length > 0) {
		Object.assign(filters, { id: { "filterType": FilterType.NUMBER, "filter": filtersParam.id.values[0], type: Type.EQUALS } });
	}
	if (filtersParam?.loadId?.values.length > 0) {
		Object.assign(filters, { loadId: { "filterType": FilterType.TEXT, "filter": filtersParam.loadId.values[0], type: Type.CONTAINS } });
	}
	if (filtersParam?.oorFrom?.values.length > 0 && filtersParam?.oorTo?.values.length === 0) {
		Object.assign(filters, { oor: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.oorFrom.values[0]), type: Type.GREATER_THAN_OR_EQUAL } });
	}
	if (filtersParam?.oorFrom?.values.length > 0 && filtersParam?.oorTo?.values.length > 0) {
		Object.assign(filters, { oor: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.oorFrom.values[0]), "filterTo": Number(filtersParam?.oorTo.values[1]), type: Type.RANGE } });
	}
	if (filtersParam?.oorFrom?.values.length === 0 && filtersParam?.oorTo?.values.length > 0) {
		Object.assign(filters, { oor: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.oorTo.values[1]), type: Type.LESS_THAN_OR_EQUAL } });
	}
	if (filtersParam?.oor?.values.length > 0) { //
		Object.assign(filters, { oor: { "filterType": "number", "filter": filtersParam.oor.values[0], type: Type.EQUALS } });
	}
	if (filtersParam?.fromMiles?.values.length > 0 && filtersParam?.toMiles?.values.length === 0) {
		Object.assign(filters, { bhMiles: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.fromMiles.values[0]), type: Type.GREATER_THAN_OR_EQUAL } });
	}
	if (filtersParam?.fromMiles?.values.length > 0 && filtersParam?.toMiles?.values.length > 0) {
		Object.assign(filters, { bhMiles: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.fromMiles.values[0]), "filterTo": Number(filtersParam?.toMiles.values[1]), type: Type.RANGE } });
	}
	if (filtersParam?.fromMiles?.values.length === 0 && filtersParam?.toMiles?.values.length > 0) {
		Object.assign(filters, { bhMiles: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.toMiles.values[1]), type: Type.LESS_THAN_OR_EQUAL } });
	}
	if (filtersParam?.suggestRateFrom?.values.length > 0 && filtersParam?.suggestRateTo?.values.length === 0) {
		Object.assign(filters, { suggestedRate: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.suggestRateFrom.values[0]), type: Type.GREATER_THAN_OR_EQUAL } });
	}
	if (filtersParam?.suggestRateFrom?.values.length > 0 && filtersParam?.suggestRateTo?.values.length > 0) {
		Object.assign(filters, { suggestedRate: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.suggestRateFrom.values[0]), "filterTo": Number(filtersParam?.suggestRateTo.values[1]), type: Type.RANGE } });
	}
	if (filtersParam?.suggestRateFrom?.values.length === 0 && filtersParam?.suggestRateTo?.values.length > 0) {
		Object.assign(filters, { suggestedRate: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam?.suggestRateTo.values[1]), type: Type.LESS_THAN_OR_EQUAL } });
	}
	if (dateValueFrom && !dateValueTo) {
		Object.assign(filters, { bhPickupDate: { "filterType": FilterType.DATE, "type": Type.GREATER_THAN, "dateFrom": dateFrom } });
	}
	if (dateValueTo && !dateValueFrom) {
		Object.assign(filters, { bhPickupDate: { "filterType": FilterType.DATE, "type": Type.LESS_THAN, "dateFrom": dateTo } });
	}
	if (dateValueTo && dateValueFrom) {
		Object.assign(filters, { bhPickupDate: { "filterType": FilterType.DATE, "type": Type.RANGE, "dateFrom": dateFrom, "dateTo": dateTo } });
	}
	// brokers
	if (postedBrokers?.length > 0) {
		Object.assign(filters, { broker: { "filterType": FilterType.SET, "values": postedBrokers } });
	}

	try {
		const response = await post<any>(`laneRoute`,
			{
				"page": paginationParam?.currentPageIndex ?? 0,
				"size": paginationParam?.rowsPerPage ?? 20,
				"filter": {
					...filters,
				},
				...(isSomeContainInObject(sortingParam) && { 'sort': [sortingData] })
			}
		);
		return response.data || undefined;
	} catch (e) {
		throw e;
	}
};