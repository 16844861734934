import React, { FC, useEffect } from 'react';
import { Button } from '@mui/material';
import { green } from '@mui/material/colors';
import { ApproveIcon } from '../../assets/icons';
import { useFormContext } from 'react-hook-form';

export const SubmitButton: FC<{ onClick?: () => void | any, disabled?: boolean, closeModalApproave: any }> = ({ onClick, disabled, closeModalApproave }) => {
	const { formState: { isSubmitSuccessful } } = useFormContext()

	useEffect(() => {
		if (isSubmitSuccessful) {
			closeModalApproave()
		}
	}, [isSubmitSuccessful])

	return (
		<Button type='submit' disabled={disabled} variant="outlined" color='inherit' endIcon={<ApproveIcon color={green[500]} />} onClick={onClick} sx={{ width: 'fit-content', color: green[800], borderColor: green[500] }}>
			Submit Bid
		</Button>
	)
}