import { get } from "./service";

export const fetchReason = async (): Promise<any> => {
	const response = await get<any>('code/route-unavailability-reason');
	return response.data;
};

export const fetchDeclineReason = async (): Promise<any> => {
	const response = await get<any>('code/match-decline-reason');
	return response.data;
};
