import { Box, FormControl, IconButton, InputLabel, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react'

interface Props {
	children: React.ReactNode;
	onChange: any;
	selectedValue: any;
	placeholder: string | React.ReactNode;
	style?: Record<string, string | number>;
	handleClearClick?: () => void;
}

interface MultiProps {
	children: React.ReactNode;
	selectedSites: any[];
	setSelectedSites: (selectedSites: any[]) => void;
	placeholder: string | React.ReactNode;
}


export const CustomSelectWithPlaceholder: FC<Props> = ({ selectedValue, onChange, children, style, placeholder, handleClearClick }) => {
	return (
		<FormControl variant="standard">
			<InputLabel>{placeholder}</InputLabel>
			<Select
				displayEmpty
				onChange={onChange}
				value={selectedValue}
				style={style ?? { width: "160px", marginBottom: "10px", marginRight: 10 }}
				endAdornment={<IconButton sx={{ display: selectedValue ? "block" : "none", position: 'relative', right: '20px', height: '40px' }} onClick={handleClearClick}><CloseIcon /></IconButton>}
			>
				{children}
			</Select>
		</FormControl>
	)
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 160,
		},
	},
};



export const CustomMultiSelectWithPlaceholder: FC<MultiProps> = ({ placeholder, children, setSelectedSites, selectedSites }) => {

	const handleChange = (event: SelectChangeEvent<typeof selectedSites>) => {
		const {
			target: { value },
		} = event;
		setSelectedSites(
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		<FormControl variant="standard">
			<Box sx={{ fontSize: '11px' }}>{placeholder}</Box>
			<Select
				multiple
				value={selectedSites}
				onChange={handleChange}
				input={<OutlinedInput label="Tag" />}
				renderValue={(selected) => selected.join(', ')}
				MenuProps={MenuProps}
				style={{ width: "160px", marginBottom: "10px", marginRight: 10 }}
			>
				{children}
			</Select>
		</FormControl>
	)
}
