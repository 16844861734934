import React from "react";
import { MUIDataTableColumn } from "mui-datatables";
import { InnerTable } from "../InnerTable";
import { Schedule } from "../Schedule";
import { LaneRoutesType, RoutesType } from "./types";

export const localEquipmentColumns = () =>
  [
    {
      name: "equipmentCode",
      label: "Equipment code",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "routes",
      label: "Schedule",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: 'center' }),
        sort: false,
        customBodyRender: (routes: LaneRoutesType[]) => <Schedule routes={routes} />,
      },
    },
    {
      name: "laneRoutes",
      label: " ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (laneRoutes: LaneRoutesType[]) => (
          <InnerTable laneRoutes={laneRoutes} />
        ),
      },
    },
  ] as MUIDataTableColumn[];
