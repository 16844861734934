import React, { FC, useContext, useState } from 'react'
import { FormControl, MenuItem, Select } from '@mui/material';
import { post } from '../../service/service';
import { showErrorNotyf } from '../DHLTable/general.utils';
import NotyfContext from '../DHLTable/NotyfContext';
import {AVAILABLE} from "./helpers";

export const saveRoute = async ({ routeId, reason }: { routeId: number, reason: number | null }): Promise<any | undefined> => {
	try {
		const response = await post<any>(`route/status`, {
			data: [{
				"id": Number(routeId),
				"reason": Number(reason)
			}]
		});
		return response.data || undefined;
	} catch (e) {
		throw e;
	}
};


export const ReasonSelect: FC<{ reason: any, reasons: any[], routeId: number | any }> = ({ reason, reasons, routeId }) => {
	const [reasonText, setReasonText] = useState<null | number>(reason);
	const notyf = useContext(NotyfContext);

	const handleChange = async (e: any) => {
		setReasonText(e.target.value)
		try {
			await saveRoute({ routeId: routeId.rowData[1], reason: e.target.value });
			await notyf.success('The reason selected');
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to save reason')
		}
	};

	const newReasons = [
		{ id: 0, name: AVAILABLE }, ...reasons];


	return (
		<>
			<FormControl>
				<Select
					variant="standard"
					value={reasonText ?? 0}
					style={{ width: "160px", fontSize: '13.5px', padding: '0' }}
					onChange={(e) => handleChange(e)}
				>
					{newReasons.map(reason => <MenuItem key={reason.id} value={reason.id}>{reason.name}</MenuItem>)}
				</Select>
			</FormControl>
		</>
	)
}
