import { Box, Button, Popover } from '@mui/material';
import React, { FC } from 'react'
import { grey, yellow } from '@mui/material/colors';
import { MarkerIcon } from '../../../MarkerIcon';
import { TooltipContent } from '../../../LaneScreenDetail/table/TooltipContent';
// import { MarkerIcon } from '../../MarkerIcon';
// import { TooltipContent } from './TooltipContent';

export const DetailTableItem: FC<any> = ({ item }) => {
	const { text, value, tooltip, id, address, tooltipColor, tooltipIcon } = item;
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const idPop = open ? 'simple-popover' : undefined;

	return (
		<Box display='grid' gridTemplateColumns='1fr 1fr' border={`1px solid ${grey[500]}`}>
			<Box borderRight={`1px solid ${grey[500]}`} p={1.2} bgcolor={grey[200]}>{text}</Box>
			<Box display='flex' p={1.2} alignItems='center'>
				{value && <Box sx={{ fontWeight: id === 2 ? "700" : undefined }}>{value}</Box>}

				{tooltip && address && (
					<>
						<Button aria-describedby={idPop} variant="text" onClick={handleClick}>
							{tooltipIcon ? <img src={tooltipIcon} height={23} /> : <MarkerIcon color={tooltipColor || yellow[600]} />}
						</Button>

						<Popover
							id={idPop}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<TooltipContent address={address} />
						</Popover>
					</>
				)}
			</Box>
		</Box>
	)
}