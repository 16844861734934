
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { fetchMap } from '../../../../service/fetchMap';
import { Map } from './Map';
import { Center } from '../../../layout/Center';
import { CircularProgress } from '@mui/material';

export const DetailMap: React.FC<{lane: any, value: number, routeId: number}> = ({lane, value, routeId}) => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [detail, setDetailData] = useState<any>()

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			if (id) {
				const response = await fetchMap(id);
				setDetailData(response);
				setIsLoading(false)
			}
		}

		fetchData()
			.catch(console.error);

	}, [id])

	if (isLoading) {
		return <Center>
			<CircularProgress size={25} />
		</Center>
	}

	if (detail?.laneRoutes.length=== 0) {
		return (
			<Center>
			No data for map available
		</Center>
		)
	}

	return (
		<div>
			<Map lane={lane} value={value} routeId={routeId} data={detail.laneRoutes} />
		</div>
	)
}
