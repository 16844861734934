import React, { FC } from "react";
import { TableFilterList } from "mui-datatables";
import { Chip } from "@mui/material";

const CustomChip: FC<any> = ({ label, onDelete }) => {
	return (
		<Chip
			variant="outlined"
			sx={{ my: '10px', mr: '10px' }}
			label={label}
			onDelete={onDelete}
		/>
	);
};

export const CustomFilterList: FC<any> = (props) => {
	return <TableFilterList {...props} ItemComponent={CustomChip} />;
};
