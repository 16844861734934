import React, { FC, ReactNode } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { useFormContext } from "react-hook-form";

import { InputNameApproave, InputNameDecline } from '../Modal/types';
import { FormItemWrapper } from './FormItemWrapper';
import { InputParametersName } from '../PursuitSupport/InputParameters/types';
import { grey } from '@mui/material/colors';
import { DeclineIcon } from '../../assets/icons';

interface FormInputFileProps {
	label: string;
	inputName: InputNameApproave | InputNameDecline | InputParametersName;
	isRequired?: boolean
	buttonText: string | ReactNode
}

export const FormInputFile: FC<FormInputFileProps> = ({ label, inputName, buttonText }) => {
	const { register, watch, setValue } = useFormContext();
	const fileNameValue = watch(inputName)?.length > 0 ? watch(inputName) : "";

	return (
		<FormItemWrapper label={label}>

			<Box textAlign={'center'} display={'grid'}>
				{fileNameValue[0]?.name ?? ""}
				<Box>
					<Button variant="outlined" component="label" sx={{ color: grey[800], borderColor: grey[800] }}>
						{buttonText}
						<input hidden multiple type="file" 	{...register(inputName, { required: true })} />
					</Button>
					{fileNameValue[0]?.name && (
						<IconButton color='default' onClick={() => setValue(inputName, null)} >
							<DeclineIcon />
						</IconButton>
					)}
				</Box>
			</Box>
		</FormItemWrapper >

	)
}
