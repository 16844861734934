import { createContext, useContext, useState } from "react";

interface RfpContextProps {
	realoadNumber: number;
	setRealoadNumber: (realoadNumber: number) => void;
}

const noop = () => {
	console.warn('setRealodNumber hook used outside of RfpContext');
};

const noContextObj = {
	realoadNumber: 0,
	setRealoadNumber: noop
};

export const useRfpRealod = (): RfpContextProps => {
	return useContext(RfpReloadContext) || noContextObj;
};

const RfpReloadContext = createContext<RfpContextProps>(noContextObj);

export const RfpContext: React.FC = ({ children }) => {
	const [realoadNumber, setRealoadNumber] = useState<number>(0);

	return (
		<RfpReloadContext.Provider value={{ realoadNumber, setRealoadNumber }}>
			{children}
		</RfpReloadContext.Provider>
	);
};