import { isSomeContainInObject } from '../components/DHLTable/action.utils';
import { mockBrokers } from '../components/LocalIdleDashboard/LocalIdleTable/helpers';
import { localIdleRouteResponseContentType } from '../components/LocalIdleDashboard/LocalIdleTable/types';
import { formatDateForFilter } from './postCapacityAssignmentRoute';
import { post } from './service';
import { FilterType, Type } from './types';

export const postIdleLocation = async ({ postedBrokers, site, filtersParam, sortingParam, paginationParam, deliveryState, pickupState, deliveryDateValueTo, pickupDateValueTo, deliveryDateValueFrom, pickupDateValueFrom, id, coordinates, equipments }: any): Promise<localIdleRouteResponseContentType | any> => {

	const filters = {};
	const sortingData = {};
	const pickupDateFrom = formatDateForFilter(pickupDateValueFrom);
	const pickupDateTo = formatDateForFilter(pickupDateValueTo);
	const deliveryDateFrom = formatDateForFilter(deliveryDateValueFrom);
	const deliveryDateTo = formatDateForFilter(deliveryDateValueTo);

	if (isSomeContainInObject(sortingParam)) {
		Object.assign(sortingData, { "colId": sortingParam.columnName, "sort": sortingParam.direction });
	}

	// brokers
	if (postedBrokers?.length > 0) {
		Object.assign(filters, { brokerId: { "filterType": FilterType.SET, "values": postedBrokers } });
	}

	// delivery date
	if (deliveryDateFrom && !deliveryDateTo) {
		Object.assign(filters, { deliveryDate: { "filterType": FilterType.DATE, "type": Type.GREATER_THAN, "dateFrom": deliveryDateFrom, "dateTo": deliveryDateTo } });
	}
	if (deliveryDateTo && !deliveryDateFrom) {
		Object.assign(filters, { deliveryDate: { "filterType": FilterType.DATE, "type": Type.LESS_THAN, "dateFrom": deliveryDateTo } });
	}
	if (deliveryDateTo && deliveryDateFrom) {
		Object.assign(filters, { deliveryDate: { "filterType": FilterType.DATE, "type": Type.RANGE, "dateFrom": deliveryDateFrom, "dateTo": deliveryDateTo } });
	}

	// pickup date
	if (pickupDateFrom && !pickupDateTo) {
		Object.assign(filters, { pickupDate: { "filterType": FilterType.DATE, "type": Type.GREATER_THAN, "dateFrom": pickupDateFrom, "dateTo": pickupDateTo } });
	}
	if (pickupDateTo && !pickupDateFrom) {
		Object.assign(filters, { pickupDate: { "filterType": FilterType.DATE, "type": Type.LESS_THAN, "dateFrom": pickupDateTo } });
	}
	if (pickupDateTo && pickupDateFrom) {
		Object.assign(filters, { pickupDate: { "filterType": FilterType.DATE, "type": Type.RANGE, "dateFrom": pickupDateFrom, "dateTo": pickupDateTo } });
	}

	// delivery states
	if (deliveryState) {
		Object.assign(filters, { "deliveryState": { "filterType": FilterType.TEXT, "type": Type.CONTAINS, "filter": deliveryState } });
	}
	if (pickupState) {
		Object.assign(filters, { "pickupState": { "filterType": FilterType.TEXT, "type": Type.CONTAINS, "filter": pickupState } });
	}

	// delivery location - city and state
	if (filtersParam?.deliveryLocation?.values.length > 0) {
		Object.assign(filters, { deliveryLocation: { "filterType": FilterType.TEXT, "filter": filtersParam.deliveryLocation.values[0], type: Type.CONTAINS } });
	}

	// pickuk location - city and state
	if (filtersParam?.pickupLocation?.values.length > 0) {
		Object.assign(filters, { pickupLocation: { "filterType": FilterType.TEXT, "filter": filtersParam.pickupLocation.values[0], type: Type.CONTAINS } });
	}

	// delivery location
	if (filtersParam?.deliveryLocation?.values.length > 0) {
		Object.assign(filters, { deliveryLocation: { "filterType": FilterType.TEXT, "filter": filtersParam.deliveryLocation.values[0], type: Type.CONTAINS } });
	}

	// pickukp location
	if (filtersParam?.pickupLocation?.values.length > 0) {
		Object.assign(filters, { pickupLocation: { "filterType": FilterType.TEXT, "filter": filtersParam.pickupLocation.values[0], type: Type.CONTAINS } });
	}

	// rate
	if (filtersParam?.fromRate?.values.length > 0 && filtersParam?.toRate.values.length === 0) {
		Object.assign(filters, { rate: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam.fromRate.values[0]), type: Type.GREATER_THAN } });
	}
	if (filtersParam?.fromRate?.values.length > 0 && filtersParam?.toRate.values.length > 0) {
		Object.assign(filters, { rate: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam.fromRate.values[0]), "filterTo": Number(filtersParam.toRate.values[1]), type: Type.RANGE } });
	}
	if (filtersParam?.fromRate?.values.length === 0 && filtersParam?.toRate.values.length > 0) {
		Object.assign(filters, { rate: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam.toRate.values[1]), type: Type.LESS_THAN } });
	}

	// broker
	if (filtersParam?.broker?.values.length > 0) {

		const filtersParams = filtersParam?.broker.values;
		const resultTwo = filtersParams.map((itemB: any) => {
			return mockBrokers
				.filter((itemA: any) => itemA.name === itemB)
				.reduce((_: any, item: any) => ({ ...item }))
		});
		const values = resultTwo.map((i: any) => i.id);
		Object.assign(filters, { brokerId: { "filterType": FilterType.SET, "values": values } });
	}

	// equipment type
	if (filtersParam?.equipmentType?.values.length > 0) {

		const filtersParams = filtersParam?.equipmentType.values;
		const resultTwo = filtersParams.map((itemB: any) => {
			return [...equipments]
				.filter((itemA: any) => itemA.brokerEquipment === itemB)
				.reduce((_: any, item: any) => ({ ...item }))
		});
		const values = resultTwo.map((i: any) => i.brokerEquipment);
		Object.assign(filters, { equipmentType: { "filterType": FilterType.SET, "values": values } });
	}

	try {
		if (site) {
			const response = await post<any>(`lane/site/${site}`,
				{
					"page": paginationParam?.currentPageIndex ?? 0,
					"size": paginationParam?.rowsPerPage ?? 20,
					"filter": {
						...filters,
					},
					"coordinates": coordinates,
					...(isSomeContainInObject(sortingParam) && { 'sort': [sortingData] })
				}
			);
			return response.data || undefined;
		}

	} catch (e) {
		throw e;
	}
};