import React, {FC, useContext, useState} from 'react'
import { green, grey, red } from '@mui/material/colors';
import { Box, IconButton } from '@mui/material';

import { ApproveIcon, DeclineIcon } from '../../../assets/icons';
import { Status } from './types';
import { useModalState } from '../../../hooks/useModalState';
import { DeclineModal, ApproaveModal } from '../../Modal';
import { ModalWrapper } from '../../Modal/ModalWrapper';
import {fetchLaneAvailability} from "../../../service/fetchLaneDetail";
import {showErrorNotyf} from "../../DHLTable/general.utils";
import NotyfContext from "../../DHLTable/NotyfContext";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { BidType } from '../../LaneScreenDetail/MatchesRoutes/BidHistory/types';

export const disabledButtonStyle = {
	background: 'none',
	pointerEvents: "none",
	pointer: 'none'
}

export const LaneScreenAction: FC<{ id: number, actionStatus: any, laneId: number, biddingEnabled: boolean }> = ({ id, actionStatus, laneId, biddingEnabled }) => {
	const { isModalOpen: isModalOpenApproave, closeModal: closeModalApproave, openModal: openModalApproave } = useModalState();
	const { isModalOpen: isModalOpenDecline, closeModal: closeModalDecline, openModal: openModalDecline } = useModalState();
	const activeIcons = actionStatus === Status.MATCH_FOUND;

	const notyf = useContext(NotyfContext);
	const [loading, setLoading] = useState(false);
	const [laneStatus, setLaneStatus] = useState(true);

	if(!biddingEnabled) {
		return (
			<Box display='flex'>
			</Box>
		)
	}

	return (
		<>
			<Box display='flex'>
				{activeIcons ? (
					<>
						<IconButton aria-label="approave" disabled={loading || !laneStatus} onClick={async () => {
							setLoading(true);
							const response = await fetchLaneAvailability(laneId);
							setLoading(false);
							if (response) {
								openModalApproave();
							} else {
								setLaneStatus(false);
								showErrorNotyf(notyf, "Lane is not available", "Status");
							}
						}}>
							{loading ? <AutorenewIcon /> : <ApproveIcon color={laneStatus ? green[500] : grey[500]} />}
						</IconButton>
						<IconButton aria-label="decline" onClick={openModalDecline}>
							<DeclineIcon color={red[700]} />
						</IconButton>
					</>
				) : (
					<>
						<IconButton aria-label="approave" sx={disabledButtonStyle}>
							<ApproveIcon color={grey[500]} />
						</IconButton>
						<IconButton aria-label="decline" sx={disabledButtonStyle}>
							<DeclineIcon color={grey[500]} />
						</IconButton>
					</>
				)}
			</Box>
			<ModalWrapper isOpen={isModalOpenApproave} closeModal={closeModalApproave} >
				<ApproaveModal id={id} closeModalApproave={closeModalApproave} bidType={BidType.LANE} />
			</ModalWrapper>
			<ModalWrapper isOpen={isModalOpenDecline} closeModal={closeModalDecline} >
				<DeclineModal id={id} closeModalDecline={closeModalDecline} />
			</ModalWrapper>
		</>
	)
}
