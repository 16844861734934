import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Options } from './Options';
import { UserName } from './UserName';

export const UserInfo: FC = () => (
	<Box display='flex' alignItems='center'>
		<Options />
		<UserName />
	</Box>
);
