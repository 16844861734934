/**
 * If value is an array of string wiht at least one value, return it.
 * Otherwise, return undefined.
 */
export interface SortingState<RowType> {
	columnName: keyof RowType;
	direction: SortingDirection;
}

export type SortingDirection = 'asc' | 'desc';
export type SortingDirectionIncludingNone = 'asc' | 'desc' | 'none';

export interface PaginationState {
	currentPageIndex: number;
	rowsPerPage: number;
}
export type ColumnType = 'string' | 'number' | 'boolean' | 'date';
export interface FilterStateForColumn {
	values: string[];
	match?: FilterMatch; // TODO not yet used
	columnType?: ColumnType; // TODO remove? is it needed?
}

export type FilterMatch = 'equals'; // TODO add more match types

export interface TableBasicUrlParamsDto {
	page?: number;
	size?: number;
	sort?: any
}

export type TableUrlFilters<RowType> = Partial<Record<keyof RowType, string[]>>;
export type TableUrlParamsDto<RowType> = TableBasicUrlParamsDto & TableUrlFilters<RowType>;
export type FiltersState<RowType> = Record<keyof RowType, FilterStateForColumn>;


export const nonEmpty = (value: string[] | undefined): string[] | undefined => {
	return value && value.length > 0 ? value : undefined;
};

export const isSomeContainInObject = (obj: Record<any, any>) => {
	return obj ? Object.keys(obj).length > 0 : false;
}

export const setPaginationAndSorting = <RowType extends unknown>(
	params: TableUrlParamsDto<RowType>,
	sortingParam: SortingState<RowType> | null,
	paginationParam: PaginationState,
): void => {
	// pagination
	params.page = paginationParam.currentPageIndex;
	params.size = paginationParam.rowsPerPage;

	// sorting
	if (sortingParam) {
		const columnName = String(sortingParam.columnName);
		const direction = String(sortingParam.direction);
		const sortableColumn = `${columnName},${direction}`;
		params.sort = sortableColumn;
	}
};

export const buildUrlParams = <RowType extends unknown>(
	filtersParam: FiltersState<RowType>,
	sortingParam: SortingState<RowType> | null,
	paginationParam: PaginationState,
	fieldNameList: (keyof RowType)[],
	site?: number,
): TableUrlParamsDto<RowType> => {
	let params: TableUrlParamsDto<RowType> = {};
	const filterParams: TableUrlFilters<RowType> = {};

	for (const field of fieldNameList) {
		filterParams[field] = nonEmpty(filtersParam[field]?.values);
	}

	const newValues = { siteId: site };
	params = { ...params, ...newValues };

	// copy filter params to the main params object
	Object.assign(params, filterParams);

	// add pagination and sorting
	setPaginationAndSorting(params, sortingParam, paginationParam);

	return params;
};
