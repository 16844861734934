import React, { FC } from 'react'
import { Box } from '@mui/material';

import { DetailTableItem } from './DetailTableItem';

export const DetailTable: FC<{ detail: any, icon?: any }> = ({ detail, icon }) => {

	return (
		<Box display='flex'>
			{icon}
			<Box ml={icon ? "10px" : undefined}>
				{detail.filter((item:any)=>{
					const visible = Object.keys(item).find(k=>k == 'visible');
					return !!visible ? item.visible : true;
				}).map((item: any) => {
					return (
						<DetailTableItem key={item.id} item={item} />
					)
				})}
			</Box>
		</Box>
	)
}


