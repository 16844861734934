import React, { FC } from 'react';
import { List } from '@mui/material';

import { SidebarListProps } from './types';
import { CustomListItem } from './CustomListItem';


const SidebarList: FC<SidebarListProps> = ({ items, open }) => {
	return (
		<List>
			{items.map((menu) => <CustomListItem key={menu.id} menu={menu} open={open} />)}
		</List >
	);
};

export default SidebarList;