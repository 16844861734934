import React, { FC, useContext } from 'react'
import { Box } from '@mui/material'
import { useForm, FormProvider } from "react-hook-form";

import { SubmitButton } from './SubmitButton';
import { ModalTitle } from './ModalTitle';
import { InputNameApproave } from './types';
import { FormItemTextField } from '../Forms/FormItemTextField';
import { post } from '../../service/service';
import NotyfContext from '../DHLTable/NotyfContext';
import { showErrorNotyf } from '../DHLTable/general.utils';
import { useOpenLanes } from '../LanesScreen/OpenLanesContext';
import { useLocalEquipmentContext } from '../LocalEquipmentDashboard/LocalEquipmentContext';
import { BidType } from '../LaneScreenDetail/MatchesRoutes/BidHistory/types';
import { useBidRealod } from '../LaneScreenDetail/MatchesRoutes/BidHistory/BidContext';

export const saveApproave = async ({ details, id, requestedRate, bidType }: { details: any, id: number, requestedRate: number, bidType: BidType }): Promise<any | undefined> => {
	try {
		const url = BidType.MATCH === bidType ? "laneRoute" : "lane";

		const response = await post<any>(`${url}/submit/${id}`, {
			"details": details,
			"requestedRate": Number(requestedRate)
		});
		return response.data || undefined;
	} catch (e) {
		throw e;
	}
};

export const ApproaveModal: FC<{ id: number, closeModalApproave: any, bidType: BidType }> = ({ id, closeModalApproave, bidType }) => {
	const methods = useForm();
	const notyf = useContext(NotyfContext);
	const { realoadNumber, setRealoadNumber } = useOpenLanes();
	const { realoadNumber: reloadNumberEquipment, setRealoadNumber: setReloadNumberEquipment } = useLocalEquipmentContext();
	const { realoadNumber: reloadBidNumber, setRealoadNumber: setReloadBidNumber } = useBidRealod();


	const onSubmit = async (data: any) => {
		try {
			await saveApproave({ details: data.details, id: id, requestedRate: data.requestedRate, bidType });
			await setRealoadNumber(realoadNumber + 1)
			await setReloadNumberEquipment(reloadNumberEquipment + 1)
			await setReloadBidNumber(reloadBidNumber + 1);
			await notyf.success('The data has been submitted');
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to load data')
		}
	}

	return (
		<>
			<ModalTitle title={`Submit Bid #${id}`} />
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<FormItemTextField label='Requested rate' inputName={InputNameApproave.REQUESTED_RATE} type='number' />
					<FormItemTextField label='Additional Details' inputName={InputNameApproave.ADDITIONAL_DETAILS} type='text' isMultiline />
					<Box display='flex' justifyContent="center" mt='30px'>
						<SubmitButton closeModalApproave={closeModalApproave} disabled={methods.formState.isSubmitting} />
					</Box>
				</form>
			</FormProvider>
		</>
	)
}