import { createContext, useContext, useState } from "react";

export interface OpenLaneDetailContextProps {
	realoadNumber: number;
	setRealoadNumber: (realoadNumber: number) => void;
}

const noop = () => {
	console.warn('setRealodNumber hook used outside of OpenLaneDetailContextContainer');
};

const noContextObj = {
	realoadNumber: 0,
	setRealoadNumber: noop
};

export const useOpenLaneDetail = (): OpenLaneDetailContextProps => {
	return useContext(OpenLaneDetailContext) || noContextObj;
};

const OpenLaneDetailContext = createContext<OpenLaneDetailContextProps>(noContextObj);

export const OpenLaneDetailContextContainer: React.FC = ({ children }) => {
	const [realoadNumber, setRealoadNumber] = useState<number>(0);

	return (
		<OpenLaneDetailContext.Provider value={{ realoadNumber, setRealoadNumber }}>
			{children}
		</OpenLaneDetailContext.Provider>
	);
};