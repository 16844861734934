import React, { FC } from 'react'
import { TextField } from '@mui/material'
import { useFormContext } from "react-hook-form";

import { FormItemWrapper } from './FormItemWrapper';
import { InputParametersName } from '../PursuitSupport/InputParameters/types';
import { InputNameApproave, InputNameDecline } from '../Modal/types';
import { InputBid } from '../LaneScreenDetail/MatchesRoutes/BidHistory/types';

interface CustomInputProps {
	label: string;
	inputName: InputNameApproave | InputNameDecline | InputParametersName | InputBid;
	type: string;
	isMultiline?: boolean;
	isHidden?: boolean;
	isRequired?: boolean
}

export const FormItemTextField: FC<CustomInputProps> = ({ label, inputName, type, isMultiline = false, isHidden = false, isRequired = false }) => {
	const { register } = useFormContext();
	return (
		<FormItemWrapper label={label}>
			<TextField
				id={inputName}
				variant="outlined"
				fullWidth
				{...type === "number" && { inputProps: { min: 0, pattern: '[0-9]*', inputMode: 'numeric' } }}
				type={type}
				multiline={isMultiline}
				hidden={isHidden}
				{...isMultiline && { rows: 4 }}
				{...register(inputName, { required: isRequired })}
			/>
		</FormItemWrapper>
	)
}
