
import { ListItemIcon } from '@mui/material'
import React, { ReactElement, ReactNode } from 'react'

export const ListItemIconWrapper: React.FC<{ ref?: any, icon: ReactElement | string | ReactNode, open: boolean }> = React.forwardRef(({ icon, open, ...props }, ref) => {
	return (
		<ListItemIcon
			ref={ref}
			{...props}
			sx={{
				minWidth: 0,
				mr: open ? 3 : 'auto',
				justifyContent: 'center',
			}}
		>{icon}</ListItemIcon>
	)
})