import { Box, Button } from "@mui/material";
import React, { FC, useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { post } from "../../../../service/service";
import NotyfContext from "../../../DHLTable/NotyfContext";
import { showErrorNotyf } from "../../../DHLTable/general.utils";
import { FormItemTextField } from "../../../Forms/FormItemTextField";
import { BidType, InputBid } from "./types";
import { Center } from "../../../layout/Center";
import { useBidRealod } from "./BidContext";

export const AddBidHistory: FC<{ id: number; closeModal: any, bidType: BidType }> = ({
  id,
  closeModal,
  bidType
}) => {
  const methods = useForm();
  const notyf = useContext(NotyfContext);
  const { realoadNumber, setRealoadNumber } = useBidRealod();

  const onSubmit = async (data: any) => {
    if (!data) {
      return null;
    }

    try {
      const url = BidType.MATCH === bidType ? "laneRoute/manual" : "lane/manual";
      const response = await post<any>(`${url}/${id}`, data);
      await notyf.success("The Bid has been saved");
      await setRealoadNumber(realoadNumber + 1);
      return response.data || undefined;
    } catch (e) {
      await setRealoadNumber(realoadNumber + 1);
      showErrorNotyf(notyf, e, "Failed to save bid");
      throw e;
    }
  };

  useEffect(() => {
    return () => closeModal();
  }, [methods.formState.isSubmitSuccessful, realoadNumber]);

  const isInputsFill = methods.formState.isValid;
  const isbuttonDisabled = methods.formState.isSubmitting || !isInputsFill;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mt={"40px"}>
            <Box>
              <FormItemTextField
                label="Rate"
                inputName={InputBid.RATE}
                type="number"
                isRequired
              />
              <FormItemTextField
                label="Message"
                inputName={InputBid.MESSAGE}
                type="text"
                isMultiline
                isRequired
              />
            </Box>
            <Center mt="40px">
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ width: "fit-content", mb: "10px" }}
                disabled={isbuttonDisabled}
              >
                Add bid
              </Button>
            </Center>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};
