import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RouteTwoToneIcon from '@mui/icons-material/RouteTwoTone';
import ViewListTwoToneIcon from '@mui/icons-material/ViewListTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';

import { ROUTES } from '../../../routes';

export const menuItems = [
	{
		id: 1,
		title: 'Lanes',
		icon: <RouteTwoToneIcon />,
		path: `/`,
	},
	{
		id: 2,
		title: 'Capacity Assignment',
		icon: <ViewListTwoToneIcon color='inherit' />,
		path: `/${ROUTES.capacityAssignmentScreen}`,
	},
	{
		id: 3,
		title: 'Pursuit Support',
		icon: <ReceiptLongTwoToneIcon />,
		path: `/${ROUTES.pursuitSupport}`,
	},
	{
		id: 4,
		title: 'Local Idle Board',
		icon: <PinDropTwoToneIcon />,
		path: `/${ROUTES.localIdleDashboard}`,
	},
	{
		id: 5,
		title: 'Idle Equipment Dashboard',
		icon: <LocalShippingTwoToneIcon />,
		path: `/${ROUTES.localEquipmentDashboard}`,
	},
];


export const bottomItems = [
	{
		id: 1,
		isExternal: true,
		title: 'Configuration Data Management',
		icon: <ExitToAppIcon />,
		path: process.env.CDM_URL,
	},
	{
		id: 2,
		isExternal: true,
		title: 'DSC User Management',
		icon: <ExitToAppIcon />,
		path: process.env.UM_URL,
	},
];