import React from "react";

import { DhlTable } from "../../DHLTable";
import { innerTableColumns } from "./innerTableColumns";
import { formatAsLocalDateTime, getLocation } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { Center } from "../../layout/Center";
import { Box } from "@mui/material";
import { LaneRoutesType } from "../LocalEquipmentTable/types";

export const InnerTable: React.FC<{laneRoutes: LaneRoutesType[]}> = ({ laneRoutes }) => {
  const navigate = useNavigate();

  const innerTableData = laneRoutes.map((laneRoute) => {
    const { bhMiles, gm, hos, rank, status, id } = laneRoute;
    const { loadId, pickupLocation, broker, pickupDate, id: laneId } = laneRoute.lane;

    const dateFormat = new Date(pickupDate);
    const date = pickupDate ? formatAsLocalDateTime(dateFormat) : "";

    return {
      id: id,
      bhPickup: getLocation({
        city: pickupLocation.city,
        state: pickupLocation.state,
      }),
      bhMiles: bhMiles,
      bhPickupDate: date,
      broker: broker,
      loadId: loadId,
      gm: gm,
      hos: hos,
      rank: rank,
      laneId: laneId,
      status: status
    };
  });

  if (laneRoutes.length === 0) {
    return (
      <Center mt="20px">
        <Box minWidth={"800px"} textAlign={"center"}>
          No routes available
        </Box>
      </Center>
    );
  }

  return (
    <Box minWidth={"800px"} textAlign={"center"}>
      <DhlTable
        title=""
        bottomPosition="10px"
        className="innerTable"
        data={innerTableData}
        totalRowCount={20}
        columns={innerTableColumns(navigate)}
        filterOptions={{}}
        isLoading={false}
        options={{
          search: false,
          download: false,
          pagination: false,
          sort: false,
          print: false,
          filter: false,
          viewColumns: false,
        }}
      />
    </Box>
  );
};
