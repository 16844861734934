import { createContext, useContext, useState } from "react";
import { SitesType } from "../../service/types";

interface SiteContextProps {
	sites: SitesType[];
	setSites: (sites: SitesType[]) => void;
}

const noop = () => {
	console.warn('setSites hook used outside of SitesContext');
};

const noContextObj = {
	sites: [],
	setSites: noop
};

export const useSites = (): SiteContextProps => {
	return useContext(SitesReloadContext) || noContextObj;
};

const SitesReloadContext = createContext<SiteContextProps>(noContextObj);

export const SitesContext: React.FC = ({ children }) => {
	const [sites, setSites] = useState<SitesType[]>([]);

	return (
		<SitesReloadContext.Provider value={{ sites, setSites }}>
			{children}
		</SitesReloadContext.Provider>
	);
};