import React, { useState, FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useIsAuthenticated } from '@azure/msal-react';

import { PageHeader } from '../PageHeader';
import { Sidebar } from '../Sidebar/Sidebar';
import { fetchUser } from '../../../service/fetchUser';
import { useSites } from '../SitesContext';

interface WrapperType {
	isSidebarVisible: boolean | null,
	setOpen: (open: boolean) => void,
	open: boolean
}


const Wrapper: FC<WrapperType> = ({ isSidebarVisible, setOpen, open, children }) => {
	return (
		<Box sx={{ display: 'flex' }}>
			<PageHeader isSidebarVisible={isSidebarVisible} setOpen={setOpen} open={open} />
			{children}
		</Box>
	)
}

const PageWrapper: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const isAuthenticated = useIsAuthenticated();
	const [open, setOpen] = useState(false);
	const [isSidebarVisible, setIsSidebarVibisle] = useState<boolean | null>(null);
	const { setSites } = useSites();

	useEffect(() => {
		const fetchData = async () => {
			const usersInfo = await fetchUser();
			const user = usersInfo.user;
			const admin = usersInfo.admin;

			const isAvailable = user || admin;
			setIsSidebarVibisle(isAvailable);
			setSites(usersInfo.sites);
		}

		fetchData()
			.catch(console.error);
	}, [isSidebarVisible])


	if (!isSidebarVisible) {
		return (
			<Wrapper isSidebarVisible={isSidebarVisible} setOpen={setOpen} open={open}>

			</Wrapper>
		)
	}

	return (
		<Wrapper isSidebarVisible={isSidebarVisible} setOpen={setOpen} open={open}>
			{isSidebarVisible ?
				<>
					<Sidebar setOpen={setOpen} open={open} isSidebarVisible={isSidebarVisible} />
					{isAuthenticated ? children :
						<Box display='flex' alignItems='center' justifyContent='center' minHeight='100vh' width="100%">
							<Box display="grid" gap="20px">

							</Box>
						</Box>}
				</>
				: (
					<Box display='flex' alignItems='center' justifyContent='center' minHeight='100vh' width="100%" fontWeight='600'>
						You are not allowed to use this App
					</Box>
				)}
		</Wrapper>
	);
}

export default PageWrapper;