import { Box, Button, TextField } from '@mui/material'
import React, { FC, useContext, useEffect } from 'react'
import { useForm, FormProvider } from "react-hook-form";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { InputParametersName } from './types';
import { FormItemCheckbox } from '../../Forms/FormItemCheckbox';
import { ContentSubtitle } from '../../Dashboard/DashboardContent/ContentSubtitle';
import { FormInputFile } from '../../Forms/FormInputFile';
import { DownloadFile } from './DownloadFile';
import { FormItemTextField } from '../../Forms/FormItemTextField';
import { FormItemWrapper } from '../../Forms/FormItemWrapper';
import { formatDateForFilter } from '../../../service/postCapacityAssignmentRoute';
import { post } from '../../../service/service';
import NotyfContext from '../../DHLTable/NotyfContext';
import { showErrorNotyf } from '../../DHLTable/general.utils';
import { useRfpRealod } from '../RfpTable/RfpContext';
import { getLocalDateFormat } from '../../../helpers';

export const InputParameters: FC = () => {
	const methods = useForm();
	const notyf = useContext(NotyfContext);
	const { realoadNumber, setRealoadNumber } = useRfpRealod();

	const [valueFrom, setValueFrom] = React.useState<any>(null);
	const [valueTo, setValueTo] = React.useState<any>(null);

	const handleChangeFrom = (newValue: any) => {
		setValueFrom(newValue);
	};

	const handleChangeTo = (newValue: any) => {
		setValueTo(newValue);
	};

	useEffect(() => {
		setValueFrom(null)
		setValueTo(null)

	}, [realoadNumber])

	const onSubmit = async (data: any) => {
		if (!data) {
			return null
		}

		const availableFromDate = valueFrom?._d && formatDateForFilter(valueFrom._d);
		const availableToDate = valueTo?._d && formatDateForFilter(valueTo._d);
		const maxDHOrigin = data && Number(data.maxDHOrigin);
		const brokerLanes = data && data.brokerLanes;
		const contractedLanes = data && data.contractedLanes;
		const importFile = data.importFile[0];

		const formData = new FormData();
		formData.append('availableFromDate', availableFromDate);
		formData.append('availableToDate', availableToDate);
		formData.append('equipmentType', data.equipmentType);
		formData.append('maxDHOrigin', maxDHOrigin);
		formData.append('brokerLanes', brokerLanes);
		formData.append('contractedLanes', contractedLanes);
		formData.append('importFile', importFile);

		try {
			const response = await post<any>(`rfp/import`,
				formData, { 'Content-Type': 'multipart/form-data' }
			);
			await setRealoadNumber(realoadNumber + 1)
			await notyf.success('The parameters saved');
			await methods.reset();
			return response.data || undefined;
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to save parameters')
			setValueFrom(null)
			setValueTo(null)
			methods.reset();
			throw e;
		}
	};

	const watchedFileInput = methods.watch('importFile') ?? {};
	const isInputsEmpty = Boolean(valueFrom) && Boolean(valueTo) && watchedFileInput[0]?.name;
	const isbuttonDisabled = methods.formState.isSubmitting || !isInputsEmpty;

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DownloadFile />
				<ContentSubtitle>Input parameters</ContentSubtitle>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Box display='grid' alignContent='space-between' gridTemplateColumns='1fr 1fr' width='70%' gap='40px' mt='30px'>
							<Box>
								<FormItemWrapper label="Available date from" isBold>
									<DesktopDatePicker
										inputFormat={getLocalDateFormat()}
										value={valueFrom}
										onChange={handleChangeFrom}
										renderInput={(params: any) => (
											<TextField
												{...params}
												name={InputParametersName.DATE_FROM}
												inputProps={{ ...params.inputProps }}
											/>
										)}
									/>

								</FormItemWrapper>
								<FormItemWrapper label="Available date to" isBold>
									<DesktopDatePicker
										inputFormat={getLocalDateFormat()}
										value={valueTo}
										onChange={handleChangeTo}
										renderInput={(params: any) => (
											<TextField
												{...params}
												name={InputParametersName.DATE_TO}
												inputProps={{ ...params.inputProps }}
											/>
										)}
									/>

								</FormItemWrapper>
								<FormItemTextField label='Equipment type' inputName={InputParametersName.EQUIPMENT_TYPE} type='text' />
								<FormItemTextField label='Max DH origin' inputName={InputParametersName.MAX_DH_ORIGIN} type='number' />
								<FormItemTextField label='Max DH destination' inputName={InputParametersName.MAX_DH_DESTINATION} type='number' />
							</Box>
							<Box display='grid' alignContent='space-between'>
								<Box>
									<FormItemCheckbox label='3rd party broker lanes' inputName={InputParametersName.BROKER_LANES} />
									<FormItemCheckbox label='DHL contracted lanes' inputName={InputParametersName.CONTRACTED_LANES} />
									<FormInputFile label='Upload routes file' inputName={InputParametersName.UPLOAD_ROUTE_FILE} buttonText="Choose file" isRequired />
								</Box>
								<Button type='submit' variant="contained" color='success' sx={{ width: 'fit-content', mb: '10px' }} disabled={isbuttonDisabled}>
									Start Matching
								</Button>
							</Box>
						</Box>
					</form >
				</FormProvider >
			</LocalizationProvider>
		</>
	)
}