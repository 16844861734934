import React, { useState, FC } from 'react';
import { useMsal } from '@azure/msal-react';
import { AccountCircle } from '@mui/icons-material';
import { useIsAuthenticated } from '@azure/msal-react';
import Button from '@mui/material/Button';
import grey from '@mui/material/colors/grey';

import { loginRequest } from '../../../../authConfig';
import { LoginLogoutButtons } from './LoginLogoutButtons';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

export const LoginLogoutPart: FC<{ isSidebar?: boolean }> = ({ isSidebar = false }) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = (logoutType: string) => {
		setAnchorEl(null);
		if (logoutType === 'popup') {
			instance.logoutPopup({
				mainWindowRedirectUri: '/',
			});
		} else if (logoutType === 'redirect') {
			instance.logoutRedirect();
		}
	};

	const handleLogin = (loginType: string): void => {
		if (loginType === 'popup') {
			instance.loginPopup(loginRequest);
		} else if (loginType === 'redirect') {
			instance.loginRedirect(loginRequest);
		}
	};

	const idPop = open ? 'login-logout-popover' : undefined;

	return (
		<>
			<Button
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{ color: grey[900], minWidth: "auto" }}
			>
				<AccountCircle /> {isSidebar && <Box sx={{ ml: '10px', textTransform: 'capitalize' }}>{isAuthenticated ? "Logout" : "Login"}</Box>}
			</Button>
			<Popover
				id={idPop}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<LoginLogoutButtons handleLogout={handleLogout} handleLogin={handleLogin} />
			</Popover>
		</>
	);


};