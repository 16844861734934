import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import theme from './theme';
import './index.css';
import App from './App';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.enableAccountStorageEvents();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline enableColorScheme />
    <Router>
      <MsalProvider instance={msalInstance}>
        <App pca={msalInstance} />
      </MsalProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);