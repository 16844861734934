import moment from "moment";

export const getLocation = ({ city, state }: { city: any | null, state: any | null }): string | null => {
	if (city && state) {
		const locationCity = city.charAt(0).toUpperCase() + city.slice(1);
		return `${locationCity}, ${state}`
	}
	if (city && !state) {
		const locationCity = city.charAt(0).toUpperCase() + city.slice(1);
		return `${locationCity}`
	}
	if (!city && state) {
		return `${state}`
	}
	return null
};

moment.locale(navigator.language);
export const formatAsLocalDate = (date: moment.MomentInput) => moment(date).format('L');
export const formatAsLocalDateTime = (date: moment.MomentInput) => moment(date).format('L LT');
export const getLocalDateFormat = () => moment.localeData().longDateFormat('L');
export const getLocaleISOStringDate = (date: moment.MomentInput) => moment(date).toISOString(true);
export const getStartOfDate = (value: moment.MomentInput) => moment(value).local(true).startOf('day').toDate();
export const getEndOfDay = (value: moment.MomentInput) => moment(value).local(true).endOf('day').toDate();
