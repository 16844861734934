
import { Paper } from '@mui/material'
import React, { FC, ReactNode } from 'react'

interface Props {
	children: ReactNode
}

export const MainPaper: FC<Props> = ({ children }) => {
	return (
		<Paper elevation={2} sx={{
			p: 4,
			mb: 4,
		}}>
			{children}
		</Paper>
	)
}