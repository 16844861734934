import { createContext, useContext, useState } from "react";

interface BidContextProps {
	realoadNumber: number;
	setRealoadNumber: (realoadNumber: number) => void;
}

const noop = () => {
	console.warn('setRealodNumber hook used outside of BidContext');
};

const noContextObj = {
	realoadNumber: 0,
	setRealoadNumber: noop
};

export const useBidRealod = (): BidContextProps => {
	return useContext(BidReloadContext) || noContextObj;
};

const BidReloadContext = createContext<BidContextProps>(noContextObj);

export const BidContext: React.FC = ({ children }) => {
	const [realoadNumber, setRealoadNumber] = useState<number>(0);

	return (
		<BidReloadContext.Provider value={{ realoadNumber, setRealoadNumber }}>
			{children}
		</BidReloadContext.Provider>
	);
};