import React, { FC } from "react";
import { CustomSelectWithPlaceholder } from "../../Forms/CustomSelectWithPlaceholder";
import { CircularProgress, MenuItem, Paper } from "@mui/material";
import { SitesType } from "../../../service/types";
import { Center } from "../../layout/Center";

interface SelectSiteProps {
	sites: SitesType[],
	site: number | null,
	isFirstLoading: boolean;
	setSite: (site: number | null) => void,
}

export const SelectSite: FC<SelectSiteProps> = ({ sites, site, isFirstLoading, setSite }) => {

	if (isFirstLoading) {
		return (
			<Center>
				<CircularProgress size={25} />
			</Center>
		)
	}

	return (
		<Paper sx={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
			{sites && (
				<CustomSelectWithPlaceholder
					selectedValue={site ?? ""}
					onChange={(e: React.ChangeEvent<any>) => setSite(e.target.value)}
					handleClearClick={() => setSite(null)}
					placeholder="Select site">
					{sites.map((site: SitesType) => (
						<MenuItem value={site.id} key={site.id}>
							{site.code}
						</MenuItem>
					))}
				</CustomSelectWithPlaceholder>
			)}
		</Paper>
	)
}