import { localIdleRouteResponseContentType, idleEquipmentRouteResponseType, localIdleRouteType, CoordinatesType } from "./types";
import { formatAsLocalDateTime, getLocation } from "../../../helpers";
import Box from "@mui/material/Box";
import React from "react";


export const localIdleToRow = (route: localIdleRouteResponseContentType): localIdleRouteType => {

	const {
    brokerName,
    brokerId,
    equipmentType,
    id,
    loadId,
    deliveryDate,
    distance,
    rate,
    pickupDate,
    pickupCity,
    pickupState,
    pickupLatitude,
    pickupLongitude,
    deliveryCity,
    deliveryState,
    deliveryLatitude,
    deliveryLongitude,
  } = route;
  const dateFormat = new Date(deliveryDate);
  const delivery = deliveryDate ? formatAsLocalDateTime(dateFormat) : "";
  const dateFormatPickup = new Date(pickupDate);
  const pickup = pickupDate ? formatAsLocalDateTime(dateFormatPickup) : "";

  const rateValue = rate && rate.toFixed(2);

  const rowData = {
    id: id,
    broker: { id: brokerId, name: brokerName },
    pickup: getLocation({ city: pickupCity, state: pickupState }),
    pickupDateFrom: pickup,
    pickupDateTo: pickup,
    delivery: getLocation({ city: deliveryCity, state: deliveryState }),
    deliveryDateFrom: delivery,
    deliveryDateTo: delivery,
    equipmentType: equipmentType,
    rate: Number(rateValue),
    fromRate: Number(rateValue),
    toRate: Number(rateValue),
    deliveryState: getLocation({ city: deliveryCity, state: deliveryState }),
    pickupState: getLocation({ city: pickupCity, state: pickupState }),
    miles: distance,
    loadId: loadId,
    deliveryLocation: getLocation({ city: deliveryCity, state: deliveryState }),
    pickupLocation: getLocation({ city: pickupCity, state: pickupState }),
    deliveryLat: deliveryLatitude,
    deliveryLng: deliveryLongitude,
    pickupLat: pickupLatitude,
    pickupLng: pickupLongitude,
  };

	return rowData
};

export const localIdleResponseToRows = (response: idleEquipmentRouteResponseType): localIdleRouteType[] => {
	return response.content.map((row) => localIdleToRow(row))
};


export const ExternalFilterWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<Box sx={{ pr: '10px', maxWidth: '160px' }}>
			{children}
		</Box>
	)
}

export const mockBrokers = [
	{ id: 498, name: 'Coyote' },
	{ id: 2, name: 'Random broker' }
]

export const localIdleCsvMapper = {
	broker: (value:any) => value.name,
}
