import { get } from "./service";

export const fetchLaneDetail = async (id: string): Promise<any> => {
	const response = await get<any>(`lane/${id}`);
	return response.data;
};

export const fetchLaneAvailability = async (id: number): Promise<any> => {
	const response = await get<any>(`lane/${id}/status`);
	return response.data;
};

export const fetchLocalIdleLaneDetail = async (id: string): Promise<any> => {
	const response = await get<any>(`lane/details/${id}`);
	return response.data;
};