import React, { FC, useEffect, useState } from 'react'
import { LocalIdleTable } from './LocalIdleTable'
import { SelectSite } from './LocalIdleTable/SelectSite';
import { Box, Button, Paper, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useSites } from '../layout/SitesContext';
import { LatestImportLogResponseType } from './LocalIdleTable/types';
import { fetchLatestImportLog } from '../../service/fetchImportLog';
import { formatAsLocalDateTime } from '../../helpers';


export const SelectedSite: FC<{ selectedSiteName: string, setSite: (site: number | null) => void}> = ({ selectedSiteName, setSite }) => {
	return (
		<Box display={'flex'} alignItems={'center'}>
			<Box sx={{ fontSize: "17px", fontWeight: 500, color: red[800], mr: '30px' }}>
				{selectedSiteName}
			</Box>
			<Button size="small" variant='outlined' color="inherit" sx={{ textTransform: 'capitalize' }} onClick={() => setSite(null)}>Change site</Button>
		</Box>
	)
}

export const LocalIdleDashboard: FC = () => {
	const { sites } = useSites();
	const [site, setSite] = useState<number | null>(null);
	const [isFirstLoading, setFirstIsLoading] = useState<boolean>(true);
	const [lastImportInfo, setLastImportInfo] = useState<LatestImportLogResponseType>();

	const getLocalDateTime = () => {
		return lastImportInfo?.finished ? `Last updated on: ${formatAsLocalDateTime(lastImportInfo?.finished!)}` : 'Last updated on: unknown';
	}

	useEffect(() => {
		setFirstIsLoading(true)
		const importLogData = async () => {
			const response = await fetchLatestImportLog('lane');
			setLastImportInfo(response);
		}

		importLogData()
			.catch(console.error);

		setFirstIsLoading(false);
	}, [])

	const selectedSiteData = sites.filter(i => i.id === site);
	const selectedSiteName = selectedSiteData[0]?.code;

	const onSiteSelect = (value: React.SetStateAction<number | null>) => {
		setSite(value);
	}

	return (
		<>
			<Paper elevation={2} sx={{
				p: 4,
				mb: 4,
				mt: '64px'
			}}>
				<Box sx={{ mb: 8, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
					<Typography sx={{ fontSize: "19px", fontWeight: 600}}>Local Idle Board</Typography>
					{lastImportInfo && 
						<Typography sx={{ fontSize: 11, fontWeight: '400' }}>
							{getLocalDateTime()}
						</Typography>
					}
				</Box>
				{selectedSiteName && <SelectedSite selectedSiteName={selectedSiteName} setSite={setSite} />} </Paper>
			{site ?
				<LocalIdleTable site={site} setSite={setSite} selectedSiteData={selectedSiteData} />
				:
				<SelectSite isFirstLoading={isFirstLoading} sites={sites} site={site} setSite={onSiteSelect} />
			}
		</>

	)
}
