import { Box, Checkbox, ListItemText, MenuItem, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import {laneCsvMapper, laneRouteResponseToRows} from './helperes';
import { FiltersState, PaginationState, SortingState } from '../DHLTable/action.utils';
import {generateDownloadFilename, showErrorNotyf, validateDate} from '../DHLTable/general.utils';
import { FilterOptionsForTable } from '../DHLTable/types';
import { tableColumns } from './LanesScreenTable/tableColums';
import { createDownloadHandler } from '../DHLTable/DhlTable.utils';
import { downloadColumnMapper } from '../DHLTable/helpers';
import { CustomMultiSelectWithPlaceholder } from '../Forms/CustomSelectWithPlaceholder';
import { postLaneRoute } from '../../service/postLaneRoute';
import { DhlTable } from '../DHLTable';
import NotyfContext from '../DHLTable/NotyfContext';
import { useOpenLanes } from './OpenLanesContext';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSites } from '../layout/SitesContext';
import SubscribeToolbar from './subscription/SubscribeToolbar';
import { useModalState } from '../../hooks/useModalState';
import { ModalWrapper } from '../Modal/ModalWrapper';
import { SubscriptionModal } from './subscription/SubscriptionModal';
import { fetchUser } from '../../service/fetchUser';
import { getEndOfDay, getLocalDateFormat, getStartOfDate } from '../../helpers';
import { fetchBrokers } from '../../service/getBrokers';
import { BrokersType } from './types';

export const LanesScreenMainTable: React.FC<any> = ({ rowActions, tableActions }) => {
	const { isModalOpen, closeModal, openModal } = useModalState();
	const [data, setData] = useState<any[]>([]);
	const [site, setSite] = useState<number | null>(null);
	const [brokers, setBrokers] = useState<any[] | null>([]);
	const [selectedBrokers, setSelectedBrokers] = useState<BrokersType[]>([]);
	const [userEmail, setUserEmail] = useState<string | null>(null);
	const [isUserSubscribed, setIsUserSubscribed] = useState<boolean | null>(null);
	const [totalRowCount, setTotalRowCount] = useState<number>(0);
	const [dateValueTo, setDateValueTo] = useState<Date | null>(null)
	const [dateValueFrom, setDateValueFrom] = useState<Date | null>(getStartOfDate(new Date()))
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedSites, setSelectedSites] = React.useState<any[]>([]);
	const [filterOptions, setFilterOptions] = useState<FilterOptionsForTable<any>>({});
	const notyf = useContext(NotyfContext);
	const { realoadNumber } = useOpenLanes();
	const { sites } = useSites();
	const [reload, setReload] = useState<boolean>(false);

	const handleFilterSite = (e: any) => {
		setSite(e.target.value)
	}
	const handleClearClick = () => {
		setSite(null);
	};

	const handleChangeTo = (value: any) => {
		if (value) {
			setDateValueTo(getEndOfDay(value));
		} else {
			setDateValueTo(value);
		}
	};

	const handleChangeFrom = (value: any) => {
		if (value) {
			setDateValueFrom(getStartOfDate(value));
		} else {
			setDateValueFrom(value)
		}
	};


	const postedSitets = sites?.filter(site => selectedSites.some(selectedSite => site.code === selectedSite)).map(site => site.code);
	const postedBrokers = brokers?.filter(broker => selectedBrokers.some(selectedBroker => broker.name === selectedBroker)).map(broker => broker.name);


	const loadDataAndFiltersOptions = async (
		filtersParam: FiltersState<any>,
		sortingParam: SortingState<any> | null,
		paginationParam: PaginationState,
	): Promise<void> => {
		if (!validateDate(dateValueFrom) || !validateDate(dateValueTo)) {
			return;
		}
		setIsLoading(true);
		let response;
		try {
			response = await postLaneRoute({ site: postedSitets, filtersParam, sortingParam, paginationParam, dateValueTo, dateValueFrom, 
				postedBrokers: postedBrokers
			 });
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to load data')
			setIsLoading(false);
			return;
		}

		setData(laneRouteResponseToRows(response));
		setTotalRowCount(response.totalElements);
		setIsLoading(false);
		setFilterOptions({});
	};

	useEffect(() => {
		if (!validateDate(dateValueFrom) || !validateDate(dateValueTo)) {
			return;
		}
		setIsLoading(true)
		const fetchData = async () => {
			const data = await postLaneRoute({ site: postedSitets, dateValueFrom, dateValueTo,
				postedBrokers: postedBrokers
			});
			setData(laneRouteResponseToRows(data));
			setTotalRowCount(data.totalElements);
			setIsLoading(false)
		}

		fetchData()
			.catch(console.error);
	}, [selectedSites, realoadNumber, dateValueFrom, dateValueTo, selectedBrokers])

	const handleSubscriptionChange = () => {
		setReload(!reload);
	}

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			const responseUser = await fetchUser();
			setUserEmail(responseUser.login);
			setIsUserSubscribed(responseUser.subscribed);
			const responseBrokers = await fetchBrokers();
			setBrokers(responseBrokers);
		}

		fetchData()
			.catch(console.error);
		if(data.length !== 0) {
			setIsLoading(false);
		}
	}, [reload])
	
	return (
		<>
			<Box sx={{ pl: '16px', zIndex: 3, width: 'fit-content', display: "flex", alignItems: "baseline", position: "relative", top: "38px" }}>
				<Box sx={{ display: "flex", gap: "10px", position: 'relative', bottom: '27px' }}>
					{sites && (
						<CustomMultiSelectWithPlaceholder placeholder="Select site" setSelectedSites={setSelectedSites} selectedSites={selectedSites}>
							{sites.map((name: any) => (
								<MenuItem key={name.id} value={name.code}>
									<Checkbox checked={selectedSites.indexOf(name.code) > -1} />
									<ListItemText primary={name.code} />
								</MenuItem>
							))}
						</CustomMultiSelectWithPlaceholder>
					)}
					{brokers && (
						<CustomMultiSelectWithPlaceholder placeholder="Select broker" setSelectedSites={setSelectedBrokers} selectedSites={selectedBrokers}>
							{brokers.map((name: any) => (
								<MenuItem key={name.id} value={name.name}>
									<Checkbox checked={selectedBrokers.indexOf(name.name) > -1} />
									<ListItemText primary={name.name} />
								</MenuItem>
							))}
						</CustomMultiSelectWithPlaceholder>
					)}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Box>
							<Box sx={{ fontSize: '11px' }}>Date from</Box>
							<DesktopDatePicker
								minDate={new Date()}
								inputFormat={getLocalDateFormat()}
								value={dateValueFrom ?? null}
								onChange={(e) => handleChangeFrom(e)}
								renderInput={(params: any) => <TextField {...params} />}
							/>
						</Box>
						<Box>
							<Box sx={{ fontSize: '11px' }}>Date to</Box>
							<DesktopDatePicker
								minDate={new Date()}
								inputFormat={getLocalDateFormat()}
								value={dateValueTo ?? null}
								onChange={(e) => handleChangeTo(e)}
								renderInput={(params: any) => <TextField {...params} />}
							/>
						</Box>
					</LocalizationProvider>
				</Box>
			</Box>
			<DhlTable
				site={site}
				title=""
				data={data}
				dateValueFrom={dateValueFrom}
				dateValueTo={dateValueTo}
				totalRowCount={totalRowCount}
				columns={tableColumns()}
				rowActions={rowActions}
				tableActions={tableActions}
				filterOptions={filterOptions}
				isLoading={isLoading}
				options={{
					search: false,
					downloadOptions: {
						filename: generateDownloadFilename('Lane screen'),
						separator: ';',
						filterOptions: {
							useDisplayedColumnsOnly: true,
							useDisplayedRowsOnly: false,
						},
					},
					onDownload: createDownloadHandler(downloadColumnMapper, laneCsvMapper),
					textLabels: {
						body: {
							noMatch: isLoading ? 'Loading...' : 'No tasks',
						},
						pagination: {
							rowsPerPage: 'Routes per page',
						},
						toolbar: {
							downloadCsv: 'Download CSV (Current Page Only)',
						},
					},
				}}
				onDataFetchRequested={loadDataAndFiltersOptions}
				customToolbar={() => <SubscribeToolbar subscribed={isUserSubscribed} handleButtonClick={() => openModal()}/>}
			/>
			<ModalWrapper isOpen={isModalOpen} closeModal={closeModal}>
				<SubscriptionModal isSubscribed={isUserSubscribed} userEmail={userEmail!} sites={sites} closeModal={closeModal} handleSubscribe={handleSubscriptionChange} />
			</ModalWrapper>
		</>
	)
}
