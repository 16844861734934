
import React, { FC, useContext, useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useForm, FormProvider } from "react-hook-form";

import { ModalTitle } from './ModalTitle';
import { DeclineButton } from './DeclineButton';
import { InputNameDecline } from './types';
import { FormItemSelect } from '../Forms/FormItemSelect';
import { FormItemTextField } from '../Forms/FormItemTextField';
import { post } from '../../service/service';
import { fetchDeclineReason } from '../../service/fetchReason';
import { Center } from '../layout/Center';
import NotyfContext from '../DHLTable/NotyfContext';
import { showErrorNotyf } from '../DHLTable/general.utils';
import { useOpenLanes } from '../LanesScreen/OpenLanesContext';
import { useLocalEquipmentContext } from '../LocalEquipmentDashboard/LocalEquipmentContext';

export const saveDecline = async ({ declineReasonCode, details, id }: { declineReasonCode: any, details: any, id: number }): Promise<any | undefined> => {
	try {
		const response = await post<any>(`laneRoute/decline/${id}`, {
			"declineReasonCode": declineReasonCode, "details": details
		});
		return response.data || undefined;
	} catch (e) {
		throw e;
	}
};


export const DeclineModal: FC<{ id: number, closeModalDecline: any }> = ({ id, closeModalDecline }) => {
	const methods = useForm();
	const notyf = useContext(NotyfContext);
	const [reasons, setReason] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { realoadNumber, setRealoadNumber } = useOpenLanes();
	const { realoadNumber: reloadNumberEquipment, setRealoadNumber: setReloadNumberEquipment } = useLocalEquipmentContext();

	const onSubmit = async (data: any) => {
		try {
			await saveDecline({ declineReasonCode: data.declineReasonCode, details: data.details, id: id });
			await setRealoadNumber(realoadNumber + 1)
			await setReloadNumberEquipment(reloadNumberEquipment + 1)
			await notyf.success('The data has been declined');
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to load data')
		}
	}

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			const responseReason = await fetchDeclineReason();
			setReason(responseReason);
			setIsLoading(false)
		}

		fetchData()
			.catch(console.error);

	}, [])

	if (isLoading) {
		return (
			<Center>
				<CircularProgress size={25} />
			</Center>
		)
	}

	return (
		<Box>
			<ModalTitle title={`Decline match #${id}`} />
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<FormItemSelect reasons={reasons} {...methods.register(InputNameDecline.DECLINE_REASON_CODE)} label='Decline Reason Code' inputName={InputNameDecline.DECLINE_REASON_CODE} />
					<FormItemTextField label='Additional Details' inputName={InputNameDecline.ADDITIONAL_DETAILS} type='text' isMultiline />
					<Box display='flex' justifyContent="center" mt='30px'>
						<DeclineButton closeModalDecline={closeModalDecline} disabled={methods.formState.isSubmitting} />
					</Box>
				</form>
			</FormProvider>
		</Box>
	)
}