import { MUIDataTableState } from "mui-datatables";
import { FiltersState } from "./action.utils";

export const convertInternalFiltersToFilterState = (
	{ filterList, columns }: { filterList: MUIDataTableState['filterList'], columns: any }
): FiltersState<any> => {
	if (columns.length !== filterList.length) {
		console.error('Columns and filters should have same length');
	}
	const filterState = {} as FiltersState<any>;
	for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
		const columnName = columns[columnIndex].name;
		const currentFilteringValues = filterList[columnIndex];
		filterState[columnName as keyof any] = { values: currentFilteringValues };
	}
	return filterState;
};

export const downloadColumnMapper = (columnValue: any) => {
	if (Array.isArray(columnValue)) {
		// RuleRow[]
		return columnValue
			.map((val) => `[${(val as unknown as any).id}] ${(val as unknown as any).name}`)
			.join(', ');
	} else if (Array.isArray(columnValue)) {
		return columnValue.map((val, idx) => `[${idx}] ${val}`).join(', ');
	}
	// pass other column values through and let the default DHLTable logic handle it
	return columnValue || '';
};


export const onlyUniquePredicate = (value: string, index: number, self: string[]): boolean =>
	self.indexOf(value) === index;
