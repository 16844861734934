export enum InputNameApproave {
	REQUESTED_RATE = "requestedRate",
	SUBMIT_REASON_CODE = "declineReasonCode",
	ADDITIONAL_DETAILS = "details",
}

export enum InputNameDecline {
	REQUESTED_RATE = "requestedRate",
	DECLINE_REASON_CODE = "declineReasonCode",
	ADDITIONAL_DETAILS = "details",
}
