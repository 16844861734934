import React, { useState, SyntheticEvent, FC } from 'react';
import { Box, Tabs, Tab, Paper } from '@mui/material';

import { TabPanel } from './TabPanel';
import { RouteDetails } from './RouteDetails';
import { MatchHistory } from './BidHistory';
import { BidContext } from './BidHistory/BidContext';
import { BidType } from './BidHistory/types';

export const MatchesRoutesContent: FC<{ detail: any, mainRouteDetail: any, biddingEnabled: boolean }> = ({ detail, mainRouteDetail, biddingEnabled }) => {
	const [value, setValue] = useState(0);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<BidContext>
			<Paper elevation={4} sx={{
				p: 4,
				ml: 4
			}}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={value} onChange={handleChange}>
						<Tab label="Route Details" />
						<Tab label="History" />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<RouteDetails detail={detail} mainRouteDetail={mainRouteDetail} biddingEnabled={biddingEnabled}/>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<MatchHistory id={detail.id} bidType={BidType.MATCH}/>
				</TabPanel>
			</Paper>
		</BidContext>
	)
}