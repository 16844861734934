import React, { FC } from 'react'
import { Box } from '@mui/material'

interface CustomInputProps {
	label: string;
	children: React.ReactNode;
	isBold?: boolean;
}

export const FormItemWrapper: FC<CustomInputProps> = ({ label, children, isBold = false }) => {
	return (
		<Box display="grid" gridTemplateColumns='40% auto' mb='10px' gap='20px' fontWeight={isBold ? 600 : 400}>
			<Box>{label}:</Box>
			{children}
		</Box>
	)
}
