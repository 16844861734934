import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { useLocalEquipmentContext } from "../LocalEquipmentContext";
import NotyfContext from "../../DHLTable/NotyfContext";
import { FilterOptionsForTable } from "../../DHLTable/types";
import { Center } from "../../layout/Center";
import { DhlTable } from "../../DHLTable";
import { createDownloadHandler } from "../../DHLTable/DhlTable.utils";
import { downloadColumnMapper } from "../../DHLTable/helpers";
import {
  FiltersState,
  PaginationState,
  SortingState,
} from "../../DHLTable/action.utils";
import {
  generateDownloadFilename,
  showErrorNotyf,
} from "../../DHLTable/general.utils";
import { localEquipmentColumns } from "./LocalEquipmentColumns";
import { localEquipmentResponseToRows } from "./helpers";
import { postIdleEquipment } from "../../../service/postIdleEquipment";
import { LocalEquipmentTableType, LocalEquipmentType } from "./types";

export const LocalEquipmentTable: React.FC<LocalEquipmentTableType> = ({
  site,
}) => {
  const [data, setData] = useState<LocalEquipmentType[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFirstLoading, setFirstIsLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<
    FilterOptionsForTable<any>
  >({});
  const notyf = useContext(NotyfContext);
  const { realoadNumber } = useLocalEquipmentContext();

  const loadDataAndFiltersOptions = async (
    filtersParam: FiltersState<any>,
    sortingParam: SortingState<any> | null,
    paginationParam: PaginationState
  ): Promise<void> => {
    setIsLoading(true);
    let response;
    try {
      response = await postIdleEquipment({
        site,
        filtersParam,
        sortingParam,
        paginationParam,
      });
    } catch (e) {
      showErrorNotyf(notyf, e, "Failed to load data");
      setIsLoading(false);
      return;
    }

    setData(localEquipmentResponseToRows(response));
    setTotalRowCount(response.totalElements);
    setIsLoading(false);
    setFilterOptions({});
  };

  useEffect(() => {
    setFirstIsLoading(true);
    if (site) {
      const fetchData = async () => {
        const data = await postIdleEquipment({ site });
        setData(localEquipmentResponseToRows(data));
        setFirstIsLoading(false);
        setIsLoading(false);
        setTotalRowCount(data.totalElements);
      };
      fetchData().catch(console.error).finally(()=>{
        setIsLoading(false);
      });
    }
  }, [site]);

  if (isFirstLoading) {
    return (
      <Center>
        <CircularProgress size={25} />
      </Center>
    );
  }

  return (
    <DhlTable
      site={site}
      rowsPerPage={5}
      title=""
      bottomPosition="0px"
      data={data}
      totalRowCount={totalRowCount}
      columns={localEquipmentColumns()}
      filterOptions={filterOptions}
      isLoading={isLoading}
      options={{
        search: false,
        downloadOptions: {
          filename: generateDownloadFilename("Local equipment"),
          separator: ";",
          filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: false,
          },
        },
        onDownload: createDownloadHandler(downloadColumnMapper),
        textLabels: {
          body: {
            noMatch: isLoading ? "Loading..." : "No tasks",
          },
          pagination: {
            rowsPerPage: "Routes per page",
          },
          toolbar: {
            downloadCsv: "Download CSV (Current Page Only)",
          },
        },
      }}
      onDataFetchRequested={loadDataAndFiltersOptions}
    />
  );
};
