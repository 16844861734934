import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useIsAuthenticated } from '@azure/msal-react';

import WelcomeName from './WelcomeName';
import { LoginLogoutPart } from './Authorization/LoginLogoutPart';

export const UserName: FC<{ isSidebar?: boolean }> = ({ isSidebar = false }) => {
	const isAuthenticated = useIsAuthenticated();

	return (
		<Box display='flex' alignItems='center' flexDirection={isSidebar ? "row-reverse" : undefined}>
			{isAuthenticated && <WelcomeName />}
			<LoginLogoutPart />
		</Box>
	)
}

