import { IconButton, Tooltip } from "@mui/material";
import { SubscribedIcon } from "../../../assets/icons/subscribed.icon";
import { UnsubscribedIcon } from "../../../assets/icons/unsubscribed.icon";

const CustomToolbar = ({subscribed = false,
    handleButtonClick
}: any) => {
  
    return (
      <>
        <Tooltip title={subscribed ? "Unsubscribe" : "Subscribe"}>
          <IconButton onClick={handleButtonClick}>
            {subscribed ? 
                <SubscribedIcon /> : <UnsubscribedIcon /> 
            }
          </IconButton>
        </Tooltip>
      </>
    );
}

export default CustomToolbar;