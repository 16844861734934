import {
  LocalEquipmentResponseType,
  localEquipmentResponseContentType,
  LocalEquipmentType,
} from "./types";

export const localEquipmentToRow = (
  route: localEquipmentResponseContentType
): LocalEquipmentType => {
  return {
    id: route.equipment.equipmentId,
    equipmentCode: route.equipment.equipmentId,
    equipment: route.equipment,
    laneRoutes: route.laneRoutes,
    routes: route.routes,
  };
};

export const localEquipmentResponseToRows = (
  response: LocalEquipmentResponseType
): LocalEquipmentType[] => {
  return response.content.map((row) => localEquipmentToRow(row));
};
