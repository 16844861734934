import React, { FC } from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
	return (
		<>
			{value === index && (
				<Box sx={{ p: 3, width: '100%' }}>
					{children}
				</Box>
			)}
		</>
	);
}

