import { RfpTableType, RfpRouteResponseContentType, RfpRouteResponseType } from "./types";
import { formatAsLocalDateTime } from "../../../helpers";

export const rfpRouteToRow = (route: RfpRouteResponseContentType): RfpTableType => {

	const { id, importFile, importDate, routes, status, lanes, exportFile, userId, processedDate, log } = route;

	const routeImportDate = importDate && new Date(importDate);
	const importDateValue = routeImportDate && formatAsLocalDateTime(routeImportDate);
	const processedDateDate = processedDate && new Date(processedDate);
	const rocessedDateValue = processedDateDate && formatAsLocalDateTime(processedDateDate);

	return {
		id: id,
		rfpId: id,
		inputFile: importFile,
		uploadDate: importDateValue,
		result: status,
		routes: routes,
		lanesFound: lanes,
		outputfile: exportFile,
		processDate: rocessedDateValue,
		uploadedBy: userId,
		log: log
	};
};

export const rftRouteResponseToRows = (response: RfpRouteResponseType): RfpTableType[] => {
	return response.content.map((row) => rfpRouteToRow(row));
};
