export enum Result {
	COUNTER_OFFER_SENT = "counter_offer_sent",
}

export interface BidHistoryResultProps {
	result: Result | null
}

export interface BidHistoryDetailProps {
	id: number;
	bidDate: any
}

export enum BidStatus {
	SENT = "SENT",
	FAILED = "FAILED",
	ACCEPTED = "ACCEPTED",
}

export enum InputBid {
	RATE = "requestedRate",
	MESSAGE = "details",
}

export enum BidType {
	MATCH = "MATCH",
	LANE = "LANE",
}
