import React from 'react'
import { getRFPFile } from '../../../service/getRFPFile';
import { Button } from '@mui/material';
import { FileType } from './types';

export const DownloadFileTable: React.FC<{ inputFile: FileType }> = ({ inputFile }) => {

	if (!inputFile) {
		return null
	}

	const { id, filename } = inputFile;

	const handleDownloadFile = () => {
		getRFPFile({ fileId: id, filename });
	}

	return (
		<Button onClick={handleDownloadFile} color='inherit'>
			{filename}
		</Button>
	)
}