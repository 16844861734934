import { Notyf } from 'notyf';
import axios, { AxiosError } from 'axios';
import format from 'date-fns/format';
import { ModificationResponse } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const isModificationErrorResponse = (obj: any): obj is ModificationResponse => {
	if (!obj) {
		return false;
	}
	const hasMessage = obj.hasOwnProperty('message');
	const hasError = obj.hasOwnProperty('error');
	if (!hasMessage || !hasError) {
		return false;
	}
	return typeof obj.error === 'boolean' && typeof obj.message === 'string';
};

export const showError = (
	notyf: Notyf,
	errorObject: Error | AxiosError | ModificationResponse | string | unknown,
	defaultMessage: string,
): void => {
	let msg = defaultMessage;
	// TODO catch general axios error, too
	if (isModificationErrorResponse(errorObject)) {
		// TODO this probably never happens (next condition is met instead)
		// the error object is an API response that is considered a non-success (probably has .error === true)
		msg += ': ' + errorObject.message;
	} else if (axios.isAxiosError(errorObject) && isModificationErrorResponse(errorObject.response)) {
		// we did receive a response from the API, but with a non-2xx status
		const createEditResponse = errorObject.response as unknown as ModificationResponse;
		msg += createEditResponse.message;
	} else if (axios.isAxiosError(errorObject)) {
		// other Axios error
		msg += ': ' + errorObject.message;
	} else if (errorObject instanceof Error) {
		// just a ES6 error // TODO when does this happen?
		msg += ': ' + errorObject.message;
	} else if (typeof errorObject === 'string') {
		msg += ': ' + errorObject;
	} else {
		// something else - no specific message available
	}
	notyf.error(msg);
};

export const showErrorFailedToDelete = (notyf: Notyf, error: unknown): void => {
	showError(notyf, error, 'Failed to delete');
};

export const showErrorFailedToSave = (notyf: Notyf, error: unknown): void => {
	showError(notyf, error, 'Failed to save');
};

export const showErrorNotyf = (notyf: Notyf, error: unknown, message: string): void => {
	showError(notyf, error, message);
};

export const uppercaseFirstLetter = (value: string): string => {
	if (!value) {
		return value;
	}
	return value.charAt(0).toUpperCase() + value.slice(1);
};

export const generateDownloadFilename = (baseName: string, extension = 'csv'): string => {
	const currentDate = format(new Date(), 'yyyy-MM-dd HH-mm')
	return `${baseName} ${currentDate}.${extension}`;
};


export const allMatch = <T extends unknown>(array: T[], predicate: (element: T) => boolean): boolean => {
	let allElementsMatch = true; // for some reason, Typescript complains array's .every() is used
	for (const arrayValue of array) {
		if (!predicate(arrayValue)) {
			allElementsMatch = false;
		}
	}
	return allElementsMatch;
};

export const getRrateWithCurrency = (rate: string | null) => rate ? `$ ${rate}` : null;


export const parseNum = (arg: any): number | null => {
	if (!isNaN(arg)) {
		return (arg * 1) as number;
	}

	if (arg && arg.length) {
		const n = arg.replaceAll(',', '.').replaceAll(/\s/g, '') * 1;
		if (isNaN(n)) {
			return null;
		}
		return n as number;
	}

	return null;
};

export const formatNum = (arg: any, decimalDigits = 2, decimalSeparator = '.', groupSeparator = ','): string => {
	let num: any = parseNum(arg);
	if (num === null) {
		return '';
	}

	const c = decimalDigits || 2,
		d = decimalSeparator || '.',
		t = groupSeparator || ',',
		s = num < 0 ? '-' : '',
		i = String(parseInt((num = Math.abs(num || 0).toFixed(c)))),
		ni = i as unknown as number,
		j = i.length > 3 ? i.length % 3 : 0;
	//TODO optimize?
	return (
		s +
		(j ? i.substr(0, j) + t : '') +
		i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
		(c
			? d +
			Math.abs(num - ni)
				.toFixed(c)
				.slice(2)
			: '')
	);
};

export const formatSecondsToHours = (value:number) => {
	const hours = ~~(value / 3600);
	const minutes = ~~((value - (hours * 3600)) / 60);
	if (hours > 0) {
		return `${hours}h ${minutes}m`
	} else {
		return `${minutes}m`
	}
}

export const validateDate = (date:any):boolean => {
	if (!date) {
		return true;
	}
	try {
		const value = new Date(date);
		return !!value?.toISOString();
	} catch (e) {
		console.warn("Invalid date", date);
		return false;
	}
}

export const formatRateToPercentage = (rate: string) => rate ? `${(100 * Number(rate)).toFixed(1)} %` : '';
