import { getFile } from './service';

export const getRFPFile = async ({ fileId, filename }: { fileId: number, filename: string | null }): Promise<any> => {
	try {
		const response = await getFile<Blob>(`rfp/download/${fileId}`, {});
		if (response.status && response.status === 200) {

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename || `rfp_${fileId}.xlsx`);
			document.body.appendChild(link);
			link.click();
		}
	} catch (e) {
		throw e;
	}

};
