import React, { FC } from 'react'
import { ContentHeader } from '../layout/ContentHeader'
import { MainPaper } from '../layout/Paper/MainPaper'
import { InputParameters } from './InputParameters'
import { RfpTable } from './RfpTable'
import { RfpContext } from './RfpTable/RfpContext'

export const PursuitSupport: FC = () => {
	return (
		<RfpContext>
			<ContentHeader header='RFP - Pursuit Support for Capacity' />
			<MainPaper>
				<InputParameters />
			</MainPaper>
			<MainPaper>
				<RfpTable />
			</MainPaper>
		</RfpContext>
	)
}