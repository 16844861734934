import { createTheme, ThemeOptions } from '@mui/material';

const fontSize = 13.5;

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#FFCC00',
    },
    secondary: {
      main: '#CC0000',
    },
    error: {
      main: '#CC0000',
    },
    info: {
      main: '#04395E',
    },
    success: {
      main: '#2E933C',
    },
    background: {
      paper: '#FFFFFF',
      default: '#EEEEEE',
    },
    contrastThreshold: 3,
  },
  typography: {
    fontSize,
    fontFamily:
      '"Delivery Normal", "Source Hans Sans CN Regular", "Source Hans Sans CN Regular", Myanmar, "Helvetica Neue", Helvetica, Arial, sans-serif;',
  },
  spacing: 4,
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
         padding: "8px",
        },
        head: {
          backgroundColor: "#DDDCDB !important",
          fontSize: "13px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
         minHeight: "10px !important",
         marginBottom: '10px'
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "6px",
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0px',
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "50px",
          height: "24px",
          padding: "0px"
        },
        switchBase: {
          color: "#818181",
          padding: "1px",
          "&$checked": {
            "& + $track": {
              backgroundColor: "#23bf58"
            }
          }
        },
        thumb: {
          color: "white",
          margin: "1px",
        },
        track: {
          borderRadius: "20px",
          backgroundColor: "#818181",
          opacity: "1 !important",
          "&:after, &:before": {
            color: "white",
            fontSize: "9px",
            fontWeight: 600,
            position: "absolute",
            top: "5px"
          },
          "&:after": {
            content: "'On'",
            left: "6px",
          },
          "&:before": {
            content: "'Off'",
            right: "5px",
          }
        }
      }
    }
  },
});

export default theme;