
import { MUIDataTableColumn } from 'mui-datatables';
import { ReactElement } from 'react';
import { PropTypes } from '@mui/material';

// MUIDataTableData seems to be incorrect - column data must be "object | number[] | string[]", but we have "string | string[] | object[]"
export type DhlColumnValue =
	| string
	| number
	| Record<string, unknown>
	| number[]
	| string[]
	| Record<string, unknown>[];

export interface MUIDataTableDataCustom {
	data: Array<DhlColumnValue>;
	index: number;
}

/**
 * Either maps a value to string, or leaves it as is.
 * Used for mapping column values to string when creating a CSV for download.
 */
export type DhlColumnValueMapper = (columnValue: DhlColumnValue) => string | DhlColumnValue;

export type DhlObjectValuesRenderer = (columnValue: DhlColumnValue, columnDef: MUIDataTableColumn) => string | DhlColumnValue;

export type DhlColumnObjectMapper = (columnValue: DhlColumnValue) => string;


export interface DropdownButtonProps {
	options: DropdownButtonOption[];
	defaultIndex?: number;
	onClick: (index: number) => void;
	color?: PropTypes.Color;
}

export interface DropdownButtonOption {
	label: string;
	icon?: ReactElement;
	disabled?: boolean;
}

export interface DropdownButtonOptionExtended<RowType> extends DropdownButtonOption {
	disabledOverride?: (row: RowType | undefined) => boolean;
}
export interface RowActions<RowType> extends Pick<DropdownButtonProps, 'defaultIndex'> {
	options: DropdownButtonOptionExtended<RowType>[];
	onClick: (actionIndex: number, dataIndex: number, dataRow: RowType) => void;
	color?: PropTypes.Color;
}

export const addActionsColumn = <RowType extends unknown>(
	columns: MUIDataTableColumn[],
	data: Array<RowType>,
	rowActions: RowActions<RowType> | undefined,
	internalOnClick: (actionIndex: number, dataIndex: number) => void,
): MUIDataTableColumn[] => {
	if (!rowActions || rowActions.options.length == 0) {
		return columns;
	}

	return [...columns];
};

export const randomTableVersion = (): number => Math.floor(Math.random() * 1000000000);

export const createDownloadHandler = (columnValueMapper: DhlColumnValueMapper, csvMapper?: any) => {
	return (
		buildHead: (columns: MUIDataTableColumn[]) => string,
		buildBody: (data: MUIDataTableDataCustom[]) => string,
		dlColumns: MUIDataTableColumn[],
		dlData: MUIDataTableDataCustom[],
	): string => {
		const dlDataModified: MUIDataTableDataCustom[] = [];
		for (const dlRow of dlData) {
			// eslint-disable-next-line no-loop-func
			const dlRowModified = dlRow.data.map((columnData, index) => {
				const mappedColumnData = columnValueMapper(columnData);
				const column:MUIDataTableColumn = dlColumns[index];

				const mapper = csvMapper && csvMapper[column.name];
				if (mapper) {
					const v = mapper(mappedColumnData);
					return v;
				}
				if (typeof mappedColumnData === 'string') {
					// provided mapper mapped this value to string
					return mappedColumnData;
				}
				// provided mapper just passed the value through
				const isPrimitiveColumnValue = (val: DhlColumnValue): boolean =>
					typeof val === 'string' || typeof val === 'number';
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (Array.isArray(mappedColumnData) && allMatch(mappedColumnData, isPrimitiveColumnValue)) {
					// number[] or string[]
					return mappedColumnData.join(', ');
				}
				if (Array.isArray(mappedColumnData)) {
					// object[]
					return '_ARRAY_OF_OBJECTS_';
				}
				if (typeof mappedColumnData === 'object') {
					// object
					return '_OBJECT_';
				}
				// string or number
				return mappedColumnData;
			});
			dlDataModified.push({ index: dlRow.index, data: dlRowModified });
		}
		return "sep=;\n" + buildHead(dlColumns) + buildBody(dlDataModified);
	};
};
