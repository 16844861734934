import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from "mui-datatables";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { addActionsColumn } from "./DhlTable.utils";
import DownloadIcon from "@mui/icons-material/Download";
import {
  FiltersState,
  PaginationState,
  SortingDirectionIncludingNone,
  SortingState,
} from "./action.utils";
import { CustomFilterList } from "./CustomFilterList";
import { FilterButton } from "./FilterButton";
import { DhlTableProps, FilterOptionsForTable } from "./types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { CircularProgress, LinearProgress } from "@mui/material";

export const DhlTable = <RowType extends unknown>({
  site,
  deliveryState,
  pickupState,
  dateValueFrom,
  dateValueTo,
  pickupDateValueTo,
  deliveryDateValueTo,
  pickupDateValueFrom,
  deliveryDateValueFrom,
  bottomPosition = "60px",
  onDataFetchRequested: onDataFetchRequestedProp,
  onDownloadRequested: onDownloadRequestedProp,
  filterOptions: filterOptionsProp,
  isLoading: isLoadingProp,
  isDownloading: isDownloadingProp,
  data: dataProp,
  totalRowCount: totalRowCountProp,
  columns: columnsProp,
  rowActions: rowActionsProp,
  tableActions: tableActionsProp,
  options: optionsProp,
  components: componentsProp,
  version: versionProp,
  rowsPerPage = 20,
  className = "dhlBox",
  customToolbar,
  ...passThroughProps
}: DhlTableProps<RowType>): ReactElement => {
  const {
    // the next options are called from within the default handlers here (after the mandatory logic) or otherwise used in this component
    onFilterChange,
    onColumnSortChange,
    onChangePage,
    onChangeRowsPerPage,
    onDownload,
    download,
    // other options are just passed through (and they may override default setting here)
    ...passThroughOptions
  } = optionsProp || {};

  // download option
  const defaultDownloadOption = isDownloadingProp ? "disabled" : "true";
  const effectiveDownloadOption = download ?? defaultDownloadOption;

  // download icon
  const { icons: iconsInComponentsProp, ...passThroughComponents } =
    componentsProp || {};
  const effectiveComponents: MUIDataTableProps["components"] = {
    ...passThroughComponents,
    TableFilterList: CustomFilterList,
    icons: {
      DownloadIcon: isDownloadingProp
        ? (): ReactNode => <CircularProgress size={25} />
        : DownloadIcon,
      ...iconsInComponentsProp,
    }, // use our icon unless the user explicitly specified their own DownloadIcon
  };

  // state

  const [columns, setColumns] = useState<MUIDataTableColumn[]>([]);
  const [filters, setFilters] = useState<FiltersState<RowType>>(
    {} as FiltersState<RowType>
  );
  const [sorting, setSorting] = useState<SortingState<RowType>>(
    {} as SortingState<RowType>
  );
  const [pagination, setPagination] = useState<PaginationState>({
    currentPageIndex: passThroughOptions.page ? passThroughOptions.page : 0,
    rowsPerPage: passThroughOptions.rowsPerPage
      ? passThroughOptions.rowsPerPage
      : rowsPerPage,
  });
  const [columnLock, setColumnLock] = useState(false);
  const [isFirstLoading, setFirstIsLoading] = useState(true);

  // functions

  const askParentToLoadDataAndFiltersOptions = (
    filtersParam: FiltersState<RowType>,
    sortingParam: SortingState<RowType>,
    paginationParam: PaginationState
  ): void => {
    if (onDataFetchRequestedProp) {
      // server mode enabled
      onDataFetchRequestedProp(filtersParam, sortingParam, paginationParam);
    }
  };

  const updateColumnsWithFilterOptions = (
    filterOptions: FilterOptionsForTable<RowType>
  ): void => {
    const columnsTemp = [
      ...addActionsColumn(
        columnsProp,
        dataProp,
        rowActionsProp,
        internalRowActionClick
      ),
    ];
    for (const column of columnsTemp) {
      if (filterOptions.hasOwnProperty(column.name)) {
        // filter exists for this column
        const filterOptionsForColumn =
          filterOptions[column.name as keyof RowType];
        if (filterOptionsForColumn) {
          column.options = {
            ...column.options,
            filterOptions: {
              ...column.options?.filterOptions,
              names: filterOptionsForColumn.values,
            },
          };
        }
      }
    }
    setColumns(columnsTemp);
  };

  const convertInternalFiltersToFilterState = (
    filterListParam: MUIDataTableState["filterList"]
  ): FiltersState<RowType> => {
    if (columns.length !== filterListParam.length) {
      console.error("Columns and filters should have same length");
    }
    const filterState = {} as FiltersState<RowType>;
    for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
      const columnName = columns[columnIndex].name;
      const currentFilteringValues = filterListParam[columnIndex];
      filterState[columnName as keyof RowType] = {
        values: currentFilteringValues,
      };
    }
    // filterState is object with values which looks like is ['722']
    return filterState;
  };

  // handler functions

  const internalRowActionClick = (
    actionIndex: number,
    dataIndex: number
  ): void => {
    // TODO check this works with sorting (without server-side data fetching)
    const row = dataProp[dataIndex];
    if (rowActionsProp?.onClick) {
      rowActionsProp?.onClick(actionIndex, dataIndex, row);
    }
  };

  const handleFilterSubmit = (applyFilters: (...args: any[]) => any): void => {
    // apply new filters to the table's state and UI by calling the applyFilters parameter
    const filterList = applyFilters();
    // apply new filters to the data (will trigger data reload)
    setFilters(convertInternalFiltersToFilterState(filterList));
  };

  const handleSortingChange = (
    columnName: string,
    order: SortingDirectionIncludingNone
  ): void => {
    const newSorting = columnName
      ? { columnName: columnName as keyof RowType, direction: order }
      : {};
    setSorting(newSorting as SortingState<RowType>);
  };

  const handlePaginationChange = (
    currentPageIndex: number,
    rowsPerPage: number
  ): void => {
    const newPagination: PaginationState = { currentPageIndex, rowsPerPage };
    setPagination(newPagination);
  };

  const handleDownload = (
    buildHead: (columns: MUIDataTableColumn[]) => string,
    buildBody: (data: RowType[]) => string,
    columnsParam: MUIDataTableColumn[],
    dataParam: RowType[]
  ): string | boolean => {
    if (onDownloadRequestedProp) {
      // server-mode download with filters, sorting and pagination provided
      onDownloadRequestedProp(filters, sorting, pagination);
      return false; // don't do the "local download"
    } else if (!!onDownload) {
      // user handles the download themselves
      return onDownload(buildHead, buildBody, columnsParam, dataParam);
    } else {
      // standard "local download" of the current page
      return buildHead(columnsParam) + buildBody(dataParam);
    }
  };

  // hooks
  useEffect(() => {
    //Get rid of this property
    setFirstIsLoading(false);
  }, []);


  useEffect(() => {
    if(!isFirstLoading) {
      askParentToLoadDataAndFiltersOptions(filters, sorting, pagination);
    }
  }, [filters, sorting, pagination])
  // useEffect(() => {
  //   askParentToLoadDataAndFiltersOptions(filters, sorting, pagination);
  // }, [
  //   // filters,
  //   // sorting,
  //   pagination,
  //   // site,
  //   // dateValueFrom,
  //   // dateValueTo,
  //   versionProp,
  //   deliveryState,
  //   pickupState,
  //   pickupDateValueTo,
  //   deliveryDateValueTo,
  //   pickupDateValueFrom,
  //   deliveryDateValueFrom,
  // ]);

  useEffect(() => {
    if (filterOptionsProp && Object.keys(filterOptionsProp).length > 0) {
      updateColumnsWithFilterOptions(filterOptionsProp);
      setColumnLock(true);
    }
  }, [filterOptionsProp]);

  useEffect(() => {
    if (!columnLock) {
      setColumns(
        addActionsColumn(
          columnsProp,
          dataProp,
          rowActionsProp,
          internalRowActionClick
        )
      );
    } else {
      console.error(
        "Columns are locked, because they have been modified with filter options; will not update columns"
      );
    }
  }, [columnsProp]);

  /**
   * Whenever data change, we need to set columns again, so the internalRowActionClick() is recreated, too,
   * and can access the most recent dataProps.
   * Otherwise, internalRowActionClick() will have stale (on first load it means empty) data.
   */
  useEffect(() => {
    setColumns(
      addActionsColumn(
        columnsProp,
        dataProp,
        rowActionsProp,
        internalRowActionClick
      )
    );
  }, [
    dataProp,
    site,
    dateValueFrom,
    dateValueTo,
    deliveryState,
    pickupState,
    pickupDateValueTo,
    deliveryDateValueTo,
    pickupDateValueFrom,
    deliveryDateValueFrom,
  ]);

  // table setup (most of them can be overridden with the DhlTable's 'options' prop)

  const options: MUIDataTableOptions = {
    responsive: "vertical", // table responsiveness set to stacking
    serverSide: !!onDataFetchRequestedProp, // remote data source
    confirmFilters: true, // filters have to be "confirmed" before being applied to the table's internal filterList
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    rowsPerPage: pagination.rowsPerPage,
    selectableRows: "none",
    elevation: 4,
    count: totalRowCountProp,
    download: effectiveDownloadOption,
    onDownload: handleDownload,
    viewColumns: true,
    setTableProps: () => ({ className: "tableClass" }),
    customToolbar: customToolbar,

    // when filters are submitted
    customFilterDialogFooter: (
      _currentFilterList,
      applyNewFilters
    ): React.ReactElement | null => {
      if (!applyNewFilters) {
        return null;
      }
      return (
        <FilterButton onClick={() => handleFilterSubmit(applyNewFilters)} />
      );
    },

    // when filters are changed - but here we only handle the "chip" click change
    onFilterChange: (
      changedColumn,
      filterList,
      type,
      changedColumnIndex,
      displayData
    ) => {
      if (type === "chip" || type === "custom") {
        // updating (removing) filters via "chips" (label-like values above the table)
        const newFilters = (): string[][] => filterList;
        handleFilterSubmit(newFilters);
      }

      // call user's callback if any
      if (onFilterChange) {
        onFilterChange(
          changedColumn,
          filterList,
          type,
          changedColumnIndex,
          displayData
        );
      }
    },

    // when sorting is changed
    onColumnSortChange: (changedColumn, direction) => {
      handleSortingChange(changedColumn, direction);

      // call user's callback if any
      if (onColumnSortChange) {
        onColumnSortChange(changedColumn, direction);
      }
    },

    // when pagination page is changed
    onChangePage: (currentPageIndex) => {
      handlePaginationChange(currentPageIndex, pagination.rowsPerPage);

      // call user's callback if any
      if (onChangePage) {
        onChangePage(currentPageIndex);
      }
    },

    // when pagination rows-per-page changes
    onChangeRowsPerPage: (rowsPerPage) => {
      // changing rows per page goes back to the first page
      handlePaginationChange(0, rowsPerPage);

      // call user's callback if any
      if (onChangeRowsPerPage) {
        onChangeRowsPerPage(rowsPerPage);
      }
    },
  };

  return (
    <Box
      className={className}
      sx={{ position: "relative", bottom: bottomPosition }}
    >
      {isLoadingProp && (
        <Box>
          <LinearProgress />
        </Box>
      )}
      <Paper
        sx={{
          px: " 16px",
          py: "20px",
        }}
      >
        <MUIDataTable
          data={dataProp as Record<string, unknown>[]}
          columns={columns}
          options={{ ...options, ...passThroughOptions }}
          components={effectiveComponents}
          {...passThroughProps}
        />
      </Paper>
    </Box>
  );
};
