import React, { FC } from 'react';
import { Box } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import PaidIcon from '@mui/icons-material/Paid';
import PersonIcon from '@mui/icons-material/Person';
import EastIcon from '@mui/icons-material/East';

import { DetailTable } from '../../table/DetailTable';
import { Actions } from './Actions';
import grey from '@mui/material/colors/grey';
import {formatSecondsToHours, getRrateWithCurrency, formatRateToPercentage} from '../../../DHLTable/general.utils';
import {red} from "@mui/material/colors";
import returnLocationIcon from "../../../../assets/icons/racing-flag.png";

const iconColor = grey[800];
const iconStyles = { color: iconColor, mt: '4px' }

export const RouteDetails: FC<{ detail: any, mainRouteDetail: any, biddingEnabled: boolean }> = ({ detail, mainRouteDetail, biddingEnabled }) => {

	const { id, code, site, lastStopLocation, returnLocation, routeType } = detail.route;
	const { oorTime, driveTime, minimumRate, marketRate, suggestedRate, oor, id: matchedId, gm, status } = detail;

	const routeTypeText = (type:string) => type == 'D' ? 'Dynamic Route' : type == 'S' ? 'Static Route' : type == 'M' ? 'Manual Route' : 'Idle Equipment Route';

	const tematchesRoutesDetailDatast = [
		{ id: 1, text: "Route ID", value: id },
		{ id: 2, text: "Route Name", value: code, visible: routeType != 'I' },
		{ id: 3, text: "Route Type", value: routeTypeText(routeType) },
		{ id: 4, text: "Driver ID", value: detail.route.employee ? `${detail.route.employee.name} (#${detail.route.employee.employeeId})` : "-", visible: routeType != 'I' },
		{ id: 5, text: "Shift ID", value: detail.route.externalId, visible: routeType != 'I' },
		{ id: 6, text: "Site", value: site?.code},
		{ id: 7, text: "Last stop", value: `${lastStopLocation.city}, ${lastStopLocation.state}`, tooltipColor: red[500] ,tooltip: true, address: {
				locationName: `${lastStopLocation.city}, ${lastStopLocation.state}`,
				zip: lastStopLocation.zip,
				lat: lastStopLocation?.latitude, lon: lastStopLocation?.longitude
			} },
		{ id: 8, text: "Last stop date", value: detail.route.lastStopDate },
		{ id: 9, text: "Return Location", value: `${returnLocation.city}, ${returnLocation.state}`, tooltip: true, address: {
				locationName: `${returnLocation.city}, ${returnLocation.state}`,
				zip: returnLocation.zip,
				lat: returnLocation?.latitude, lon: returnLocation?.longitude
			}, tooltipIcon: returnLocationIcon },
		{ id: 10, text: "Equipment Type", value: mainRouteDetail.equipmentType },
		{ id: 11, text: "Commodity Type", value: "-" },
	]

	const matchesRoutesMapData = [
		{ id: 1, text: "OOR Miles", value: oor },
		{ id: 2, text: "OOR Time", value: oorTime ? formatSecondsToHours(oorTime) : "-" },
		{ id: 3, text: "Revenue Miles", value: mainRouteDetail.distance },
		{ id: 4, text: "Drive Time", value: driveTime },
	]

	const mathcesRoutesMoneyData = [
		{ id: 1, text: "Broker Rate", value: getRrateWithCurrency(mainRouteDetail.rate) },
		{ id: 2, text: "Minimum Rate", value: getRrateWithCurrency(minimumRate) },
		{ id: 3, text: "Market Rate", value: getRrateWithCurrency(marketRate) },
	]

	const matchesRoutesPersonData = [
		{ id: 1, text: "HOS Hours", value: "-" },
		{ id: 2, text: "Next Shift", value: "-" },
		{ id: 3, text: "Driver Time Free", value: "-" },
		{ id: 4, text: "Re-assignment Fee", value: "-" },
	]

	const matchesRoutesSuggestedData = [
		{ id: 1, text: "Suggested Rate", value: getRrateWithCurrency(suggestedRate) },
		{ id: 2, text: "SR GM Rate", value: formatRateToPercentage(gm) },
	]

	return (
		<>
			<Box display='grid' gridTemplateColumns='40% 25% 1fr' columnGap='20px'>
				<DetailTable detail={tematchesRoutesDetailDatast} />
				<Box display='grid'>
					<DetailTable detail={matchesRoutesMapData} icon={<RouteIcon sx={iconStyles} />} />
					<DetailTable detail={mathcesRoutesMoneyData} icon={<PaidIcon sx={iconStyles} />} />
				</Box>
				<Box display='grid'>
					<DetailTable detail={matchesRoutesPersonData} icon={<PersonIcon sx={iconStyles} />} />
					<DetailTable detail={matchesRoutesSuggestedData} icon={<EastIcon sx={iconStyles} />} />
				</Box>
			</Box>
			<Actions id={matchedId} status={status} laneId={mainRouteDetail.id} biddingEnabled={biddingEnabled}/>
		</>
	)
}