import React, { FC } from 'react';
import { Box, Button, Link, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { grey } from '@mui/material/colors';


export const Options: FC = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openOptions = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (

		<Box display='flex' alignItems='center'>
			<Button
				id="demo-positioned-button"
				aria-controls={openOptions ? 'demo-positioned-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={openOptions ? 'true' : undefined}
				onClick={handleClick}
				variant="text"
				sx={{ color: grey[800], mr: '30px' }}
				endIcon={<ArrowDropDownIcon />}
			>
				Options
			</Button>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorEl}
				open={openOptions}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<MenuItem onClick={handleClose} sx={{ minWidth: '100px' }}>
					<Link underline='none' sx={{ color: grey[800] }} href={process.env.REACT_APP_CDM_URL} target='_blank' rel="noopener">
						Configuration Data Management
					</Link>
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<Link underline='none' sx={{ color: grey[800] }} href={process.env.REACT_APP_UM_URL} target='_blank' rel="noopener">
						DSC User Management
					</Link>
				</MenuItem>
			</Menu>
		</Box>
	);
};
