import { green, orange, red } from '@mui/material/colors'
import { Box } from '@mui/system'
import React, { FC } from 'react'
import { ColorCircleProps, CircleStatus } from './types'

const getStatusColor = (status: CircleStatus): string => {
	return ({
		[CircleStatus.GREEN]: green[500],
		[CircleStatus.YELLOW]: orange[400],
		[CircleStatus.RED]: red[600],
	})[status]
}

export const ColorCircle: FC<ColorCircleProps> = ({ value }) => {
	//console.log(value, 'value');
	return <Box borderRadius={'50%'} width={20} height={20} bgcolor={getStatusColor(value)}></Box>
}
