import { post } from './service';


export const postSubscribe = async (email: string, siteIdList: string[]): Promise<any | undefined> => {
	try {
		const response = await post<any>(`subscription/subscribe`,
			{
				"email": email,
				"siteIdList": siteIdList,
			}
		);
		return response || undefined;
	} catch (e) {
		throw e;
	}
};

export const postUnsubscribe = async (email: string, siteIdList: string[]): Promise<any | undefined> => {
	try {
		const response = await post<any>(`subscription/unsubscribe`,
			{
				"email": email,
				"siteIdList": siteIdList,
			}
		);
		return response || undefined;
	} catch (e) {
		throw e;
	}
};