export interface LaneScreenStatusProps {
	result: Result;
	log: any
}

export enum Result {
	IN_PROCESS = "IN_PROCESS",
	INVALID = "INVALID",
	PROCESSED = "PROCESSED",
	FAILED = "FAILED",
	NEW = "NEW"
}

export interface FileType {
	id: number,
	filename: null | string,
	dateTime: string
}


export interface RfpTableType {
	id: number,
	rfpId: number,
	inputFile: FileType | null,
	uploadDate: string,
	result: string,
	routes: number | null,
	lanesFound: number,
	outputfile: FileType | null,
	processDate: string,
	uploadedBy: string,
	log?: string
}


export interface RfpRouteResponseContentType {
	id: number;
	userId: string;
	importDate: string;
	status: string,
	processedDate: string,
	routes: number,
	lanes: number,
	importFile: FileType,
	exportFile: FileType,
	log?: string
}

export interface RfpRouteResponseType {
	content: RfpRouteResponseContentType[];
	empty: boolean;
	first: boolean;
	last: boolean;
	number: number;
	numberOfElements: number;
	pageable: Record<string, string | null | number | boolean>;
	size: number;
	sort: Record<string, string | null | number | boolean>;
	totalElements: number;
	totalPages: number;
}