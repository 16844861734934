import React, { FC, useRef } from 'react';
import { Box, Collapse, ListItemButton, ListItemText } from '@mui/material';
import { CustomListItemProps } from './types';
import { MenuIconWrapper } from './MenuIconWrapper';
import { ListItemIconWrapper } from './ListItemIconWrapper';
import { ListItemTextWrapper } from './ListItemTextWrapper';

export const CustomListItemContent: FC<CustomListItemProps> = ({ menu, open }) => {
	const { isNested, title, icon, subtitles } = menu;
	const [openNestedItem, setOpenNestedItem] = React.useState(false);
	const ref = useRef();

	const handleClick = () => {
		setOpenNestedItem(!openNestedItem);
	};

	if (isNested && subtitles) {
		return (
			<Box>
				<Box sx={{ display: 'flex' }}>
					<MenuIconWrapper title={title} open={open}>
						<ListItemIconWrapper open={open} icon={icon} ref={ref} />
					</MenuIconWrapper>
					<ListItemTextWrapper title={title} open={open} onClick={handleClick} />
				</Box>

				<Collapse in={openNestedItem} timeout="auto" unmountOnExit>
					{subtitles.map(nested => (
						<ListItemButton key={nested.id}>
							<ListItemText primary={nested.title} />
						</ListItemButton>
					))}
				</Collapse>
			</Box>
		)
	}

	return (
		<>
			<MenuIconWrapper title={title} open={open} >
				<ListItemIconWrapper open={open} icon={icon} />
			</MenuIconWrapper>
			<ListItemTextWrapper title={title} open={open} />
		</>
	)
}
