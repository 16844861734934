import { ListItemText } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { FC } from 'react'

export const ListItemTextWrapper: FC<{ title: string, open: boolean, onClick?: () => void }> = ({ title, open, onClick }) => {
	return (
		<ListItemText onClick={onClick} sx={{
			opacity: open ? 1 : 0,
			'.css-l4p2bh-MuiTypography-root': {
				color: `${grey[800]} !important`,
			}
		}}>{title}</ListItemText>
	)
}