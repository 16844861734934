import React, { FC } from 'react'
import { Box } from '@mui/system'
import { green, yellow, red } from '@mui/material/colors';
import { BidStatus } from './types';

const getStatusColor = (status: BidStatus): string => {
	return ({
		[BidStatus.SENT]: yellow[700],
		[BidStatus.ACCEPTED]: green[600],
		[BidStatus.FAILED]: red[600],
	})[status]
}

const getStatusText = (status: BidStatus): string => {
	return ({
		[BidStatus.SENT]: "Sent",
		[BidStatus.ACCEPTED]: "Accepted",
		[BidStatus.FAILED]: "Failed",
	})[status]
}

export const BisHistoryStatus: FC<{ status: BidStatus }> = ({ status }) => {
	if (status == null) {
		return <Box>-</Box>
	}

	return (
		<Box
			sx={{
				border: '2px solid',
				borderColor: getStatusColor(status),
				color: getStatusColor(status),
				display: 'flex',
				justifyContent: 'center',
				fontWeight: '600',
				padding: 1
			}}>
			{getStatusText(status)}
		</Box>
	)
}
