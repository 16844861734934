import { useState } from "react";

interface UseModalReturnTypeProps {
	isModalOpen: boolean;
	openModal(): void;
	closeModal(): void;
	toggleIsModalOpen: () => void;
};

export type UseModalType = (initialState?: boolean) => UseModalReturnTypeProps;

export const useModalState: UseModalType = (initialState = false) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(initialState);
	const toggleIsModalOpen = () => setIsModalOpen(!isModalOpen);
	const closeModal = () => setIsModalOpen(false);
	const openModal = () => setIsModalOpen(true);

	return {
		isModalOpen,
		closeModal,
		openModal,
		toggleIsModalOpen,
	};
};
