
import { getFile } from "./service";

export const getTemplate = async (): Promise<any> => {

	try {
		const response = await getFile<Blob>(`rfp/template`, {});
		if (response.status && response.status === 200) {
			console.info('response', response);
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `template.xlsx`);
			document.body.appendChild(link);
			link.click();
		}
	} catch (e) {
		throw e;
	}
};



