import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import { IconWrapperProps } from './types';

export const MenuIconWrapper: FC<IconWrapperProps> = ({ title, children, open }) => {
	if (!open) {
		return (
			<Tooltip title={title} arrow placement="right">
				{children}
			</Tooltip>
		)
	}

	return (
		<>
			{children}
		</>
	)
}