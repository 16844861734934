import React, { FC } from "react";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/material";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

interface ModalWrapperProps {
	isOpen: boolean;
	closeModal(): void;
	children: React.ReactNode;
};

export const ModalWrapper: FC<ModalWrapperProps> = ({ children, isOpen, closeModal }) => {

	return (
		<Modal open={isOpen} onClose={closeModal} disableEnforceFocus>
			<Fade in={isOpen} >
				<Box
					sx={{
						position: 'relative',
						padding: '40px',
						margin: '50px auto',
						maxWidth: '500px',
						background: 'white',
					}}>
					<IconButton color='default' onClick={closeModal}
						sx={{
							position: 'absolute',
							top: '20px',
							right: '20px',
							outline: 'none'
						}}>
						<CloseIcon />
					</IconButton>

					{children}
				</Box>
			</Fade>
		</Modal>
	);
};
