import React from 'react'
import { BidStatus } from './types';
import { BisHistoryStatus } from './BidHistoryStatus';
import { BidHistoryDate } from './BidHistoryDate';
import { MUIDataTableColumn } from 'mui-datatables';
import { Status } from '../../../LanesScreen/LanesScreenTable/types';
import { LaneScreenStatus } from '../../../LanesScreen/LanesScreenTable/LaneScreenStatus';

export const historytableColumns = (): MUIDataTableColumn[] => ([
	{
		name: "id",
		label: "Id",
	},
	{
		name: "matchStatus",
		label: "Match Status",
		options: {
			sort: true,
			customBodyRender: (status: Status) => <LaneScreenStatus status={status} />,
		}
	},
	{
		name: "rate",
		label: "Rate",
		options: {
			sort: true,
			customBodyRender: (rate: String) => rate != null ? rate : "-",
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			sort: true,
			customBodyRender: (status: BidStatus) => <BisHistoryStatus status={status} />,
		}
	},
	{
		name: "rejectReason",
		label: "Reason",
		options: {
			sort: true,
			customBodyRender: (reason: String) => reason != null ? reason : "-",
		}
	},
	{
		name: "bidMessage",
		label: "Additonal Details",
		options: {
			filter: true,
			sort: true,
			customFilterListOptions: { render: (bidMessage): string => `Additional details include: ${bidMessage}` },
			filterType: 'textField',
		}
	},
	{
		name: "bidType",
		label: "Type",
		options: {
			sort: true,
		}
	},
	{
		name: "user",
		label: "User",
		options: {
			sort: true,
		}
	},
	{
		name: "bidDate",
		label: "Submitted",
		options: {
			filter: true,
			sort: true,
			customBodyRender: (bidDate: BidStatus) => <BidHistoryDate bidDate={bidDate} />,
		}
	},
]);
