import { Box } from '@mui/material';
import React, { FC } from 'react'
import { yellow } from '@mui/material/colors';
import { TooltipContentProps } from './types';

export const TooltipContent: FC<TooltipContentProps> = ({ address }) => {
	const { locationName, street, area, state, zip, country, lat, lon } = address;
	return (
		<Box sx={{ p: '20px', bgcolor: yellow[100] }}>
			<Box>{locationName}</Box>
			<Box>{street}</Box>
			<Box>{area}</Box>
			<Box>{state}</Box>
			<Box>{zip}</Box>
			<Box>{country}</Box>
			<Box>lat: {lat}</Box>
			<Box>lon: {lon}</Box>
		</Box>
	)
}