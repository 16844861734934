import React, { useContext, useEffect, useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, ListItemText, TextField } from "@mui/material";
import { postCapacityAssignmentRoute } from '../../service/postCapacityAssignmentRoute';
import { createDownloadHandler } from '../DHLTable/DhlTable.utils';
import { columnsCapacityAssignment } from './columnsCapacityAssignment';
import {generateDownloadFilename, showErrorNotyf, validateDate} from '../DHLTable/general.utils';
import { FiltersState, PaginationState, SortingState } from '../DHLTable/action.utils';
import { RouteType } from './types';
import {routeCsvMapper, routeResponseToRows} from './helpers';
import { downloadColumnMapper } from '../DHLTable/helpers';
import { FilterOptionsForTable } from '../DHLTable/types';
import { fetchReason } from '../../service/fetchReason';
import { DhlTable } from '../DHLTable';
import { CustomMultiSelectWithPlaceholder } from '../Forms/CustomSelectWithPlaceholder';
import NotyfContext from '../DHLTable/NotyfContext';
import { useSites } from '../layout/SitesContext';
import { getStartOfDate, getEndOfDay, getLocalDateFormat } from '../../helpers';
import { ModalWrapper } from '../Modal/ModalWrapper';
import { useModalState } from '../../hooks/useModalState';
import CapacityDistributionToolbar from './distribution/DistributionToolbar';
import { DistributionModal } from './distribution/DistributionModal';

export const CapacityAssignment: React.FC<any> = ({ rowActions, tableActions }) => {
	const todayStart = getStartOfDate(new Date());
	const [data, setData] = useState<RouteType[]>([]);
	const [reasons, setReason] = useState<any[]>([]);
	const [dateValueTo, setDateValueTo] = useState<Date | null>(null);
	const [dateValueFrom, setDateValueFrom] = useState<Date | null>(todayStart);
	const [isLoading, setIsLoading] = useState(true);
	const [totalRowCount, setTotalRowCount] = useState<number>(0);
	const [filterOptions, setFilterOptions] = useState<FilterOptionsForTable<RouteType>>({});
	const [selectedSites, setSelectedSites] = React.useState<any[]>([]);
	const { sites } = useSites();
	const notyf = useContext(NotyfContext);
	const { isModalOpen, closeModal, openModal } = useModalState();

	const handleChangeTo = (value: any) => {
		if (value) {
			setDateValueTo(getEndOfDay(value));
		} else {
			setDateValueTo(value);
		}
	};

	const handleChangeFrom = (value: any) => {
		if (value) {
			setDateValueFrom(getStartOfDate(value));
		} else {
			setDateValueFrom(value);
		}
	};

	const postedSitets = sites?.filter(site => selectedSites.some(selectedSite => site.code === selectedSite)).map(siteId => siteId.id);


	const loadDataAndFiltersOptions = async (
		filtersParam: FiltersState<RouteType>,
		sortingParam: SortingState<RouteType> | null,
		paginationParam: PaginationState,
	): Promise<void> => {
		setIsLoading(true);
		if (!validateDate(dateValueFrom) || !validateDate(dateValueTo)) {
			return;
		}
		let response;
		try {
			response = await postCapacityAssignmentRoute({ site: postedSitets, filtersParam, sortingParam, paginationParam, dateValueTo, dateValueFrom, reasons });
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to load data')
			setIsLoading(false);
			return;
		}

		setIsLoading(false);
		setData(routeResponseToRows(response));
		setTotalRowCount(response.totalElements);
		setFilterOptions({});
	};

	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			const data = await postCapacityAssignmentRoute({ site: postedSitets, dateValueFrom, dateValueTo });
			setData(routeResponseToRows(data as any));
			setTotalRowCount(data.totalElements);
			setIsLoading(false);
		}

		fetchData()
			.catch(console.error);
	}, [selectedSites, dateValueFrom, dateValueTo])

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			const responseReason = await fetchReason();
			setReason(responseReason);
		}

		fetchData()
			.catch(console.error);
		if(data.length !== 0) {
			setIsLoading(false);
		}
	}, [])

	return (
		<>
			<Box sx={{ pl: '16px', zIndex: 3, width: 'fit-content', display: "flex", alignItems: "baseline", position: "relative", top: "38px" }}>
				<Box sx={{ display: "flex", gap: "10px", position: 'relative', bottom: '27px' }}>
					{sites && (
						<CustomMultiSelectWithPlaceholder placeholder="Select site" setSelectedSites={setSelectedSites} selectedSites={selectedSites}>
							{sites.map((name: any) => (
								<MenuItem key={name.id} value={name.code}>
									<Checkbox checked={selectedSites.indexOf(name.code) > -1} />
									<ListItemText primary={name.code} />
								</MenuItem>
							))}
						</CustomMultiSelectWithPlaceholder>
					)}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<Box>
							<Box sx={{ fontSize: '11px' }}>Date from</Box>
							<DesktopDatePicker
								minDate={new Date()}
								inputFormat={getLocalDateFormat()}
								value={dateValueFrom ?? null}
								onChange={(e) => handleChangeFrom(e)}
								renderInput={(params: any) => <TextField {...params} />}
							/>
						</Box>
						<Box>
							<Box sx={{ fontSize: '11px' }}>Date to</Box>
							<DesktopDatePicker
								minDate={new Date()}
								inputFormat={getLocalDateFormat()}
								value={dateValueTo ?? null}
								onChange={(e) => handleChangeTo(e)}
								renderInput={(params: any) => <TextField {...params} />}
							/>
						</Box>
					</LocalizationProvider>

				</Box>
			</Box>
			<DhlTable
				title=""
				data={data}
				dateValueFrom={dateValueFrom}
				dateValueTo={dateValueTo}
				totalRowCount={totalRowCount}
				columns={columnsCapacityAssignment(reasons)}
				rowActions={rowActions}
				tableActions={tableActions}
				filterOptions={filterOptions}
				isLoading={isLoading}
				options={{
					search: false,
					downloadOptions: {
						filename: generateDownloadFilename('Capacity Assignment'),
						separator: ';',
						filterOptions: {
							useDisplayedColumnsOnly: true,
							useDisplayedRowsOnly: false,
						},
					},
					onDownload: createDownloadHandler(downloadColumnMapper, routeCsvMapper),
					textLabels: {
						body: {
							noMatch: isLoading ? 'Loading...' : 'No tasks',
						},
						pagination: {
							rowsPerPage: 'Routes per page',
						},
						toolbar: {
							downloadCsv: 'Download CSV (Current Page Only)',
						},
					},
				}}
				onDataFetchRequested={loadDataAndFiltersOptions}
				customToolbar={() => <CapacityDistributionToolbar handleButtonClick={() => openModal()}/>}
			/>
			<ModalWrapper isOpen={isModalOpen} closeModal={closeModal}>
				<DistributionModal closeModal={closeModal} />
			</ModalWrapper>
		</>
	);
};
