import React, { FC, useState, useEffect, useContext } from "react";

import { DhlTable } from "../../../DHLTable";
import { postBidHistory } from "../../../../service/postBidHistory";
import { generateDownloadFilename, showErrorNotyf } from "../../../DHLTable/general.utils";
import { FiltersState, PaginationState, SortingState } from "../../../DHLTable/action.utils";
import { createDownloadHandler } from "../../../DHLTable/DhlTable.utils";
import { downloadColumnMapper } from "../../../DHLTable/helpers";
import { laneRouteBidResponseToRows } from "./helpers";
import { Center } from "../../../layout/Center";
import CircularProgress from "@mui/material/CircularProgress";
import { historytableColumns } from "./historytableColumns";
import { Box, Button } from "@mui/material";
import NotyfContext from "../../../DHLTable/NotyfContext";
import { ModalWrapper } from "../../../Modal/ModalWrapper";
import { useModalState } from "../../../../hooks/useModalState";
import { AddBidHistory } from "./AddBidHistory";
import { useBidRealod } from "./BidContext";

export const MatchHistory: FC<any> = ({ rowActions, tableActions, id, bidType }) => {
	const { isModalOpen, closeModal, openModal } = useModalState();
	const [data, setData] = useState<any[]>([]);
	const [isFirstLoading, setFirstIsLoading] = useState(true);
	const [totalRowCount, setTotalRowCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState(true);
	const notyf = useContext(NotyfContext);
	const { realoadNumber } = useBidRealod();

	const loadDataAndFiltersOptions = async (
		filtersParam: FiltersState<any>,
		sortingParam: SortingState<any> | null,
		paginationParam: PaginationState,
	): Promise<void> => {
		setIsLoading(true);
		let response;
		try {
			response = await postBidHistory({ filtersParam, sortingParam, paginationParam, id, bidType });
		} catch (e) {
			showErrorNotyf(notyf, e, 'Failed to load data')
			setIsLoading(false);
			return;
		}

		setData(laneRouteBidResponseToRows(response));
		setTotalRowCount(response.totalElements);
		setIsLoading(false);
	};

	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			const data = await postBidHistory({ id, bidType });
			setData(laneRouteBidResponseToRows(data));
			setFirstIsLoading(false);
			setIsLoading(false);
		}

		fetchData()
			.catch(console.error);

	}, [realoadNumber])

	if (isFirstLoading) {
		return (
			<Center mt="50px">
				<CircularProgress size={25} />
			</Center>
		)
	}

	const handleClick = () => {
		openModal()
	}

	return (
		<Box>
			<DhlTable
				bottomPosition="0px"
				title={<Button onClick={handleClick} variant="contained" sx={{ color: 'white' }}>Add bid</Button>}
				data={data}
				totalRowCount={totalRowCount}
				columns={historytableColumns()}
				rowActions={rowActions}
				tableActions={tableActions}
				isLoading={isLoading}
				options={{
					search: false,
					downloadOptions: {
						filename: generateDownloadFilename('Lane screen detail'),
						separator: ';',
						filterOptions: {
							useDisplayedColumnsOnly: true,
							useDisplayedRowsOnly: false,
						},
					},
					onDownload: createDownloadHandler(downloadColumnMapper),
					textLabels: {
						body: {
							noMatch: isLoading ? 'Loading...' : 'No tasks',
						},
						pagination: {
							rowsPerPage: 'Routes per page',
						},
						toolbar: {
							downloadCsv: 'Download CSV (Current Page Only)',
						},
					},
				}}
				onDataFetchRequested={loadDataAndFiltersOptions}
			/>
			<ModalWrapper isOpen={isModalOpen} closeModal={closeModal}>
				<AddBidHistory id={id} closeModal={closeModal} bidType={bidType}/>
			</ModalWrapper>
		</Box>
	)
}

