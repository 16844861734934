import { LaneRouteResponseType, OpenLaneResponseContentType, OpenLaneType } from "./types";
import { formatAsLocalDateTime, getLocation } from "../../helpers";
import {Status} from "./LanesScreenTable/types";
import {getStatusText} from "./LanesScreenTable/LaneScreenStatus";
import { formatRateToPercentage } from "../DHLTable/general.utils";

export const laneRouteToRow = (openLane: OpenLaneResponseContentType): OpenLaneType => {

	const { id, routeId, laneId, site, routeName, bhPickup, bhPickupDate, bhDelivery, broker, loadId, oor, revenueMiles, bhMiles,
		suggestedRate, gm, status, biddingEnabled, created, updated } = openLane;

	const dateFormat = new Date(bhPickupDate);
	const date = bhPickupDate ? formatAsLocalDateTime(dateFormat) : "";
	const createdDate = created ? formatAsLocalDateTime(new Date(created)) : "";
	const updatedDate = updated ? formatAsLocalDateTime(new Date(updated)) : "";

	const suggestedRateValue = suggestedRate && suggestedRate.toFixed(2);

	const srGm = gm ? formatRateToPercentage(gm) : "";

	return {
		id: id,
		routeName: routeName ?? "",
		bhPickup: bhPickup,
		bhPickupDate: date,
		bhDelivery: bhDelivery,
		brokerName: broker,
		loadId: loadId,
		oor: oor,
		distance: revenueMiles,
		returnMiles: bhMiles,
		fromMiles: bhMiles,
		bhMiles: bhMiles,
		suggestRate: Number(suggestedRateValue),
		suggestRateTo: Number(suggestedRateValue),
		suggestRateFrom: Number(suggestedRateValue),
		gm: srGm,
		rank: 1,
		action: routeId,
		status: status,
		site: site,
		laneId: laneId,
		actionStatus: status,
		biddingEnabled: biddingEnabled,
		created: createdDate,
		updated: updatedDate,
	};
};

export const laneRouteResponseToRows = (response: LaneRouteResponseType): OpenLaneType[] => {
	return response.content.map((row) => laneRouteToRow(row));
};

export const laneCsvMapper = {
	site: (value:any) => value.code,
	broker: (value:any) => value.name,
	status: (value:any) => {
		const v = Object.keys(Status).find(s=>s == value);
		if (v) {
			//@ts-ignore
			return getStatusText(Status[v]);
		}
		return null;
	}
}
