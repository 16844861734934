import { Box, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Center } from '../../layout/Center';
import { MainPaper } from '../../layout/Paper/MainPaper';
import { MainDetailTable } from './table/MainDetailTable';
import { MatchHistory } from '../../LaneScreenDetail/MatchesRoutes/BidHistory';
import { LocalIdleLaneScreenDetailMap } from './LocalIdleLaneScreenDetailMap';
import { BidType } from '../../LaneScreenDetail/MatchesRoutes/BidHistory/types';
import { Actions } from './Actions';

export const LocalIdleLaneScreenDetailContent: FC<{detail: any}> = ({detail}) => {

	if (!detail) {
		return (
			<Center>
				No detail data available
			</Center>
		)
	}

	const biddingEnabled = detail.broker.biddingEnabled;

	return (
		<>
			<MainPaper>
				<Box display='grid' gridTemplateColumns='1fr 1fr' gap='100px'>
					<Box>
						<MainDetailTable detail={detail} />
					</Box>
					<Box>
						<LocalIdleLaneScreenDetailMap lane={detail} />
					</Box>
				</Box>
			</MainPaper>
			<MainPaper>
				<Typography variant='h3' sx={{ fontSize: "18px", fontWeight: 600, mb: 8 }}>Bid History</Typography>
				<Paper elevation={4} sx={{
					p: 4,
				}}>
					<MatchHistory id={detail.id} bidType={BidType.LANE}/>
					<Actions laneId={detail.id} biddingEnabled={biddingEnabled}/>
				</Paper>
			</MainPaper>
		</>
	)
}