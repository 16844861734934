import React, { useEffect, useState } from "react";
import { LocalEquipmentTable } from "./LocalEquipmentTable";
import { Paper, Box, Typography } from "@mui/material";
import { SelectSite } from "../LocalIdleDashboard/LocalIdleTable/SelectSite";
import { SelectedSite } from "../LocalIdleDashboard";
import { LocalEquipmentContextContainer } from "./LocalEquipmentContext";
import { useSites } from "../layout/SitesContext";
import { fetchLatestImportLog } from "../../service/fetchImportLog";
import { LatestImportLogResponseType } from "../LocalIdleDashboard/LocalIdleTable/types";
import { formatAsLocalDateTime } from "../../helpers";

export const LocalEquipmentDashboard: React.FC<{}> = () => {
  const { sites } = useSites();
  const [site, setSite] = useState<number | null>(null);
  const [isFirstLoading, setFirstIsLoading] = useState<boolean>(true);
	const [lastImportInfo, setLastImportInfo] = useState<LatestImportLogResponseType>();

	const getLocalDateTime = () => {
		return lastImportInfo?.finished ? `Last updated on: ${formatAsLocalDateTime(lastImportInfo?.finished!)}` : 'Last updated on: unknown';
	}

	useEffect(() => {
    setFirstIsLoading(true);

		const importLogData = async () => {
			const response = await fetchLatestImportLog('match');
			setLastImportInfo(response);
		}

    importLogData()
      .catch(console.error);

		setFirstIsLoading(false);
	}, [])

  const selectedSiteData = sites.filter((i) => i.id === site);
  const selectedSiteName = selectedSiteData[0]?.code;

  return (
    <LocalEquipmentContextContainer>
      <Paper
        elevation={2}
        sx={{
          p: 4,
          mb: 4,
          mt: "64px",
        }}
      >
        <Box sx={{ mb: 8, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
					<Typography sx={{ fontSize: "19px", fontWeight: 600}}>Idle Equipment Dashboard</Typography>
					{lastImportInfo && 
						<Typography sx={{ fontSize: 11, fontWeight: '400' }}>
							{getLocalDateTime()}
						</Typography>
					}
				</Box>
        {selectedSiteName && (
          <SelectedSite selectedSiteName={selectedSiteName} setSite={setSite} />
        )}
      </Paper>
      {site ? (
        <LocalEquipmentTable site={site} />
      ) : (
        <SelectSite
          isFirstLoading={isFirstLoading}
          sites={sites}
          site={site}
          setSite={setSite}
        />
      )}
    </LocalEquipmentContextContainer>
  );
};
