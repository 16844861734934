import { PopupRequest, Configuration, AccountInfo } from '@azure/msal-browser';

const REACT_APP_CLIENT_ID = '8c5d7e84-334e-490e-9701-9c7bb1d461c4';
const REACT_APP_REDIRECT_URL = "http://localhost:3000/"

const clientId = process.env.REACT_APP_CLIENT_ID || 'PUT_YOUR_CLIENT_ID_INTO_PACKAGE_JSON';
const redirectUrl = process.env.REACT_APP_REDIRECT_URL || 'PUT_YOUR_REDIRECT_URL_INTO_PACKAGE_JSON';

export const loginRequest: PopupRequest = {
	scopes: [`${clientId}/.default`],
};

export const msalConfig: Configuration = {
	auth: {
		clientId: clientId,
		authority: 'https://login.microsoftonline.com/cd99fef8-1cd3-4a2a-9bdf-15531181d65e',
		redirectUri: redirectUrl,
		postLogoutRedirectUri: '/',
	},
};

const activeAccountHolder: AccountHolder = {
	value: null,
};

type AccountHolder = {
	value: AccountInfo | null;
};

export const setActiveAccount = (account: AccountInfo | null): void => {
	activeAccountHolder.value = account;
};

export const getActiveAccount = (): AccountInfo | null => {
	return activeAccountHolder.value;
};
