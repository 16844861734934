import { isSomeContainInObject } from "../components/DHLTable/action.utils";
import { getLocaleISOStringDate } from "../helpers";
import { post } from "./service";
import { FilterType, Type } from "./types";

export const formatDateForFilter = (date: undefined | Date) => {
	const value = date && getLocaleISOStringDate(date)
	return value;
}

export const postCapacityAssignmentRoute = async ({ site, filtersParam, sortingParam, paginationParam, dateValueTo, dateValueFrom, reasons }: any): Promise<any | undefined> => {

	const filters = {};
	const sortingData = {};
	const dateTo = formatDateForFilter(dateValueTo);
	const dateFrom = formatDateForFilter(dateValueFrom)

	if (isSomeContainInObject(sortingParam)) {
		Object.assign(sortingData, { "colId": sortingParam.columnName, "sort": sortingParam.direction });
	} else {
		Object.assign(sortingData, {"colId": "routeStart", "sort": "desc"});
	}
	if (site?.length > 0) {
		Object.assign(filters, { site: { "filterType": FilterType.SET, "values": site } });
	}
	if (filtersParam?.id?.values.length > 0) {
		Object.assign(filters, { id: { "filterType": FilterType.NUMBER, "filter": filtersParam.id.values[0], type: Type.EQUALS } });
	}
	if (filtersParam?.code?.values.length > 0) {
		Object.assign(filters, { code: { "filterType": FilterType.TEXT, "filter": filtersParam.code.values[0], type: Type.CONTAINS } });
	}
	if (filtersParam?.externalId?.values.length > 0) {
		Object.assign(filters, { externalId: { "filterType": FilterType.TEXT, "filter": filtersParam.externalId.values[0], type: Type.CONTAINS } });
	}
	if (filtersParam?.reason?.values.length > 0) {

		const filtersParams = filtersParam?.reason.values;
		const resultTwo = filtersParams.map((itemB: any) => {
			return [...reasons, {id: null, name: 'Available'}]
				.filter((itemA: any) => itemA.name === itemB)
				.reduce((_: any, item: any) => ({ ...item }))
		});
		const values = resultTwo.map((i: any) => i.id);
		Object.assign(filters, { reason: { "filterType": FilterType.SET, "values": values } });
	}
	if (filtersParam?.fromMiles?.values.length > 0 && filtersParam?.toMiles.values.length === 0) {
		Object.assign(filters, { returnMiles: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam.fromMiles.values[0]), type: Type.GREATER_THAN } });
	}
	if (filtersParam?.fromMiles?.values.length > 0 && filtersParam?.toMiles.values.length > 0) {
		Object.assign(filters, { returnMiles: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam.fromMiles.values[0]), "filterTo": Number(filtersParam.toMiles.values[1]), type: Type.RANGE } });
	}
	if (filtersParam?.fromMiles?.values.length === 0 && filtersParam?.toMiles.values.length > 0) {
		Object.assign(filters, { returnMiles: { "filterType": FilterType.DECIMAL, "filter": Number(filtersParam.toMiles.values[1]), type: Type.LESS_THAN } });
	}
	if (dateValueFrom && !dateValueTo) {
		Object.assign(filters, { routeStart: { "filterType": FilterType.DATE, "type": Type.GREATER_THAN, "dateFrom": dateFrom, "dateTo": dateTo } });
	}
	if (dateValueTo && !dateValueFrom) {
		Object.assign(filters, { routeStart: { "filterType": FilterType.DATE, "type": Type.LESS_THAN, "dateFrom": dateTo } });
	}
	if (dateValueTo && dateValueFrom) {
		Object.assign(filters, { routeStart: { "filterType": FilterType.DATE, "type": Type.RANGE, "dateFrom": dateFrom, "dateTo": dateTo } });
	}
	if (filtersParam?.t5Shift?.values.length > 0) {
		Object.assign(filters, { t5Shift: { "filterType": FilterType.TEXT, "filter": filtersParam.t5Shift.values[0], type: Type.CONTAINS } });
	}
	if (filtersParam?.name?.values.length > 0) {
		Object.assign(filters, { name: { "filterType": FilterType.TEXT, "filter": filtersParam.name.values[0], type: Type.CONTAINS } });
	}

	Object.assign(filters, { type: { "filterType": FilterType.SET, "values": ["S","D", "M"] } });

	try {
		const response = await post<any>(`route`,
			{
				"page": paginationParam?.currentPageIndex ?? 0,
				"size": paginationParam?.rowsPerPage ?? 20,
				"filter": {
					...filters
				},
				//...(isSomeContainInObject(sortingParam) && { 'sort': [sortingData] })
				...{ 'sort': [sortingData] }
			}
		);
		return response.data || undefined;
	} catch (e) {
		throw e;
	}
};
