import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import React, { FC } from 'react';
import { formatAsLocalDateTime } from '../../../../helpers';

export const BidHistoryDate: FC<{ bidDate: string }> = ({ bidDate }) => {
	const bidHistoryDate = new Date(bidDate);
	const date = bidDate ? formatAsLocalDateTime(bidHistoryDate) : "";

	return (
		<Box color={grey[700]}>{date}</Box>
	);
}

