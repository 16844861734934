
import { MapContainer, TileLayer, Polyline, Marker, useMapEvents, Popup, Tooltip, LayersControl, LayerGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MapType } from '../LocalIdleTable/types';
import { Paper } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { Icon, LatLng } from 'leaflet';
import { SitesType } from '../../../service/types';
import yellowIcon from '../../../assets/icons/marker-icon-gold.png';
import originIcon from '../../../assets/icons/origin-marker-icon.png';
import destinationIcon from '../../../assets/icons/destination-icon.png';
import redIcon from '../../../assets/icons/location-pin.png';
import { useState } from 'react';

type MapProps = {
	mapData: MapType[];
	selectedSiteData?: SitesType[],
	isDataVisible: boolean,
	coordinates: any,
	onManualChange: any,
};
 
export const LocalIdleMap: React.FC<MapProps> = ({ mapData, selectedSiteData, isDataVisible = false, coordinates, onManualChange }) => {
	const lanesPosition = mapData ? [mapData[0]?.deliveryLat, mapData[0]?.deliveryLng] : [];

	const selectedPosition = (selectedSiteData && [selectedSiteData[0]?.latitude, selectedSiteData[0]?.longitude])
	const customPosition = coordinates ? [coordinates.lat, coordinates.lng] : null;
	const positionArray = lanesPosition.filter(Boolean);
	const [position, setPosition] = useState(positionArray.length > 0 ? lanesPosition : selectedPosition);

	const MyComponent = () => {
		const [markerPosition, setMarkerPosition] = useState<LatLng | null>(null);

		useMapEvents({
		   click: (event) => {
			setMarkerPosition(event.latlng);
			onManualChange(event.latlng);
		   },
		});
	   
		return markerPosition === null ? null : <Marker position={markerPosition} icon={new Icon({ iconUrl: redIcon, iconSize: [32, 32], iconAnchor: [12, 41] })}/>;
	}

	return (

		<Paper>
			<MapContainer
				style={{ height: 'auto' }}
				center={position as any}
				zoom={4}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
				{!!selectedSiteData &&
					selectedSiteData
						.filter((s) => s.latitude !== undefined && s.longitude !== undefined)
						.map((stop, idx) => (
							<Marker
								position={[stop.latitude, stop.longitude]}
								key={idx}
								icon={new Icon({ iconUrl: yellowIcon, iconSize: [25, 41], iconAnchor: [12, 41] })}
							>
							</Marker>
						))}
				{customPosition && <Marker
					position={[customPosition?.at(0), customPosition?.at(1)]}
					key={'marker-position'}
					icon={new Icon({ iconUrl: redIcon, iconSize: [32, 32], iconAnchor: [16, 32] })}
					>
				</Marker>
}
				<LayersControl position="topright">
					<LayersControl.Overlay name="Show markers">
						<LayerGroup>
							{isDataVisible &&
								mapData?.map(({ id, deliveryLat, deliveryLng, pickupLat, pickupLng, selected, loadId }: any) => {
								return (
									<>
										<Marker
											position={[pickupLat, pickupLng]}
											key={`${id} - pickup`}
											icon={new Icon({ iconUrl: originIcon, iconSize: [24, 24], iconAnchor: [12, 20] })}
										>
										</Marker>
										<Marker
											position={[deliveryLat, deliveryLng]}
											key={`${id} - delivery`}
											icon={new Icon({ iconUrl: destinationIcon, iconSize: [24, 24], iconAnchor: [12, 20] })}
										>
										</Marker>
									</>
								)}
							)}			
						</LayerGroup>			
					</LayersControl.Overlay>
				</LayersControl>

				{isDataVisible &&
					mapData?.map(({ id, deliveryLat, deliveryLng, pickupLat, pickupLng, selected, loadId, pickupDate, pickupLocation, deliveryLocation, revenue }: any) => {
						return (
							<>	
								<Polyline key={id} positions={[
									[deliveryLat, deliveryLng], [pickupLat, pickupLng],
								]} color={selected ? green[700] : red[700]} >
									<Tooltip>
										{`Load Id: ${loadId}`}<br />
										{`Pickup date: ${pickupDate}`}<br />
										{`Pickup: ${pickupLocation}`}<br />
										{`Delivery: ${deliveryLocation}`}<br />
										{`Revenue: $${revenue}`}
										</Tooltip>
								</Polyline>
							</>
						)
					})
				}
				<MyComponent />
			</MapContainer>
		</Paper >

	);
};
