import * as React from "react";
import {AppointmentTooltip, ViewState} from "@devexpress/dx-react-scheduler";
import format from "date-fns/format";
import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  AppointmentForm,
    AppointmentTooltip as Popup,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  Appointment,
  TooltipContent,
  TooltipHeader,
  TooltipIcon,
  //TooltipLayout
} from "./Appoitment";
import { WEEK_DAYS } from "./types";
import { DayScaleCell, ToolbarWithLoading } from "./helpers";
import {LaneRoutesType, RoutesType} from "../LocalEquipmentTable/types";
import moment from "moment";

export const Schedule: React.FC<{ routes: LaneRoutesType[] }> = ({ routes }) => {

  const mapAppointment = (laneRoute:LaneRoutesType):any => {
    if (laneRoute.lane) {
      const routeStartDate = laneRoute.lane?.pickupDate && new Date(laneRoute.lane?.pickupDate);
      const routeEndDate = laneRoute.lane?.deliveryDate && new Date(laneRoute.lane?.deliveryDate);
      return {
        endDate: routeEndDate,
        id: laneRoute.route.id,
        startDate: routeStartDate,
        title: `${laneRoute.lane.broker.name} #${laneRoute.lane.loadId}`,
        routeType: laneRoute.route.routeType,
      };
    } else {
      const routeStartDate = laneRoute.route?.routeStart && new Date(laneRoute.route?.routeStart);
      const routeEndDate = laneRoute.route?.routeEnd && new Date(laneRoute.route?.routeEnd);
      return {
        endDate: routeEndDate,
        id: laneRoute.route.id,
        startDate: routeStartDate,
        title: laneRoute.route.code,
        routeType: laneRoute.route.routeType,
        employee: laneRoute.route.employee,
      };
    }
  }

  const addDays = (date:Date, days:number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const splitAppointment = (originalStart:Date, originalEnd:Date, appointment:any, index = 1):any[] => {
    const all = [];
    const firstEnd = moment(appointment.startDate).endOf('day').toDate();
    const secondStart = addDays(moment(appointment.startDate).startOf('day').toDate(), 1);
    all.push({...appointment, endDate: firstEnd, id: `${appointment.id}_${index}`, originalStart, originalEnd});
    const secondPart = {...appointment, startDate: secondStart};
    const duration = Math.abs(secondPart.endDate - secondPart.startDate) / 36e5;
    if (duration <= 24) {
      all.push({...secondPart, id: `${appointment.id}_${index + 1}`, originalStart, originalEnd});
    } else {
      all.push(...splitAppointment(originalStart, originalEnd, {...secondPart, id: `${appointment.id}`, originalStart, originalEnd}, index + 1));
    }
    return all;
  }

  //@ts-ignore
  const eventsData = routes.reduce((prev, curr) => {
    const appointment = mapAppointment(curr);
    const duration = Math.abs(appointment.endDate - appointment.startDate) / 36e5;
    if (duration <= 24) {
      //@ts-ignore
      prev.push(appointment);
    } else {
      //@ts-ignore
      prev.push(...splitAppointment(appointment.startDate, appointment.endDate, appointment));
    }
    return prev;
  }, []);

  const today = format(new Date(), "eeee");
  const loading = false;

  return (
    <Scheduler
      data={eventsData as any}
      //height={250}
      firstDayOfWeek={WEEK_DAYS[today as any] as any}
    >
      <ViewState currentDate={new Date()} currentViewName={"Week"} />
      <WeekView
        startDayHour={0}
        endDayHour={24}
        cellDuration={120}
        intervalCount={0.6}
        dayScaleCellComponent={DayScaleCell}
      />
      {/*<Appointments />*/}
      <Appointments appointmentComponent={Appointment} />
      <AppointmentTooltip
          layoutComponent={Popup.Layout}
          headerComponent={TooltipHeader}
          contentComponent={TooltipContent}
          commandButtonComponent={Popup.CommandButton}
          recurringIconComponent={TooltipIcon} />
      <Toolbar {...(loading ? { rootComponent: ToolbarWithLoading } : null)} />
      <AppointmentForm readOnly />
    </Scheduler>
  );
};
