import React, { FC } from 'react';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

import { drawerWidth } from '../Sidebar/constants';
import { SidebarProps } from '../Sidebar/types';
import { AppBarProps } from './types';
import { AppInfo } from './AppInfo';
import { UserInfo } from './UserInfo';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const PageHeader: FC<SidebarProps> = ({ open, setOpen, isSidebarVisible }) => {
  const isUserInfoVisible = useMediaQuery('(min-width:900px)');
  return (
    <AppBar position="fixed" open={open} >
      <Toolbar>
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <AppInfo setOpen={setOpen} open={open} isSidebarVisible={isSidebarVisible} />
          {isUserInfoVisible && <UserInfo />}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
