import { Box, Button } from '@mui/material'
import React, { FC } from 'react'
import { ExcelIcon } from '../../../assets/icons'
import { getTemplate } from '../../../service/getTemplate'

export const DownloadFile: FC = () => {
	const handleFetchTemplate = () => {
		getTemplate()
	}

	return (
		<Box display='flex' mb='40px' alignItems="center">
			<Box mr='20px' fontSize="16px">Download template file:</Box>
			<Button variant='outlined' color='success' onClick={handleFetchTemplate}>
				<ExcelIcon color='green' />
			</Button>
		</Box>
	)
}