import { useState } from 'react';
import {TileLayer, Polyline, ScaleControl, MapContainer, Marker} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { decode } from './utils';
import {blue, red, grey, green} from '@mui/material/colors';
import {Icon} from "leaflet";
import yellowIcon from '../../../../assets/icons/marker-icon-gold.png';
import redIcon from '../../../../assets/icons/location-pin.png';
import depotIcon from '../../../../assets/icons/racing-flag.png';


type MapProps = {
	data?: any;
	lane?: any,
	routeId: number,
	value: number
};

type IconProps = {
	latitude: number,
	longitude: number,
	iconKey: string,
}

const getDiameter = (firstNumber: number, secondNumber: number) => {
	const total = firstNumber + secondNumber;
	return total / 2
}

export const Map: React.FC<MapProps> = ({ data, lane, routeId, value }) => {
	const [map, setMap] = useState<any>(null)

	const result = data.map((current:any) => {
		let polynes:any = {routeId: current.routeId}
		const { polyline } = decode(current.laneRoutePolyline.value)
		//const { polyline: routePolyline } = decode(current.routePolyline.value)

		if (polyline.length) {
			polynes.polyline = polyline;
		}
		/*if (routePolyline.length) {
			polynes.push(routePolyline);
		}*/

		return polynes;
	});

	const current = result.find((r:any)=>r.routeId === routeId);

	if (!current || !current.polyline) {
		return <>No Map data available for this Match</>;
	}

	const firstParameters = current.polyline[0];
	const secondParameters = current.polyline[0];

	const lat = getDiameter(firstParameters[0], secondParameters[0])
	const lon = getDiameter(firstParameters[1], secondParameters[1])

	const pickupLocation = lane.pickupLocation;
	const deliveryLocation = lane.deliveryLocation;

	const r = result.filter((line:any)=>line.polyline && line.polyline.length).map((line: any, idx: number) => {
		return ({
			routeId: line.routeId,
			line: <Polyline key={`${routeId}-${idx}`} positions={line.polyline} pathOptions={{ color: line.routeId === routeId ? blue[800] : grey[500], weight: 8, opacity: 0.85 }} />
		});
	}).flat();

	const inactive = r.filter((d:any) => d.routeId != routeId).map((d:any)=>d.line);
	const active = r.filter((d:any) => d.routeId == routeId).map((d:any)=>d.line);

	const selected = data.find((d:any)=>d.routeId === routeId);

	return (
		<MapContainer center={[lat, lon]} zoom={4} scrollWheelZoom={true}
			// @ts-ignore
			whenReady={(map: any) => setMap(map)}
		>
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{inactive.length && inactive}
			{active.length && active}
			{selected?.depotLocation && <DepotIcon latitude={selected.depotLocation.latitude} longitude={selected.depotLocation.longitude} iconKey={"depotLocation"} />}
			{selected?.lastStopLocation && <RedIcon latitude={selected.lastStopLocation.latitude} longitude={selected.lastStopLocation.longitude} iconKey={"lastStopLocation"} />}
			{pickupLocation && <YellowIcon latitude={pickupLocation.latitude} longitude={pickupLocation.longitude} iconKey={"pickupLocation"} />}
			{deliveryLocation && <YellowIcon latitude={deliveryLocation.latitude} longitude={deliveryLocation.longitude} iconKey={"deliveryLocation"} />}
			<ScaleControl position="bottomleft" />
		</MapContainer >
	);
};

const DepotIcon: React.FC<IconProps> = ({latitude, longitude, iconKey}) => {
	return <Marker position={[latitude, longitude]} key={iconKey} icon={new Icon({ iconUrl: depotIcon, iconSize: [40, 40], iconAnchor: [12, 41] })}></Marker>
}
const RedIcon: React.FC<IconProps> = ({latitude, longitude, iconKey}) => {
	return <Marker position={[latitude, longitude]} key={iconKey} icon={new Icon({ iconUrl: redIcon, iconSize: [40, 40], iconAnchor: [12, 41] })}></Marker>
}
const YellowIcon: React.FC<IconProps> = ({latitude, longitude, iconKey}) => {
	return <Marker position={[latitude, longitude]} key={iconKey} icon={new Icon({ iconUrl: yellowIcon, iconSize: [25, 41], iconAnchor: [12, 41] })}></Marker>
}