
import { MapContainer, TileLayer, Polyline, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Paper } from '@mui/material';
import { green } from '@mui/material/colors';
import { Icon } from 'leaflet';
import yellowIcon from '../../../assets/icons/marker-icon-gold.png';

type MapProps = {
	lane: any;
};

export const LocalIdleLaneScreenDetailMap: React.FC<MapProps> = ({ lane }) => {
	const {deliveryLocation, pickupLocation} = lane;
	const position = [pickupLocation?.latitude, deliveryLocation?.longitude];

	return (

		<Paper>
			<MapContainer
				style={{ height: 'auto' }}
				center={position as any}
				zoom={6}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
				{lane &&
					<>
						<Polyline key={lane.id} positions={[
							[deliveryLocation.latitude, deliveryLocation.longitude], [pickupLocation.latitude, pickupLocation.longitude],
						]} color={green[700]} />
				
						<Marker
							position={[pickupLocation.latitude, pickupLocation.longitude]}
							key={`${pickupLocation.id} - pickup`}
							icon={new Icon({ iconUrl: yellowIcon, iconSize: [25, 41], iconAnchor: [12, 41] })}
						>
						</Marker>
						<Marker
							position={[deliveryLocation.latitude, deliveryLocation.longitude]}
							key={`${deliveryLocation.id} - delivery`}
							icon={new Icon({ iconUrl: yellowIcon, iconSize: [25, 41], iconAnchor: [12, 41] })}
						>
						</Marker>
					</>
				}
			</MapContainer>
		</Paper >

	);
};
