import React, { FC, ReactNode, useState, useEffect, ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './components/Dashboard';
import { LocalIdleDashboard } from './components/LocalIdleDashboard';
import { LanesScreenDetail } from './components/LaneScreenDetail';
import { LanesScreen } from './components/LanesScreen';
import { Page } from './components/layout/Page';
import PageWrapper from './components/layout/PageWrapper';
import { PursuitSupport } from './components/PursuitSupport';
import { ROUTES } from './routes';
import { MsalProvider, useMsal } from '@azure/msal-react';
import {AccountInfo, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import { loginRequest, setActiveAccount } from './authConfig';
import { CapacityAssigmentScreen } from './components/CapacityAssignment';
import {LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";
import { LocalEquipmentDashboard } from './components/LocalEquipmentDashboard';
import { SitesContext } from './components/layout/SitesContext';
import { LocalIdleLanesScreenDetail } from './components/LocalIdleDashboard/LocalIdleLaneScreenDetail';

type SecuredProps = {
  children?: ReactNode;
};

const Secured = ({ children }: SecuredProps): ReactElement | null => {
  const [authorized, setAuthorized] = useState<boolean>(true);
  const { instance } = useMsal();

  useEffect(() => {

    if (instance.getActiveAccount() != null) {
      setActiveAccount(instance.getActiveAccount());
      setAuthorized(true);
      return;
    }

    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
      new Promise(() => {
        instance
          .acquireTokenSilent({ ...loginRequest, account: instance.getAllAccounts()[0] })
          .then(() => {
            instance.setActiveAccount(instance.getAllAccounts()[0]);
            setActiveAccount(instance.getAllAccounts()[0]);
            setAuthorized(true);
          })
          .catch(console.error);
      });
      return;
    }

    const callback = (event: EventMessage): void => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        new Promise(() => {
          instance
            .acquireTokenSilent({ ...loginRequest, account: event.payload as AccountInfo })
            .then(() => {
              instance.setActiveAccount(event.payload as AccountInfo);
              setActiveAccount(event.payload as AccountInfo);
              setAuthorized(true);
            })
            .catch(console.error);
        });
      }
      if (event.eventType === EventType.LOGOUT_SUCCESS) {
        setActiveAccount(null);
      }
    };

    const callbackId = instance.addEventCallback(callback) || '';
    return (): void => instance.removeEventCallback(callbackId);
  }, []);

  if (!authorized) {
    return null;
  }
  return <>{children}</>;
};

const App: FC<any> = ({ pca }) => {
  const { inProgress } = useMsal();
  return (
      inProgress === InteractionStatus.None ? <SitesContext>
        <PageWrapper>
          <Page>
            <Routes>
              <Route path="/" element={<Secured><LanesScreen /></Secured>} />
              <Route path={`/${ROUTES.capacityAssignmentScreen}`} element={<Secured><CapacityAssigmentScreen /></Secured>} />
              <Route path={`/${ROUTES.lanesScreen}/:id`} element={<Secured><LanesScreenDetail /></Secured>} />
              <Route path={`/${ROUTES.pursuitSupport}`} element={<Secured><PursuitSupport /></Secured>} />
              <Route path={`/${ROUTES.localIdleDashboard}`} element={<Secured><LocalIdleDashboard /></Secured>} />
              <Route path={`/${ROUTES.localIdleDashboard}/:id`} element={<Secured><LocalIdleLanesScreenDetail /></Secured>} />
              <Route path={`/${ROUTES.localEquipmentDashboard}`} element={<Secured><LocalEquipmentDashboard /></Secured>} />
            </Routes>
          </Page>
        </PageWrapper>
      </SitesContext>
          : <Box><LinearProgress/></Box>
  );
}

export default App;
