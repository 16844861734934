import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import React, { FC, useState, SyntheticEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { grey } from '@mui/material/colors';

import { MainPaper } from '../layout/Paper/MainPaper';
import { MatchesRoutesContent } from './MatchesRoutes/MatchesRoutesContent';

import { TabPanel } from './MatchesRoutes/TabPanel';
import { MainDetailTable } from './table/MainDetailTable';
import { Center } from '../layout/Center';
import { DetailMap } from './MatchesRoutes/DetailMap';

export const LanesScreenDetailContent: FC<{detail: any}> = ({detail}) => {
	const { hash: matchId } = useLocation();
	const [value, setValue] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		const matchData = async () => {
			const matchValue = matchId ? matchId.replace("#","") : null;
			if (matchValue) {
				const index = detail.laneToRoutes.findIndex((lr:any)=>`${lr.id}` == matchValue);
				setValue(index >= 0 ? index : 0);
			}
			setIsLoading(false);
		}

		matchData()
			.catch(console.error);

	}, [detail, matchId])

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
		const newHash = detail.laneToRoutes[newValue];
		//window.location.replace(`#${newHash.id}`);
		window.history.replaceState(null, '', `#${newHash.id}`);
	};

	if (isLoading) {
		return (
			<Center>
				<CircularProgress size={25} />
			</Center>
		)
	}

	if (!detail) {
		return (
			<Center>
				No detail data available
			</Center>
		)
	}

	const laneToRoutes = detail.laneToRoutes;
	const biddingEnabled = detail.broker.biddingEnabled;

	return (
		<>
			<MainPaper>
				<Box display='grid' gridTemplateColumns='1fr 1fr' gap='100px'>
					<Box>
						<MainDetailTable detail={detail} />
					</Box>
					<Box>
						<DetailMap lane={detail} value={value} routeId={laneToRoutes[value]?.route?.id} />
					</Box>
				</Box>
			</MainPaper>
			<MainPaper>
				<Typography variant='h3' sx={{ fontSize: "18px", fontWeight: 600, mb: 8 }}>Matched Route</Typography>
				<Box
					sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
				>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="Vertical tabs example"
						sx={{ borderRight: 1, borderColor: 'divider' }}
					>
						{laneToRoutes.map((_: any, index: number) => <Tab key={index} label={`#${index + 1}`} sx={{ padding: '6px', fontWeight: 500, color: grey[900], fontSize: "18px" }} />)}
					</Tabs>
					{laneToRoutes.map((laneToRoute: any, index: number) => (
						<TabPanel key={index} value={value} index={index} >
							<MatchesRoutesContent detail={laneToRoute} mainRouteDetail={detail} biddingEnabled={biddingEnabled}/>
						</TabPanel>
					))}
				</Box>
			</MainPaper>
		</>
	)
}