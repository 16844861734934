import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import {
  WeekView,
  Toolbar,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Box } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

const classes = {
	todayCell: `todayCell`,
	weekendCell: `weekendCell`,
	today: `today`,
	weekend: `weekend`,
  };
  

export const ToolbarWithLoading = ({ children, ...restProps }: any) => (
	<Box sx={{ position: "relative" }}>
	  <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
	  <LinearProgress />
	</Box>
  );
  
  const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(
	({ theme }) => ({
	  [`&.${classes.today}`]: {
		backgroundColor: alpha(theme.palette.primary.main, 0.16),
	  },
	  [`&.${classes.weekend}`]: {
		backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06),
	  },
	})
  );
  
  export const DayScaleCell = (props: any) => {
	const { startDate, today } = props;
  
	if (today) {
	  return <StyledWeekViewDayScaleCell {...props} className={classes.today} />;
	}
	if (startDate.getDay() === 0 || startDate.getDay() === 6) {
	  return (
		<StyledWeekViewDayScaleCell {...props} className={classes.weekend} />
	  );
	}
	return <StyledWeekViewDayScaleCell {...props} />;
  };