import { FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import { MUIDataTableColumn } from 'mui-datatables';
import { BrokerType } from './types';
import { mockBrokers } from './helpers';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const localIdletableColumns = (equipments?: any): MUIDataTableColumn[] => ([
	{
		name: "broker",
		label: "Broker",
		options: {
			filter: false,
			sort: true,
			filterOptions: {
				fullWidth: true,
				names: mockBrokers.map((i: BrokerType) => i.name),
			},
			customBodyRender: (broker: BrokerType) => <div>{broker.name}</div>,
			filterType: 'multiselect',
			customFilterListOptions: { render: (v): string => `Broker: ${v}` },
		}
	},
	{
		name: "loadId",
		label: "Load Id",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "pickupDateFrom",
		label: "Pickup date from",
		options: {
			filter: false,
			sort: true,
			filterList: []
		}
	},
	{
		name: "pickupDateTo",
		label: "Pickup date to",
		options: {
			filter: false,
			sort: true,
			filterList: []
		}
	},
	{
		name: "pickupState",
		label: "Pickup location",
		options: {
			filter: false,
			sort: true,
			filterList: []
		}
	},
	{
		name: "deliveryDateFrom",
		label: "Delivery date from",
		options: {
			filter: false,
			sort: true,
			filterList: []
		}
	},
	{
		name: "deliveryDateTo",
		label: "Delivery date to",
		options: {
			filter: false,
			sort: true,
			filterList: []
		}
	},
	{
		name: "deliveryState",
		label: "Delivery location",
		options: {
			filter: false,
			sort: true,
			filterList: []
		}
	},
	{
		name: "miles",
		label: "Miles",
		options: {
			filter: false,
			sort: true,
		}
	},
	{
		name: "equipmentType",
		label: "Equipment type",
		options: {
			sort: true,
			filter: true,
			filterType: 'multiselect',
			filterOptions: {
				names: [...equipments?.map((i: any) => i.brokerEquipment)],
			},
			customFilterListOptions: { render: (v): string => `Equipment type: ${v}` },
		}
	},
	{
		name: "rate",
		label: "Revenue",
		options: {
			filter: false,
			sort: true,
			customBodyRender: (rate: number) => <div>$ {rate}</div>,
		}
	},
	{
		name: 'fromRate',
		options: {
			filter: true,
			display: 'excluded',
			filterType: 'custom',
			customFilterListOptions: {
				render: v => {
					if (v[0]) {
						return `From rate: ${v[0]}`;
					}
					return [];
				},
				update: (filterList, filterPos, index = 6) => {

					if (filterPos === 0) {
						filterList[index].splice(filterPos, 1, '');
					} else if (filterPos === 1) {
						filterList[index].splice(filterPos, 1);
					} else if (filterPos === -1) {
						filterList[index] = [];
					}

					return filterList;
				},
			},
			filterOptions: {
				names: [],
				logic(fromMiles, filters) {
					if (filters[0] && filters[1]) {
						return fromMiles < filters[0] || fromMiles > filters[1];
					} else if (filters[0]) {
						return fromMiles < filters[0];
					} else if (filters[1]) {
						return fromMiles > filters[1];
					}
					return false;
				},
				display: (filterList, onChange, index, column) => (
					<div>
						<FormLabel>Min Revenue</FormLabel>
						<FormGroup row>
							<TextField
								type='number'
								value={filterList[index][0] || ''}
								onChange={event => {
									filterList[index][0] = event.target.value;
									onChange(filterList[index], index, column);
								}}
							/>
						</FormGroup>
					</div>
				),
			},
			print: false,
		},
	},
	{
		name: 'toRate',
		options: {
			filter: true,
			filterType: 'custom',
			display: 'excluded',
			customFilterListOptions: {
				render: v => {
					if (v[1]) {
						return `Max rate: ${v[1]}`;
					}
					return [];
				},
				update: (filterList, filterPos, index) => {

					if (filterPos === 0) {
						filterList[index].splice(filterPos, 1, '');
					} else if (filterPos === 1) {
						filterList[index].splice(filterPos, 1);
					} else if (filterPos === -1) {
						filterList[index] = [];
					}

					return filterList;
				},
			},
			filterOptions: {
				names: [],
				logic(toMiles, filters) {
					if (filters[1]) {
						return toMiles > filters[1];
					}
					return false;
				},
				display: (filterList, onChange, index, column) => (
					<div>
						<FormLabel>Max Revenue</FormLabel>
						<FormGroup row>
							<TextField
								type='number'
								value={filterList[index][1] || ''}
								onChange={event => {
									filterList[index][1] = event.target.value;
									onChange(filterList[index], index, column);
								}}
							/>
						</FormGroup>
					</div>
				),
			},
			print: false,
		},
	},
	{
		name: "deliveryLocation",
		label: "Delivery location - state or city",
		options: {
			filter: true,
			display: 'excluded',
			filterType: 'textField',
			customFilterListOptions: { render: (v): string => `Delivery location: ${v}` },
		}
	},
	{
		name: "pickupLocation",
		label: "Pickup location - state or city",
		options: {
			filter: true,
			display: 'excluded',
			filterType: 'textField',
			customFilterListOptions: { render: (v): string => `Pickup location: ${v}` },
		}
	},
	{
		name: "id",
		label: "Detail",
		options: {
			sort: false,
			filter: false,
			download: false,
			customBodyRender: (id: number, data) => {
				return (
					<Link to={`${id}`}>
						<IconButton color='default'>
							<MoreVertIcon />
						</IconButton>
					</Link>
				)
			}
		}
	},
]);
