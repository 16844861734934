import { isSomeContainInObject } from "../components/DHLTable/action.utils";
import { localIdleRouteResponseContentType } from "../components/LocalIdleDashboard/LocalIdleTable/types";
import { post } from "./service";

export const postIdleEquipment = async ({
  site,
  sortingParam,
  paginationParam,
}: any): Promise<localIdleRouteResponseContentType | any> => {
  const filters = {};
  const sortingData = {};

  if (isSomeContainInObject(sortingParam)) {
    Object.assign(sortingData, {
      colId: sortingParam.columnName,
      sort: sortingParam.direction,
    });
  }

  try {
    if (site) {
      const response = await post<any>(`route/idle/site/${site}`, {
        page: paginationParam?.currentPageIndex ?? 0,
        size: paginationParam?.rowsPerPage ?? 5,
        filter: {
          ...filters,
        },
        ...(isSomeContainInObject(sortingParam) && { sort: [sortingData] }),
      });
      return response.data || undefined;
    }
  } catch (e) {
    throw e;
  }
};
